import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { isArray } from 'lodash';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import { useLocation, useHistory } from 'react-router-dom';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import PinIcon from '../icons/PinIcon';
import CompareIcon from '../icons/CompareIcon';
import SaveIcon from '../icons/SaveIcon';
import AlertIcon from '../icons/AlertIcon';
import EditIcon from '../icons/EditIcon';
import EyeIcon from '../icons/EyeIcon';
import Action from '../atoms/Action';
import Toast, { ToastType } from '../atoms/Toast';
import Loader from '../atoms/Loader';
import SurfaceToTop from '../molecules/SurfaceToTop';
import SearchPlayerFilterDrawer, {
  PlayerPositionType,
  FILTER_TYPE,
  PLAYER_POSITION_DEFAULT_FILTERS,
  SPRINTS,
  HURDLES,
  RELAYS,
  JUMPING_EVENTS,
  THROWING_EVENTS,
  MIDDLE_DISTANCE,
  ARM_LENGTH_RANGE,
  HAND_SIZE_RANGE,
  WINGSPAN_RANGE,
} from '../molecules/SearchPlayerFilterDrawer';
import SaveSearchModal from '../molecules/SaveSearchModal';
import PageLimitDropdown from '../molecules/PageLimitDropdown';
import { MultiSelectItem } from '../molecules/MultiSelect';
import SearchPlayerTable, {
  PLAYER_COLUMN_TITLE,
  HS_PLAYER_DEFAULT_COLUMNS,
  COLLEGE_PLAYER_DEFAULT_COLUMNS,
  NFL_PLAYER_DEFAULT_COLUMNS,
  BLENDED_PLAYER_DEFAULT_COLUMNS,
  HS_PLAYER_COLUMNS,
  COLLEGE_PLAYER_COLUMNS,
  NFL_PLAYER_COLUMNS,
  BLENDED_PLAYER_COLUMNS,
  PLAYER_ALL_COLUMNS,
} from './SearchPlayerTable';
import AddRemoveColumns from './AddRemoveColumns';
import SelectXLSExportType from './SelectXLSExportType';
import gql, { serializeGQLObject } from '../services/gql';
import exportXLSReport, {
  XLSExportType,
  XLSExportPage,
  ERROR_MESSAGE_CHECK_EMAIL,
} from '../services/export-xls-report';
import { State } from '../redux/reducers';
import * as clientStorage from '../services/client-storage';
import { StorageKey } from '../services/client-storage';
import { getQueryParameter } from '../services/query-parameters';
import { getHighSchoolAccessYears, getMaxHighSchoolClassYear } from '../services/user';
import { fetchCollegeTeams, fetchNFLTeams, fetchJucoTeams } from '../redux/dispatchers/teams';
import { fetchUserSettings, saveSearchColumns } from '../redux/dispatchers/user-settings';
import { fetchStates } from '../redux/dispatchers/states';
import { fetchFBSConferences, fetchFCSConferences } from '../redux/dispatchers/conferences';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';
import {
  COLOR_BLUE,
  COLOR_BORDER,
  COLOR_DARK_GRAY,
  COLOR_ORANGE,
  COLOR_SHADOW,
  COLOR_TEXT,
  COLOR_WHITE,
} from '../styles/colors';
import MEDIA from '../styles/media';
import Player from '../types/Player';
import User from '../types/User';
import Team from '../types/Team';
import Conference from '../types/Conference';
import SearchesFolder from '../types/SearchesFolder';
import {
  HSFilter,
  NFLFilter,
  CollegeFilter,
  PlayerPosition,
  SEARCH_COLUMNS_SORTING_RULES,
  SEARCH_COLUMNS_ORDERING_RULES,
} from '../types/SearchPlayer';
import Search from '../types/Search';
import SearchCounts from '../types/SearchCounts';
import StateType from '../types/State';
import AccessLevel, { ExportSearchResultsAllowanceType } from '../types/AccessLevel';
import SearchPlayersResult from '../types/SearchPlayersResult';
import Column from '../types/Column';
import { PlayerColumn } from '../types/PlayerColumn';
import { Order } from '../types/Order';

interface SearchPlayersProps {
  className?: string;
  searchId?: number;
  isDrawerOpen: boolean;
  currentRecruitingYear: number;
  nflDraftYear: number;
  setIsDrawerOpen: (value:boolean) => void;
  user?: User;
  isNFL?: boolean;
  isAdmin?: boolean;
  collegeTeams: Team[],
  collegeTeamsLoading: boolean,
  jucoTeams: Team[],
  jucoTeamsLoading: boolean,
  nflTeams: Team[],
  nflTeamsLoading: boolean,
  states: StateType[];
  statesLoading: boolean;
  fbsConferences: Conference[];
  fbsConferencesLoading: boolean;
  fcsConferences: Conference[];
  fcsConferencesLoading: boolean;
  rolloverMonthTransferPortal: number;
  rolloverDateTransferPortal: number;
  fetchCollegeTeams: () => void;
  fetchJucoTeams: () => void;
  fetchNFLTeams: () => void;
  fetchStates: () => void;
  fetchFBSConferences: () => void;
  fetchFCSConferences: () => void;
  fetchUserSettings: () => void;
  savedColumns: Column[] | null;
  saveColumns: (searchColumns:Column[]) => void;
}

enum SelectedXLSType {
  SELECTED = 'Selected Players',
  ALL = 'All Search Results',
}

export interface SearchByNamePlayer {
  name: string;
  slug?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  searchPlayers: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },

  header: {
    display: 'flex',
    flexDirection: 'column',
    borderTop: `1px solid ${COLOR_BORDER}`,
    borderLeft: `1px solid ${COLOR_BORDER}`,
    borderRight: `1px solid ${COLOR_BORDER}`,
    boxShadow: `0 10px 10px 0 ${COLOR_SHADOW}`,
  },

  titleRow: {
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    position: 'relative',
  },
  titleTextWrap: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },

  headerTitle: {
    ...theme.typography.h2,
    margin: theme.spacing(0, 4, 0, 0),
  },

  dropdowns: {
    marginLeft: 'auto',
    display: 'flex',
    flexShrink: 0,
  },
  dropdownWrapper: {
    marginLeft: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(16),
    color: COLOR_TEXT,
  },
  dropdown: {
    minWidth: '86px',
    marginLeft: theme.spacing(2),
  },
  dropdownSelector: {
    minHeight: '24px',
  },
  limitDropDown: {
    marginRight: theme.spacing(2),
  },

  surfaceToTop: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
  },

  actions: {
    padding: theme.spacing(3, 2.5),
    display: 'flex',
    flexWrap: 'wrap',
    borderTop: `1px solid ${COLOR_BORDER}`,
  },
  actionsPrimary: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    maxWidth: '560px',
    paddingRight: theme.spacing(5),
  },
  actionsSecondary: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
    justifyContent: 'space-between',
  },
  action: {
    padding: theme.spacing(0,0,0,2),
    color: COLOR_DARK_GRAY,
    outlineColor: COLOR_ORANGE,
    transition: 'color 0.3s',
    overflow: 'hidden',

    '&:first-child': {
      paddingLeft: 0,
    },

    '&:hover': {
      color: COLOR_BLUE,
      textDecoration: 'underline',

      '& $actionText': {
        color: COLOR_BLUE,
      },
    },
  },
  disabledAction: {
    outlineColor: 'transparent',

    '& $actionText': {
      color: COLOR_DARK_GRAY,
    },

    '&:hover': {
      color: COLOR_DARK_GRAY,
      textDecoration: 'none',

      '& $actionText': {
        color: COLOR_DARK_GRAY,
      },
    },
  },
  actionIcon: {
    width: '24px',
    height: '24px',
  },
  actionIconReport: {
    width: '16px',
    height: '16px',
  },
  actionText: {
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(16),
    color: COLOR_TEXT,
    transition: 'color 0.3s',
  },

  addRemoveColumns: {
    margin: theme.spacing(0, 1, 0, 0),
    padding: theme.spacing(1, 2, 1, 0),
    overflow: 'hidden',
  },

  xlsExport: {
    marginLeft: theme.spacing(2),
  },

  importPageLink: {
    paddingLeft: theme.spacing(0.5),
    textDecoration: 'underline',
    color: COLOR_WHITE,
    fontWeight: 600,
  },

  '@media (max-width: 1023px)': {
    titleRow: {
      display: 'block',
    },

    dropdowns: {
      marginTop: theme.spacing(2),
    },
    dropdownWrapper: {
      marginLeft: 0,
    },

    actions: {
      display: 'block',
    },
    actionsPrimary: {
      paddingRight: 0,
    },
    actionsSecondary: {
      justifyContent: 'flex-start',
      marginTop: theme.spacing(2),
      marginLeft: 0,
    },
  },

  [MEDIA.MOBILE]: {
    dropdowns: {
      display: 'block',
    },
    dropdownWrapper: {
      flexWrap: 'wrap',
      marginTop: theme.spacing(2),
      fontSize: theme.typography.pxToRem(14),
    },
    dropdown: {
      minWidth: 0,
    },

    surfaceToTopLabel: {
      width: '100%',
    },
    surfaceToTop: {
      margin: theme.spacing(1,0,0),
      width: '100%',
      maxWidth: '160px',
    },

    limitDropDown: {
      margin: theme.spacing(0,1),
    },

    actions: {
      padding: theme.spacing(2),
    },
    actionsPrimary: {
      justifyContent: 'flex-start',
    },
    action: {
      padding: theme.spacing(0, 1),
      fontSize: theme.typography.pxToRem(14),
    },
    actionText: {
      display: 'none',
      fontSize: theme.typography.pxToRem(14),
    },
  },

  [MEDIA.DESKTOP_LARGE]: {
    dropdownWrapper: {
      fontSize: theme.typography.pxToRem(16),
    },

    surfaceToTop: {
      width: '300px',
    },
  },
}), { name: SearchPlayers.name });

function SearchPlayers (props: SearchPlayersProps) {
  const {
    className,
    searchId,
    isDrawerOpen,
    currentRecruitingYear,
    nflDraftYear,
    setIsDrawerOpen,
    user,
    isNFL,
    isAdmin,
    collegeTeams,
    collegeTeamsLoading,
    jucoTeams,
    jucoTeamsLoading,
    nflTeams,
    nflTeamsLoading,
    states,
    statesLoading,
    fbsConferences,
    fbsConferencesLoading,
    fcsConferences,
    fcsConferencesLoading,
    rolloverMonthTransferPortal,
    rolloverDateTransferPortal,
    fetchCollegeTeams,
    fetchJucoTeams,
    fetchNFLTeams,
    fetchStates,
    fetchFBSConferences,
    fetchFCSConferences,
    fetchUserSettings,
    savedColumns,
    saveColumns,
  } = props;
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();

  const highSchoolAccessYears = getHighSchoolAccessYears(user);

  const ARCHIVE_YEAR_RANGE = {
    MIN: 2010,
    MAX: currentRecruitingYear,
  };

  const PREP_YEAR_RANGE = {
    MIN: 2010,
    MAX: currentRecruitingYear,
  };

  const TRACK_AND_FIELD_YEAR_RANGE = {
    MIN: 1970,
    MAX: currentRecruitingYear + 4,
  };

  const COMBINE_YEAR_RANGE = {
    MIN: 2010,
    MAX: currentRecruitingYear + 1,
  };

  const HEIGHT_RANGE = {
    MIN: 60,
    MAX: 84,
  };

  const WEIGHT_RANGE = {
    MIN: 125,
    MAX: 400,
  };

  const NFL_DRAFT_YEAR_RANGE = {
    MIN: 1970,
    MAX: nflDraftYear,
  };

  const COLLEGE_YEAR_RANGE = {
    MIN: 1970,
    MAX: currentRecruitingYear,
  };

  const WATCH_LIST_YEAR_RANGE = {
    MIN: 2020,
    MAX: nflDraftYear + 1,
  };

  const ALL_AMERICAN_YEAR_RANGE = {
    MIN: 2010,
    MAX: currentRecruitingYear - 1,
  };

  const ALL_CONFERENCE_YEAR_RANGE = {
    MIN: 2010,
    MAX: currentRecruitingYear - 1,
  };

  const ALL_PRO_YEAR_RANGE = {
    MIN: 2010,
    MAX: nflDraftYear - 1,
  };

  const PRO_BOWL_YEAR_RANGE = {
    MIN: 2010,
    MAX: nflDraftYear - 1,
  };

  const [pageLoading, setPageLoading] = useState<boolean>(true);
  const [needToApplyFilters, setNeedToApplyFilters] = useState<boolean>(false);
  const [needToRefresh, setNeedToRefresh] = useState<boolean>(false);
  const [needToResetFilters, setNeedToResetFilters] = useState<boolean>(false);
  const [ignoreNeedToApplyFilters, setIgnoreNeedToApplyFilters] = useState<boolean>(false);

  const [players, setPlayers] = useState<Player[]>([]);
  const [allPlayerIds, setAllPlayerIds] = useState<number[]>([]);
  const [totalPlayersCount, setTotalPlayersCount] = useState<number>(0);
  const [surfaceToTopValues, setSurfaceToTopValues] = useState<string[]>([]);
  const [selectedPlayers, setSelectedPlayers] = useState<Player[]>([]);
  const [order, setOrder] = useState<Order>(Order.asc);
  const [sortedByColumn, setSortedByColumn] = useState<PlayerColumn>(PlayerColumn.NAME);

  const [columns, setColumns] = useState<Column[]>(PLAYER_ALL_COLUMNS.map((columnName:string) => ({
    name: columnName,
    selected: HS_PLAYER_COLUMNS.includes(columnName as PlayerColumn),
  })));
  const [defaultColumns, setDefaultColumns] = useState<string[]>([]);
  const [columnsToShow, setColumnsToShow] = useState<string[]>([]);
  const [disableColumns, setDisableColumns] = useState<string[]>([]);
  const [defaultDisableColumns, setDefaultDisableColumns] = useState<string[]>([]);

  const [savedSearchId, setSavedSearchId] = useState<number | undefined>(searchId);
  const [savedSearchData, setSavedSearchData] = useState<Search>();
  const [searchCounts, setSearchCounts] = useState<SearchCounts>();
  const [selectedStates, setSelectedStates] = useState<string[]>([]);

  const [playerTypeFilters, setPlayerTypeFilters] = useState<string[]>([]);

  const HS_CLASS_YEAR_RANGE = {
    MIN: (isAdmin && (playerTypeFilters.includes(FILTER_TYPE.COLLEGE) || playerTypeFilters.includes(FILTER_TYPE.NFL)))
      ? 1970
      : currentRecruitingYear,
    MAX: getMaxHighSchoolClassYear(user, currentRecruitingYear),
  };

  const [hsClassYearRangeFilter, setHSClassYearRangeFilter] = useState<number |number[]>([HS_CLASS_YEAR_RANGE.MIN, HS_CLASS_YEAR_RANGE.MAX]);
  const [hsArchiveFilter, setHSArchiveFilter] = useState<number | number[]>([ARCHIVE_YEAR_RANGE.MIN, ARCHIVE_YEAR_RANGE.MAX]);
  const [prepFilter, setPrepFilter] = useState<number | number[]>([PREP_YEAR_RANGE.MIN, PREP_YEAR_RANGE.MAX]);
  const [hsHeightRangeFilter, setHsHeightRangeFilter] = useState<number | number[]>([HEIGHT_RANGE.MIN, HEIGHT_RANGE.MAX]);
  const [hsWeightRangeFilter, setHsWeightRangeFilter] = useState<number | number[]>([WEIGHT_RANGE.MIN, WEIGHT_RANGE.MAX]);
  const [hsWingspanRangeFilter, setHSWingspanRangeFilter] = useState<number | number[]>([WINGSPAN_RANGE.MIN, WINGSPAN_RANGE.MAX]);
  const [hsArmLengthRangeFilter, setHSArmLengthRangeFilter] = useState<number | number[]>([ARM_LENGTH_RANGE.MIN, ARM_LENGTH_RANGE.MAX]);
  const [hsHandSizeRangeFilter, setHSHandSizeRangeFilter] = useState<number | number[]>([HAND_SIZE_RANGE.MIN, HAND_SIZE_RANGE.MAX]);
  const [searchByNamePlayers, setSearchByNamePlayers] = useState<SearchByNamePlayer[]>([]);
  const [paiRangeFilter, setPaiRangeFilter] = useState<number | number[]>([0, 5]);
  const [ppiRangeFilter, setPpiRangeFilter] = useState<number | number[]>([0, 5]);
  const [hsMPHRangeFilter, setHsMPHRangeFilter] = useState<number | number[]>([0,30]);
  const [combineScoreRangeFilter, setCombineScoreRangeFilter] = useState<number | number[]>([0, 5]);
  const [proCombineScoreRangeFilter, setProCombineScoreRangeFilter] = useState<number | number[]>([0, 5]);
  const [combineRangeFilters, setCombineRangeFilters] = useState<any>({});
  const [rating247RangeFilter, setRating247RangeFilter] = useState<number | number[]>([0, 5]);
  const [offerCountRangeFilter, setOfferCountRangeFilter] = useState<number | number[]>([0, 70]);
  const [hsTrackAndFieldYearRangeFilter, setHSTrackAndFieldYearRangeFilter] = useState<number | number[]>([TRACK_AND_FIELD_YEAR_RANGE.MIN, TRACK_AND_FIELD_YEAR_RANGE.MAX]);
  const [trackAndFieldEventFilters, setTrackAndFieldEventFilters] = useState<any>({});
  const [hsName, setHSName] = useState<string>('');
  const [hsCity, setHSCity] = useState<string>('');
  const [hsCounty, setHSCounty] = useState<string>('');
  const [gpaMinimum, setGpaMinimum] = useState<string>('');
  const [satMinimum, setSatMinimum] = useState<string>('');
  const [actMinimum, setActMinimum] = useState<string>('');
  const [offerSchoolName, setOfferSchoolName] = useState<string>('');
  const [committedSchoolName, setCommittedSchoolName] = useState<string>('');
  const [combineYearRangeFilter, setCombineYearRangeFilter] = useState<number | number[]>([COMBINE_YEAR_RANGE.MIN, COMBINE_YEAR_RANGE.MAX]);
  const [combineCityName, setCombineCityName] = useState<string>('');
  const [combineEventFilters, setCombineEventFilters] = useState<string[]>([]);
  const [otherSportFilters, setOtherSportFilters] = useState<string[]>([]);

  const [collegeRangeFilters, setCollegeRangeFilters] = useState<any>({});
  const [collegeClassYearRangeFilter, setCollegeClassYearRangeFilter] = useState<number |number[]>([COLLEGE_YEAR_RANGE.MIN, COLLEGE_YEAR_RANGE.MAX]);
  const [collegeHeightFilter, setCollegeHeightFilter] = useState<number | number[]>([HEIGHT_RANGE.MIN, HEIGHT_RANGE.MAX]);
  const [collegeWeightFilter, setCollegeWeightFilter] = useState<number | number[]>([WEIGHT_RANGE.MIN, WEIGHT_RANGE.MAX]);
  const [collegeName, setCollegeName] = useState<string>('');
  const [collegeLevelFilters, setCollegeLevelFilters] = useState<string[]>([]);
  const [fcsConferenceFilters, setFCSConferenceFilters] = useState<number[]>([]);
  const [fbsConferenceFilters, setFBSConferenceFilters] = useState<number[]>([]);
  const [offerFBSConferenceFilters, setOfferFBSConferenceFilters] = useState<number[]>([]);
  const [offerFCSConferenceFilters, setOfferFCSConferenceFilters] = useState<number[]>([]);
  const [transferPortalFilters, setTransferPortalFilters] = useState<string[]>([]);
  const [transferYearsFilters, setTransferYearsFilters] = useState<string[]>([]);

  const [nflRangeFilters, setNflRangeFilters] = useState<any>({});
  const [nflDraftTeamFilters, setNflDraftTeamFilters] = useState<number[]>([]);

  const [positionFilters, setPositionFilters] = useState<PlayerPositionType>(PLAYER_POSITION_DEFAULT_FILTERS);
  const [needMoreFilters, setNeedMoreFilters] = useState<boolean>(false);

  const [hsFilters, setHsFilters] = useState<string[]>([]);
  const [oldHSFilters, setOldHSFilters] = useState<string[]>([]);
  const [collegeFilters, setCollegeFilters] = useState<string[]>([]);
  const [nflFilters, setNflFilters] = useState<string[]>([]);

  const [limit, setLimit] = useState<number>((clientStorage.get(StorageKey.SEARCH_PAGE) || {}).limit || 10);
  const [prevLimit, setPrevLimit] = useState<number>((clientStorage.get(StorageKey.SEARCH_PAGE) || {}).limit || 10);
  const [currentPage, setCurrentPage] = useState<number>((clientStorage.get(StorageKey.SEARCH_PAGE) || {}).currentPage || 1);

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [toastVisible, setToastVisible] = useState<boolean>(false);
  const [toastType, setToastType] = useState<ToastType>(ToastType.SUCCESS);
  const [toastMessage, setToastMessage] = useState<any>('');

  const [hsPlayersForbidden, setHSPlayersForbidden] = useState<boolean>(true);
  const [collegePlayersForbidden, setCollegePlayersForbidden] = useState<boolean>(true);
  const [nflPlayersForbidden, setNFLPlayersForbidden] = useState<boolean>(true);
  const [archivePlayersForbidden, setArchivePlayersForbidden] = useState<boolean>(true);
  const [activePlayersOnly, setActivePlayersOnly] = useState<boolean>(false);
  const [uncommittedEnabled, setUncommittedEnabled] = useState<boolean>(false);
  // query parameters
  const queryString = location.search;
  const withQueryParameters = queryString.replace('?', '').length > 0;

  const resetFilters:boolean = getQueryParameter('reset-filters') === 'true';

  const teamSlug:string = decodeURIComponent(getQueryParameter('team-slug') || '');
  const sortByPAI:boolean = getQueryParameter('sort-by') === 'pai';
  const sortByPerformance:boolean = getQueryParameter('sort-by') === 'performance';
  const sortByMPH:boolean = getQueryParameter('sort-by') === 'mph';

  const showOffers:boolean = getQueryParameter('offers') === 'true';

  const showCommits:boolean = getQueryParameter('commits') === 'true';

  const showSpeedPicks:boolean = getQueryParameter('speed-picks') === 'true';

  const showPowerPicks:boolean = getQueryParameter('power-picks') === 'true';

  const showWatchList:boolean = getQueryParameter('watch-list') === 'true';
  const watchListType:string = getQueryParameter('watch-list-type');
  const watchListYear:number = Number(getQueryParameter('watch-list-year'));

  // HS Triple Verified
  const hsTripleVerified:boolean = getQueryParameter('hs-triple-verified') === 'true';
  const hsOnlyTripleVerified:boolean = getQueryParameter('only-verified') === 'true';
  // hasJucoAccess
  const hasJucoAccess:boolean = getQueryParameter('has-juco-access') === 'true';
  // Transfer portal
  const showTransferPortalPlayers:boolean = getQueryParameter('transfer-portal-players') === 'true';
  const transferPortalStatus:string = getQueryParameter('transfer-portal-status');
  // For Instate Player
  const showInStatePlayers:boolean = getQueryParameter('in-state-player') === 'true';
  const inState:string = getQueryParameter('state');
  const hsClassYear:number = Number(getQueryParameter('hs-class-year'));
  const hsClassAllYears:boolean = !hsClassYear || isNaN(hsClassYear);
  // For HS track players
  const showTopHSTrackPlayers:boolean = getQueryParameter('top-hs-track-player') === 'true';
  const topHSTrackPlayersEvent:string = getQueryParameter('event');

  const isSelectedHSClassYearFilter = hsFilters.includes(HSFilter.HS_CLASS_YEAR);
  const isSelectedArchiveFilter = hsFilters.includes(HSFilter.ARCHIVES);
  const isSelectedPrepFilter = hsFilters.includes(HSFilter.PREP);

  const watchListItems = [
    CollegeFilter.WATCH_LIST,
    CollegeFilter.SENIOR_BOWL,
    CollegeFilter.CGS_SHOWCASE,
    CollegeFilter.EAST_WEST_SHRINE,
  ];

  useEffect(() => {
    fetchCollegeTeams();
    fetchJucoTeams();
    fetchNFLTeams();
    fetchStates();
    fetchFBSConferences();
    fetchFCSConferences();
    fetchUserSettings();
    fetchPlayerCounts();
  }, []);

  useEffect(() => {
    clientStorage.save(StorageKey.SEARCH_PAGE, { currentPage, limit });
  }, [currentPage, limit]);

  useEffect(() => {
    if (prevLimit !== limit) {
      setCurrentPage(1);
      setPrevLimit(limit);
    }
  }, [limit]);

  useEffect(() => {
      if (playerTypeFilters && playerTypeFilters.length == 1 && playerTypeFilters.includes('college')) {
          setActivePlayersOnly(true);
      }
  }, [playerTypeFilters.length])

  useEffect(() => {
    if (!teamSlug) {
      // setAndUpdateColumns();
      setHSClassYearsAndArchiveFilters();
    }
  }, [playerTypeFilters.length, teamSlug]);

  useEffect(() => {
    // only admins can search without having to select additional filters

    const moreThanOneTypeSelected = playerTypeFilters.length > 1;
    const hsSelected = playerTypeFilters.includes(FILTER_TYPE.HIGH_SCHOOL);
    const collegeSelected = playerTypeFilters.includes(FILTER_TYPE.COLLEGE);
    const nflSelected = playerTypeFilters.includes(FILTER_TYPE.NFL);

    const positionSelected = positionFilters[FILTER_TYPE.HIGH_SCHOOL]?.length > 0
      || (collegeSelected && positionFilters[FILTER_TYPE.COLLEGE]?.length > 0)
      || (nflSelected && positionFilters[FILTER_TYPE.NFL]?.length > 0);

    let enoughFiltersSelected = false;
    if (isAdmin
      || (showTransferPortalPlayers)
      || (hsSelected && hsFilters?.length > 1)
      || (!hsSelected && hsFilters?.length > 0)
      || positionSelected
      || (collegeSelected && collegeFilters?.length > 0)
      || (nflSelected && nflFilters?.length > 0)
    ) {
      enoughFiltersSelected = true;
    }
    
    if ((moreThanOneTypeSelected || collegeSelected) && !enoughFiltersSelected) {
      setNeedMoreFilters(true);
      showToast('Please refine your search by using more filters', ToastType.INFO);
    } else if (needMoreFilters) {
      setNeedMoreFilters(false);
    }
  }, [
    isAdmin,
    playerTypeFilters.length,
    hsFilters?.length,
    collegeFilters?.length,
    nflFilters?.length,
    positionFilters,
  ]);

  useEffect(() => {
    setHSClassYearsAndArchiveFilters();
  }, [isSelectedArchiveFilter]);

  useEffect(() => {
    setHSClassYearsAndPrepFilters();
  }, [isSelectedPrepFilter]);

  useEffect(() => {
    if (isSelectedArchiveFilter) {
      setHSClassYearsAndArchiveFilters();
    } else if (isSelectedPrepFilter) {
      setHSClassYearsAndPrepFilters();
    }
  }, [isSelectedHSClassYearFilter]);

  useEffect(() => {
    if (teamSlug && !!collegeTeams?.length) {
      const teamName = (collegeTeams.find((team:Team) => team.slug === teamSlug) || {}).shortName || '';

      setTimeout(() => {
        setFiltersBasedOnQueryParameters(teamName, sortByPAI, sortByPerformance);
        setNeedToRefresh(true);
      }, 100);
    }
  }, [teamSlug, sortByPAI, collegeTeams?.length, jucoTeams?.length]);

  useEffect(() => {
    const searchFilters = clientStorage.get(StorageKey.SEARCH_FILTERS);

    if (searchId) {
      fetchSavedSearchData()
        .then((savedSearch:Search) => {
          setSavedSearchData(savedSearch);
          prepareDrawerContent(savedSearch.criteria);
          fetchSearchPlayers(savedSearch.criteria);
        })
        .catch(console.error);
    } else if (searchFilters && !resetFilters) {
      setFilters(searchFilters);
    }
  }, [searchId, resetFilters]);

  useEffect(() => {
    if (user?.accessLevel) {
      if (hsTripleVerified) setFiltersBasedOnQueryParameters();
      checkAccessLevel(user.accessLevel);
    }
  }, [user?.accessLevel?.hasCollegeAccess, user?.accessLevel?.hasNFLAccess, highSchoolAccessYears]);

  useEffect(() => {
    if (needToApplyFilters) {
      setNeedToApplyFilters(false);
      applyFilters();
    }
  }, [needToApplyFilters]);

  useEffect(() => {
    if (needToRefresh && !pageLoading) {
      setNeedToRefresh(false);
      fetchSearchPlayers();
    }
  }, [needToRefresh, pageLoading]);

  useEffect(() => {
    if (!pageLoading) {
      fetchSearchPlayers();
    }
  }, [currentPage, surfaceToTopValues.length, limit]);

  useEffect(() => {
    if (savedColumns?.length) {
      setAndUpdateColumns();
    } else {
      setAndUpdateColumns(true)
    }
  }, [savedColumns, columnsToShow]);

  useEffect(() => {
      const searchFilters = clientStorage.get(StorageKey.SEARCH_FILTERS);
      if (user?.accessLevel) checkAccessLevel(user.accessLevel);
      if ((!withQueryParameters && !searchFilters) || resetFilters) {
        setNeedToResetFilters(true);
      } else if (withQueryParameters && !teamSlug) {
        setTimeout(() => {
          setFiltersBasedOnQueryParameters();
          setNeedToRefresh(true);
        }, 100);
      } else if (!searchId) {
        setCurrentPage(clientStorage.get(StorageKey.SEARCH_PAGE).currentPage || 1);
        if (!ignoreNeedToApplyFilters) setNeedToApplyFilters(true);
      }
  }, [queryString, resetFilters]);

  useEffect(() => {
    if (needToResetFilters) {
      setNeedToResetFilters(false);
      clearAllFilters();
      setCurrentPage(1);

      setTimeout(() => setNeedToApplyFilters(true), 100);
    }
  }, [needToResetFilters]);

  useEffect(() => {
    setAndUpdateColumnsBasedOnFilters([...hsFilters, ...collegeFilters]);
  }, [hsFilters.length, collegeFilters.length, playerTypeFilters.length]);

  useEffect(() => {
    if (positionFilters?.[FILTER_TYPE.HIGH_SCHOOL]?.length
      && (!hsFilters.includes(PlayerPosition.PRIMARY_POSITION) && !hsFilters.includes(PlayerPosition.SECONDARY_POSITION))
    ) {
      setHsFilters([
        ...hsFilters,
        PlayerPosition.PRIMARY_POSITION,
      ]);
    }
  }, [
    hsFilters?.length,
    positionFilters?.[FILTER_TYPE.HIGH_SCHOOL]?.length,
  ]);

  useEffect(() => {
    if (positionFilters?.[FILTER_TYPE.COLLEGE]?.length
      && (!collegeFilters.includes(PlayerPosition.PRIMARY_POSITION) && !collegeFilters.includes(PlayerPosition.SECONDARY_POSITION))
    ) {
      setCollegeFilters([
        ...collegeFilters,
        PlayerPosition.PRIMARY_POSITION,
      ]);
    }
  }, [
    collegeFilters?.length,
    positionFilters?.[FILTER_TYPE.COLLEGE]?.length,
  ]);

  useEffect(() => {
    if (positionFilters?.[FILTER_TYPE.NFL]?.length
      && (!nflFilters.includes(PlayerPosition.PRIMARY_POSITION) && !nflFilters.includes(PlayerPosition.SECONDARY_POSITION))
    ) {
      setNflFilters([
        ...nflFilters,
        PlayerPosition.PRIMARY_POSITION,
      ]);
    }
  }, [
    nflFilters?.length,
    positionFilters?.[FILTER_TYPE.NFL]?.length,
  ]);

  function fetchSearchPlayers (filters?:any) {
    const playerTypesSelected = filters ? filters.playerType.length > 0 : playerTypeFilters.length > 0;
    if (!playerTypesSelected) return;

    setPageLoading(true);

    gql(`
      search(
        ${searchId ? `savedSearchId: ${searchId}` : ''}
        limit: ${limit},
        offset: ${(currentPage - 1) * limit},
        sort: "${sortedByColumn || ''}",
        order: "${order || ''}",
        ${!!surfaceToTopValues?.length ? `surfaceToTop: ["${surfaceToTopValues.join('", "')}"],` : ''}
        filters: ${prepareFilters(filters || null)},
      ) {
        count
        playerIds
        data {
          id
          slug
          firstName
          lastName
          photoUrl
          pai
          combine
          nflCombineScore
          nflCombines {
            id
          }
          ppi
          compStar
          _247Star
          linkMilesplit
          linkMaxpreps
          link247
          linkRivals
          linkAthleticNet
          linkPff
          linkOn3
          linkSSA
          linkInstagram
          linkDirectAthletics
          linkTwitter
          linkHudl
          linkTrackingFootball
          highSchools
          multiSport
          paiHasFreshmanData
          combineHasFreshmanData
          notes {
            colorCode
          }
          committedToTeamId
          offers
          createdAt
          updatedAt
          playerColleges {
            recruitingClass
            height
            weight
            positions
            isPrimary
            team {
              id
              name
              logoESPN
              logo247
              logoAlt
            }
          }
          playerNFLTeams {
            draftYear
            draftRound
            height
            weight
            positions
            isPrimary
            team {
              id
              name
              logoESPN
              logo247
              logoAlt
            }
          }
          bestTrackEvent
          hsCombines {
            height
            weight
            wingspan
            hand
            arm
            mph
            _10Split
            powerToss
            shuttle
            _40mDash
            verticalJump
            broadJump
            _3Cone
          }
          email
          cellPhone
          address
          igaScore
          watchList
          bestHighSchoolMPH {
          mph
          }
        }
      }
    `)
      .then((data:any) => (data?.search) as SearchPlayersResult)
      .then((search:SearchPlayersResult) => {
        const players = (search?.data || []) as Player[];
        setPlayers(players);

        const allPlayerIds = (search?.playerIds || []) as number[];
        setAllPlayerIds(allPlayerIds);

        const totalCount = (search?.count || 0) as number;
        setTotalPlayersCount(totalCount);
      })
      .catch(console.error)
      .finally(() => setPageLoading(false));
  }

  function fetchSavedSearchData () {
    setPageLoading(true);

    return gql(`
      savedSearch (id: ${searchId}) {
        name
        folder {
          id
          name
        }
        criteria
      }
    `)
      .then((data:any) => data.savedSearch as Search)
      .finally(() => setPageLoading(false));
  }

  function fetchPlayerCounts () {
    return gql(`
      searchCounts {
        archiveCount
        prepCount
        newCount
        internationalCount
      }
    `)
      .then((data:any) => data.searchCounts as SearchCounts)
      .then((searchCounts:SearchCounts) => {
        if (searchCounts) {
          setSearchCounts(searchCounts);
        }
      })
      .catch(console.error);
  }

  function onAlert () {
    setPageLoading(true);

    gql(`
      mutation {
        addAlerts (playerIds: [${selectedPlayers.map((selectedItem:Player) => selectedItem.id)}])
      }
    `)
      .then((data:any) => data && data.addAlerts as boolean)
      .then((success:boolean) => {
        if (success) {
          showToast('Subscribed for updates', ToastType.SUCCESS);
        } else {
          showToast('Oops, something is wrong. Try again or contact our support team.', ToastType.ERROR);
        }
      })
      .catch(error => {
        console.error(error);
        showToast('Oops, something is wrong. Try again or contact our support team.', ToastType.ERROR);
      })
      .finally(() => setPageLoading(false));

      setSelectedPlayers([]);
  }

  function onSave () {
    setPageLoading(true);

    gql(`
      mutation {
        savePlayers (playerIds: [${selectedPlayers.map((selectedItem:Player) => selectedItem.id)}], folderId: null)
      }
    `)
      .then((data:any) => data && data.savePlayers as boolean)
      .then((success:boolean) => {
        if (success) {
          showToast('Saved', ToastType.SUCCESS);
        } else {
          showToast('Oops, something is wrong. Try again or contact our support team.', ToastType.ERROR);
        }
      })
      .catch(error => {
        console.error(error);
        showToast('Oops, something is wrong. Try again or contact our support team.', ToastType.ERROR);
      })
      .finally(() => setPageLoading(false));

      setSelectedPlayers([]);
  }

  function onPin () {
    setPageLoading(true);

    gql(`
      mutation {
        pin (playerIds: [${selectedPlayers.map((selectedItem:Player) => selectedItem.id)}])
      }
    `)
      .then((data:any) => ({
        success: data.pin as boolean,
        errorMessage: data.errorMessage,
      }))
      .then(({ success, errorMessage }:{ success:boolean, errorMessage:string }) => {
        if (success) {
          showToast('Pinned', ToastType.SUCCESS);
        } else if (errorMessage) {
          showToast(errorMessage, ToastType.ERROR);
        } else {
          showToast('Oops, something is wrong. Try again or contact our Support team.', ToastType.ERROR);
        }
      })
      .catch(error => {
        console.error(error);
        showToast('Oops, something is wrong. Try again or contact our Support team.', ToastType.ERROR);
      })
      .finally(() => setPageLoading(false));

      setSelectedPlayers([]);
  }

  function editPlayer (player:Player):Promise<boolean> {
    if (!player?.slug) return Promise.resolve(false);

    return gql(`
      mutation {
        editPlayer(slug: "${player.slug}")
      }
    `)
      .then((data:any) => data.editPlayer as boolean);
  }

  async function onEditPlayers () {
    setPageLoading(true);

    Promise.all(selectedPlayers.map((selectedPlayer:Player) => editPlayer(selectedPlayer)))
      .then((results:boolean[]) => {
        const successfulResults = results.filter(Boolean);

        if (successfulResults.length === results.length) {
          showToast(
            <>All selected players are sent to the
              <Link
                className={classes.importPageLink}
                to={`/import${successfulResults.length === 1 ? `#scrollto-${selectedPlayers[0].slug}` : ''}`}
                target='_blank'
              >Import page</Link>
            </>, ToastType.SUCCESS);
        } else if (successfulResults.length < results.length) {
          showToast(
            <>{successfulResults.length}/{results.length} players were successfully sent to the
              <Link
                className={classes.importPageLink}
                to='/import'
                target='_blank'
              >Import page</Link>
            </>, ToastType.INFO);
        }
      })
      .catch(error => {
        console.error(error);
        showToast('Yikes! Something went wrong. Try again later or contact our support team.', ToastType.ERROR);
      })
      .finally(() => setPageLoading(false));
  }

  function onHidePlayers () {
    const hiddenPlayerIds = (selectedPlayers || []).map((player:Player) => player.id);

    setPlayers(!hiddenPlayerIds?.length
      ? players
      : players.filter((player:Player) => !hiddenPlayerIds.includes(player.id))
    );
  }

  function onXLSDownload (selectedExportType:string) {
    const playerIds = selectedExportType === SelectedXLSType.SELECTED
      ? selectedPlayers.map((player:Player) => player.id)
      : allPlayerIds;

    if (!playerIds.length) {
      showToast(`Select players you want to export, or choose "${SelectedXLSType.ALL}"`, ToastType.INFO);
      return;
    }

    setPageLoading(true);

    exportXLSReport(
      XLSExportType.PLAYERS,
      { ids: playerIds },
      XLSExportPage.SEARCH,
    )
      .catch(error => {
          console.error(error);
        if (error.message === ERROR_MESSAGE_CHECK_EMAIL) {
          showToast('Check e-mail. You should receive the download link in several minutes', ToastType.SUCCESS);
        } else {
          console.error(error);
          showToast(<>Failed to download XLS report. <br />({error.message})</>, ToastType.ERROR);
        }
      })
      .finally(() => setPageLoading(false));
  }

  function setTrackFilterBasedOnEvent (event:string) {
    const newTrackEvents: any = [];
    switch (event) {
      case 'event_100m':
        newTrackEvents.push(HSFilter.S_100_METERS);
        newTrackEvents.push(HSFilter.SPRINTS);
        newTrackEvents.push(HSFilter.SPEED);
        break;
      case 'event_200m':
        newTrackEvents.push(HSFilter.S_200_METERS);
        newTrackEvents.push(HSFilter.SPRINTS);
        newTrackEvents.push(HSFilter.SPEED);
        break;
      case 'event_discus':
        newTrackEvents.push(HSFilter.POWER);
        newTrackEvents.push(HSFilter.DISCUS);
        newTrackEvents.push(HSFilter.THROWING_EVENTS);
        break;
        case 'event_shotput':
        newTrackEvents.push(HSFilter.POWER);
        newTrackEvents.push(HSFilter.SHOTPUT);
        newTrackEvents.push(HSFilter.THROWING_EVENTS);
        break;
      case 'event_long_jump':
        newTrackEvents.push(HSFilter.LONG_JUMP);
        newTrackEvents.push(HSFilter.JUMPING_EVENTS);
        newTrackEvents.push(HSFilter.POWER);
        break;
      case 'event_triple_jump':
        newTrackEvents.push(HSFilter.TRIPLE_JUMP);
        newTrackEvents.push(HSFilter.JUMPING_EVENTS);
        newTrackEvents.push(HSFilter.POWER);
        break;
    }
    return newTrackEvents;
  }

  function setFiltersBasedOnQueryParameters (teamName?:string, sortByPAI?:boolean, sortByPerformance?:boolean) {
    clearAllFilters();
    let newHSFilters:string[] = [];
    let newCollegeFilters:string[] = [];
    let newPlayerTypeFilters = [FILTER_TYPE.HIGH_SCHOOL];
    // Instate Player 
    if (showInStatePlayers) {
      if (inState) {

        newHSFilters = [
          ...newHSFilters.filter(filter => filter !== HSFilter.HS_STATE && filter !== HSFilter.RATING_247),
          HSFilter.HS_STATE,
          HSFilter.RATING_247,
        ];
        setRating247RangeFilter([0, 0]);

        if (hsClassYear || hsClassAllYears) {
          newHSFilters = [
            ...newHSFilters.filter(filter => filter !== HSFilter.HS_CLASS_YEAR),
            HSFilter.HS_CLASS_YEAR,
          ];
          setHSClassYearRangeFilter([
            hsClassAllYears ? HS_CLASS_YEAR_RANGE.MIN : hsClassYear,
            hsClassAllYears ? HS_CLASS_YEAR_RANGE.MAX : hsClassYear,
          ]);
        }

        setSelectedStates([inState]);
      }

      setCurrentPage(1);
    }

    // offers
    if (showOffers) {
      if (hsClassYear || hsClassAllYears) {
        newHSFilters = [
          ...newHSFilters.filter(filter => filter !== HSFilter.HS_CLASS_YEAR),
          HSFilter.HS_CLASS_YEAR,
        ];
        setHSClassYearRangeFilter([
          hsClassAllYears ? HS_CLASS_YEAR_RANGE.MIN : hsClassYear,
          hsClassAllYears ? HS_CLASS_YEAR_RANGE.MAX : hsClassYear,
        ]);
      }

      if (teamName) {
        newHSFilters = [
          ...newHSFilters.filter(filter => filter !== HSFilter.OFFER_SCHOOL),
          HSFilter.OFFER_SCHOOL,
        ];
        setOfferSchoolName(teamName);
      } else {
        newHSFilters = newHSFilters.filter(filter => filter !== HSFilter.OFFER_SCHOOL);
        setOfferSchoolName('');
      }

      setCurrentPage(1);
    }
    
    // commits
    if (showCommits) {
      newHSFilters = [
        ...newHSFilters.filter(filter => filter !== HSFilter.COMMITTED_SCHOOL),
        HSFilter.COMMITTED_SCHOOL,
      ];

      if (hsClassYear || hsClassAllYears) {
        newHSFilters = [
          ...newHSFilters.filter(filter => filter !== HSFilter.HS_CLASS_YEAR),
          HSFilter.HS_CLASS_YEAR,
        ];
        setHSClassYearRangeFilter([
          hsClassAllYears ? HS_CLASS_YEAR_RANGE.MIN : hsClassYear,
          hsClassAllYears ? HS_CLASS_YEAR_RANGE.MAX : hsClassYear,
        ]);
      }

      if (teamName) {
        setCommittedSchoolName(teamName);
      } else {
        setCommittedSchoolName('');
      }

      setCurrentPage(1);
    } else {
      newHSFilters = newHSFilters.filter(filter => filter !== HSFilter.COMMITTED_SCHOOL);
      setCommittedSchoolName('');
    }

    // speed-picks
    if (showSpeedPicks) {
      newHSFilters = [
        ...newHSFilters.filter(filter => filter !== HSFilter.SPEED),
        HSFilter.SPEED,
      ];

      if (hsClassYear || hsClassAllYears) {
        newHSFilters = [
          ...newHSFilters.filter(filter => filter !== HSFilter.HS_CLASS_YEAR),
          HSFilter.HS_CLASS_YEAR,
        ];
        setHSClassYearRangeFilter([
          hsClassAllYears ? HS_CLASS_YEAR_RANGE.MIN : hsClassYear,
          hsClassAllYears ? HS_CLASS_YEAR_RANGE.MAX : hsClassYear,
        ]);
      }

      if (teamName) {
        newHSFilters = [
          ...newHSFilters.filter(filter => filter !== HSFilter.OFFER_SCHOOL),
          HSFilter.OFFER_SCHOOL,
        ];
        setOfferSchoolName(teamName);
      } else {
        newHSFilters = newHSFilters.filter(filter => filter !== HSFilter.OFFER_SCHOOL);
        setOfferSchoolName('');
      }

      setCurrentPage(1);
    } else {
      newHSFilters = newHSFilters.filter(filter => filter !== HSFilter.SPEED);
    }

    // power-picks
    if (showPowerPicks) {
      newHSFilters = [
        ...newHSFilters.filter(filter => filter !== HSFilter.POWER),
        HSFilter.POWER,
      ];

      if (hsClassYear || hsClassAllYears) {
        newHSFilters = [
          ...newHSFilters.filter(filter => filter !== HSFilter.HS_CLASS_YEAR),
          HSFilter.HS_CLASS_YEAR,
        ];
        setHSClassYearRangeFilter([
          hsClassAllYears ? HS_CLASS_YEAR_RANGE.MIN : hsClassYear,
          hsClassAllYears ? HS_CLASS_YEAR_RANGE.MAX : hsClassYear,
        ]);
      }

      if (teamName) {
        newHSFilters = [
          ...newHSFilters.filter(filter => filter !== HSFilter.OFFER_SCHOOL),
          HSFilter.OFFER_SCHOOL,
        ];
        setOfferSchoolName(teamName);
      } else {
        newHSFilters = newHSFilters.filter(filter => filter !== HSFilter.OFFER_SCHOOL);
        setOfferSchoolName('');
      }

      setCurrentPage(1);
    } else {
      newHSFilters = newHSFilters.filter(filter => filter !== HSFilter.POWER);
    }

    if ((!showOffers && !hsClassYear) && (!showCommits && !hsClassYear) && (!showSpeedPicks && !hsClassYear) && (!showPowerPicks && !hsClassYear)) {
      newHSFilters = newHSFilters
        .filter(filter => filter !== HSFilter.HS_CLASS_YEAR)
        .filter(filter => filter !== HSFilter.OFFER_SCHOOL);
      setHSClassYearRangeFilter([HS_CLASS_YEAR_RANGE.MIN, HS_CLASS_YEAR_RANGE.MAX]);
      setOfferSchoolName('');
    }

    if (showWatchList) {
      newCollegeFilters = [
        ...newCollegeFilters.filter(filter => !watchListItems.includes(filter as CollegeFilter)),
        ...watchListItems,
      ]
      // setCollegeFilters([
      //   ...collegeFilters.filter(filter => !watchListItems.includes(filter as CollegeFilter)),
      //   ...watchListItems,
      // ]);
      newPlayerTypeFilters = [FILTER_TYPE.COLLEGE, FILTER_TYPE.NFL];

      let newCollegeRangeFilters = { ...collegeRangeFilters };

      if (watchListType === CollegeFilter.SENIOR_BOWL) {
        // setCollegeFilters([
        //   ...collegeFilters.filter(filter => !watchListItems.includes(filter as CollegeFilter)),
        //   ...watchListItems.filter(filter => filter == CollegeFilter.SENIOR_BOWL || filter == CollegeFilter.WATCH_LIST),
        // ]);
        newCollegeFilters = [
          ...newCollegeFilters.filter(filter => !watchListItems.includes(filter as CollegeFilter)),
          ...watchListItems.filter(filter => filter == CollegeFilter.SENIOR_BOWL || filter == CollegeFilter.WATCH_LIST),
        ]
      } else if (watchListType === CollegeFilter.EAST_WEST_SHRINE) {
        // setCollegeFilters([
        //   ...collegeFilters.filter(filter => !watchListItems.includes(filter as CollegeFilter)),
        //   ...watchListItems.filter(filter => filter == CollegeFilter.EAST_WEST_SHRINE || filter == CollegeFilter.WATCH_LIST),
        // ]);
        newCollegeFilters = [
          ...newCollegeFilters.filter(filter => !watchListItems.includes(filter as CollegeFilter)),
          ...watchListItems.filter(filter => filter == CollegeFilter.EAST_WEST_SHRINE || filter == CollegeFilter.WATCH_LIST),
        ]
      } else if (watchListType === CollegeFilter.CGS_SHOWCASE) {
        // setCollegeFilters([
        //   ...collegeFilters.filter(filter => !watchListItems.includes(filter as CollegeFilter)),
        //   ...watchListItems.filter(filter => filter == CollegeFilter.CGS_SHOWCASE || filter == CollegeFilter.WATCH_LIST),
        // ]);
        newCollegeFilters = [
          ...newCollegeFilters.filter(filter => !watchListItems.includes(filter as CollegeFilter)),
          ...watchListItems.filter(filter => filter == CollegeFilter.CGS_SHOWCASE || filter == CollegeFilter.WATCH_LIST),
        ]
      }

      newCollegeRangeFilters[CollegeFilter.SENIOR_BOWL] = [watchListType === CollegeFilter.SENIOR_BOWL && watchListYear ? watchListYear : WATCH_LIST_YEAR_RANGE.MIN, watchListType === CollegeFilter.SENIOR_BOWL && watchListYear ? watchListYear : WATCH_LIST_YEAR_RANGE.MAX];
      newCollegeRangeFilters[CollegeFilter.CGS_SHOWCASE] = [watchListType === CollegeFilter.CGS_SHOWCASE && watchListYear ? watchListYear : WATCH_LIST_YEAR_RANGE.MIN, watchListType === CollegeFilter.CGS_SHOWCASE && watchListYear ? watchListYear : WATCH_LIST_YEAR_RANGE.MAX];
      newCollegeRangeFilters[CollegeFilter.EAST_WEST_SHRINE] = [watchListType === CollegeFilter.EAST_WEST_SHRINE && watchListYear ? watchListYear : WATCH_LIST_YEAR_RANGE.MIN, watchListType === CollegeFilter.EAST_WEST_SHRINE && watchListYear ? watchListYear : WATCH_LIST_YEAR_RANGE.MAX];

      setCollegeRangeFilters(newCollegeRangeFilters);
    } else {
      // setCollegeFilters(collegeFilters.filter(filter => !watchListItems.includes(filter as CollegeFilter)));
      newCollegeFilters = newCollegeFilters.filter(filter => !watchListItems.includes(filter as CollegeFilter));
    }

    // hasJucoAccess
    if (hasJucoAccess) {
      newCollegeFilters = [
        ...newCollegeFilters.filter(filter => CollegeFilter.COLLEGE_LEVEL != filter),
        CollegeFilter.COLLEGE_LEVEL,
      ]
      newPlayerTypeFilters = [FILTER_TYPE.COLLEGE];

      setCollegeLevelFilters(['JUCO']);
    }

    // hsTripleVerified
    if (hsTripleVerified || (showTopHSTrackPlayers && topHSTrackPlayersEvent && topHSTrackPlayersEvent == 'mph')) {
      newHSFilters = [
        ...newHSFilters.filter(filter => HSFilter.HS_MPH != filter),
        HSFilter.HS_MPH,
      ];
      newPlayerTypeFilters = [FILTER_TYPE.HIGH_SCHOOL];
      if (hsClassYear  || hsClassAllYears) {
        newHSFilters = [
          ...newHSFilters.filter(filter => filter !== HSFilter.HS_CLASS_YEAR),
          HSFilter.HS_CLASS_YEAR,
        ];
        setHSClassYearRangeFilter([
          hsClassAllYears ? HS_CLASS_YEAR_RANGE.MIN : hsClassYear,
          hsClassAllYears ? HS_CLASS_YEAR_RANGE.MAX : hsClassYear,
        ]);
      }
      if (hsOnlyTripleVerified) {
        newHSFilters = [
          ...newHSFilters.filter(filter => filter !== HSFilter.COMBINE && filter !== HSFilter.PAI),
          HSFilter.COMBINE,
          HSFilter.PAI,
        ];
      }
      setCurrentPage(1);
    } else {
      newHSFilters = newHSFilters.filter(filter => filter !== HSFilter.HS_MPH);
    }
    
    if (showTransferPortalPlayers && transferPortalStatus) {
      newCollegeFilters = [
        ...newCollegeFilters.filter(filter => CollegeFilter.TRANSFER_PORTAL != filter && CollegeFilter.TRANSFER_YEARS != filter),
        CollegeFilter.TRANSFER_PORTAL,
        CollegeFilter.TRANSFER_YEARS,
      ];
      
      newPlayerTypeFilters = [FILTER_TYPE.COLLEGE];
      
      const currentYearRolloverDate = new Date(new Date().getFullYear(), rolloverMonthTransferPortal - 1, rolloverDateTransferPortal);
      const twoDigitTransferStartYear = Number((new Date() < currentYearRolloverDate ? new Date().getFullYear() - 1 : new Date().getFullYear()).toString().slice(-2));
      // const twoDigitCurrentRecruitingYear:number = Number(currentRecruitingYear.toString().slice(-2));
      const shorthandCurrentRecruitingYear = `${twoDigitTransferStartYear}-${twoDigitTransferStartYear + 1}`;
      setTransferYearsFilters([shorthandCurrentRecruitingYear.toString()]);
      setTransferPortalFilters([transferPortalStatus]);
    }

    // Top HS Track Players
    if (showTopHSTrackPlayers) {
      if (topHSTrackPlayersEvent && topHSTrackPlayersEvent !== 'mph') {
        const newTrackEvents = setTrackFilterBasedOnEvent(topHSTrackPlayersEvent);
        newHSFilters = [
          ...newHSFilters.filter(filter => !newTrackEvents.includes(filter)),
          ...newTrackEvents
        ];

        if (hsClassYear || hsClassAllYears) {
          newHSFilters = [
            ...newHSFilters.filter(filter => filter !== HSFilter.HS_CLASS_YEAR),
            HSFilter.HS_CLASS_YEAR,
          ];
          setHSClassYearRangeFilter([
            hsClassAllYears ? HS_CLASS_YEAR_RANGE.MIN : hsClassYear,
            hsClassAllYears ? HS_CLASS_YEAR_RANGE.MAX : hsClassYear,
          ]);
        }
      }
      setCurrentPage(1);
    }
    console.log('newHSFilters', newHSFilters);
    console.log('newPlayerTypeFilters', newPlayerTypeFilters);
    setPlayerTypeFilters(newPlayerTypeFilters);
    setHsFilters(newHSFilters);
    setCollegeFilters(newCollegeFilters);

    if (sortByPAI) {
      onSort(PlayerColumn.PAI_SCORE);
    }
    
    if (sortByPerformance) {
      const trackEvent = getTrackEventNameForSort(topHSTrackPlayersEvent);
      const order = ['event_100m', 'event_200m'].includes(topHSTrackPlayersEvent) ? Order.asc : Order.desc;
      onSort(trackEvent, order);
    }

    if (sortByMPH) {
      onSort(PlayerColumn.HS_MPH, Order.desc);
    }
  }

  function getTrackEventNameForSort(e: string) {
    switch (e) {
      case 'event_100m':
        setOrder(SEARCH_COLUMNS_ORDERING_RULES[HSFilter.S_100_METERS]);
        return PlayerColumn.TRACK_100M;
      case 'event_200m':
        setOrder(SEARCH_COLUMNS_ORDERING_RULES[HSFilter.S_200_METERS]);
        return PlayerColumn.TRACK_200M;
      case 'event_discus':
        return PlayerColumn.TRACK_DISCUS;
      case 'event_shotput':
        return PlayerColumn.TRACK_SHOTPUT;
      case 'event_long_jump':
        return PlayerColumn.TRACK_LONG_JUMP;
      case 'event_triple_jump':
        return PlayerColumn.TRACK_TRIPLE_JUMP;
      default:
        return PlayerColumn.TRACK_100M;
    } 
  }

  function checkAccessLevel (accessLevel?:AccessLevel) {
    if (!accessLevel) return;

    const hasHSAccess = highSchoolAccessYears.length > 0;
    const hasCollegeAccess = accessLevel.hasCollegeAccess;

    let newPlayerTypeFilters = [...playerTypeFilters];

    if (hasHSAccess) {
      setHSPlayersForbidden(false);
    } else {
      setHSPlayersForbidden(true);
      newPlayerTypeFilters = newPlayerTypeFilters.filter(playerType => playerType !== FILTER_TYPE.HIGH_SCHOOL);
      setHsFilters(hsFilters.filter(hsFilter => {
        return hsFilter !== HSFilter.HS_CLASS_YEAR
          && hsFilter !== HSFilter.NEW_PLAYER
          && hsFilter !== HSFilter.INTERNATIONAL;
      }));
    }

    if (hasCollegeAccess) {
      setCollegePlayersForbidden(false);
    } else {
      setCollegePlayersForbidden(true);
      newPlayerTypeFilters = newPlayerTypeFilters.filter(playerType => playerType !== FILTER_TYPE.COLLEGE);
    }

    if (accessLevel.hasNFLAccess) {
      setNFLPlayersForbidden(false);
    } else {
      setNFLPlayersForbidden(true);
      newPlayerTypeFilters = newPlayerTypeFilters.filter(playerType => playerType !== FILTER_TYPE.NFL);
    }
    let colToShow = [];
    let disableColToShow: PlayerColumn[] = [];
    if (hasHSAccess) {
      if (!newPlayerTypeFilters.length) newPlayerTypeFilters = [FILTER_TYPE.HIGH_SCHOOL];
      colToShow.push(...HS_PLAYER_COLUMNS);
      disableColToShow.push(...HS_PLAYER_DEFAULT_COLUMNS);
    }

    if (hasCollegeAccess || accessLevel.hasJucoAccess) {
      if (hasJucoAccess && accessLevel.hasJucoAccess && !hasCollegeAccess) {
        newPlayerTypeFilters = [FILTER_TYPE.COLLEGE]
      };
      colToShow.push(...COLLEGE_PLAYER_COLUMNS);
      disableColToShow.push(...COLLEGE_PLAYER_DEFAULT_COLUMNS);
    }

    if (accessLevel.hasNFLAccess) {
      colToShow.push(...NFL_PLAYER_COLUMNS);
      disableColToShow.push(...NFL_PLAYER_DEFAULT_COLUMNS);
    }

    const disableCol = colToShow.filter((column: PlayerColumn) => disableColToShow.includes(column));
    const restCol = colToShow.filter((column: PlayerColumn) => !disableColToShow.includes(column));
    const orderedColumns = [...disableCol, ...restCol];

    setColumnsToShow(Array.from(new Set(orderedColumns.flat())));
    setDefaultDisableColumns(Array.from(new Set(disableColToShow.flat())));
    setDisableColumns(Array.from(new Set(disableColToShow.flat())));

    if (accessLevel.hasArchiveAccess) {
      setArchivePlayersForbidden(false);
    } else {
      setArchivePlayersForbidden(true);
      setHsFilters(hsFilters.filter(hsFilter => hsFilter !== HSFilter.ARCHIVES));
    }

    setPlayerTypeFilters(newPlayerTypeFilters);
  }

  function handlePlayerTypeChange (filteredPlayerType:string) {
    return (value:boolean) => {
      if (value) {
        setPlayerTypeFilters([...playerTypeFilters, filteredPlayerType]);
      } else {
        setPlayerTypeFilters(playerTypeFilters.filter(filter => filter !== filteredPlayerType));
      }
    };
  }

  function clearAllFilters () {
    clearPlayerTypeHSFilters();
    clearPlayerTypeCollegeFilters();
    clearPlayerTypeNFLFilters();

    const newHsFilters = [];
    const hasHSAccess = highSchoolAccessYears.length > 0;
    if (hasHSAccess) {
      newHsFilters.push(HSFilter.HS_CLASS_YEAR);
    }
    setHsFilters(newHsFilters);
    setCollegeFilters([]);
    setNflFilters([]);

    const newPlayerTypes = [
      !hsPlayersForbidden && FILTER_TYPE.HIGH_SCHOOL,
      !collegePlayersForbidden && hsPlayersForbidden && nflPlayersForbidden && FILTER_TYPE.COLLEGE,
      !nflPlayersForbidden && hsPlayersForbidden && FILTER_TYPE.NFL,
    ].filter(Boolean) as string[];

    setPlayerTypeFilters(newPlayerTypes);
    
    setAndUpdateColumns(false, true);
  }

  function clearPlayerTypeHSFilters () {
    setHSClassYearRangeFilter([HS_CLASS_YEAR_RANGE.MIN, HS_CLASS_YEAR_RANGE.MAX]);
    setHSArchiveFilter([ARCHIVE_YEAR_RANGE.MIN, ARCHIVE_YEAR_RANGE.MAX]);
    setPrepFilter([PREP_YEAR_RANGE.MIN, PREP_YEAR_RANGE.MAX]);
    setHsHeightRangeFilter([HEIGHT_RANGE.MIN, HEIGHT_RANGE.MAX]);
    setHsWeightRangeFilter([WEIGHT_RANGE.MIN, WEIGHT_RANGE.MAX]);
    setHSWingspanRangeFilter([WINGSPAN_RANGE.MIN, WINGSPAN_RANGE.MAX]);
    setHSArmLengthRangeFilter([ARM_LENGTH_RANGE.MIN, ARM_LENGTH_RANGE.MAX]);
    setHSHandSizeRangeFilter([HAND_SIZE_RANGE.MIN, HAND_SIZE_RANGE.MAX]);
    setPaiRangeFilter([0, 5]);
    setCombineScoreRangeFilter([0, 5]);
    setCombineRangeFilters({});
    setRating247RangeFilter([0, 5]);
    setOfferCountRangeFilter([0, 70]);
    setHsMPHRangeFilter([0, 30]);
    setHSTrackAndFieldYearRangeFilter([TRACK_AND_FIELD_YEAR_RANGE.MIN, TRACK_AND_FIELD_YEAR_RANGE.MAX]);
    setTrackAndFieldEventFilters({});
    setHSName('');
    setHSCity('');
    setHSCounty('');
    setActMinimum('');
    setSatMinimum('');
    setGpaMinimum('');
    setOfferSchoolName('');
    setCommittedSchoolName('');
    setCombineYearRangeFilter([COMBINE_YEAR_RANGE.MIN, COMBINE_YEAR_RANGE.MAX]);
    setCombineCityName('');
    setCombineEventFilters([]);
    setOtherSportFilters([]);
    setSelectedStates([]);
    setSearchByNamePlayers([]);
  }

  function clearPlayerTypeCollegeFilters() {
    setCollegeRangeFilters({});
    setCollegeClassYearRangeFilter([COLLEGE_YEAR_RANGE.MIN, COLLEGE_YEAR_RANGE.MAX]);
    setCollegeHeightFilter([HEIGHT_RANGE.MIN, HEIGHT_RANGE.MAX]);
    setCollegeWeightFilter([WEIGHT_RANGE.MIN, WEIGHT_RANGE.MAX]);
    setCollegeName('');
    setCollegeLevelFilters([]);
    setFCSConferenceFilters([]);
    setFBSConferenceFilters([]);
    setTransferPortalFilters([]);
    setTransferYearsFilters([]);
  }

  function clearPlayerTypeNFLFilters() {
    setNflRangeFilters({});
    setNflDraftTeamFilters([]);
    setProCombineScoreRangeFilter([0, 5]);
    setPpiRangeFilter([0, 5]);
    setPositionFilters(PLAYER_POSITION_DEFAULT_FILTERS);
  }

  function handleAllPositionChange(filteredPlayerPosition:any, playerType:string) {
    return (value: boolean) => {
      if (value) {
        setPositionFilters({...positionFilters, [playerType]: [...(positionFilters[playerType] ? positionFilters[playerType] : []), filteredPlayerPosition.value]});
      } else {
        const selectedFiltersWithoutCheckedItem = [...positionFilters[playerType]];
        const removeIndex = positionFilters[playerType].findIndex(item => item === filteredPlayerPosition.value);
        if (removeIndex > -1) {
          selectedFiltersWithoutCheckedItem.splice(removeIndex, 1);
        }
        setPositionFilters({...positionFilters, [playerType]: selectedFiltersWithoutCheckedItem});
      }
    };
  }

  function checkAndResetTrackFieldRanges (selectedItemsWithoutCheckedItem:string[]) {
    const trackAndFieldEventRanges = trackAndFieldEventFilters;
    const trackAndFieldEvents = [
      ...SPRINTS,
      ...HURDLES,
      ...RELAYS,
      ...JUMPING_EVENTS,
      ...THROWING_EVENTS,
      ...MIDDLE_DISTANCE,
    ];

    trackAndFieldEvents.map((fieldEvent:any) => {
      if (!selectedItemsWithoutCheckedItem.includes(fieldEvent.value)) {
        trackAndFieldEventRanges[fieldEvent.value] = [fieldEvent.range.min, fieldEvent.range.max];
      }
      return undefined;
    })

    setTrackAndFieldEventFilters(trackAndFieldEventRanges);
  }

  function onFilterCheckBoxChange (checkedItem:string, playerType:string) {
    return (value: boolean) => {
      switch (playerType) {
        case FILTER_TYPE.HIGH_SCHOOL:
          if (value) {
            setHsFilters([...hsFilters, checkedItem]);
          } else {
            let selectedItemsWithoutCheckedItem = [...hsFilters];
            const removeIndex = hsFilters.findIndex(item => item === checkedItem);
            if (removeIndex > -1) {
              selectedItemsWithoutCheckedItem.splice(removeIndex, 1);
            }

            if (!selectedItemsWithoutCheckedItem.includes(PlayerPosition.PRIMARY_POSITION) && !selectedItemsWithoutCheckedItem.includes(PlayerPosition.SECONDARY_POSITION)) {
              setPositionFilters({
                ...positionFilters,
                [FILTER_TYPE.HIGH_SCHOOL]: []
              })
            }

            if (!selectedItemsWithoutCheckedItem.includes(HSFilter.OTHER_SPORTS)) {
              setOtherSportFilters([]);
            }

            if (!selectedItemsWithoutCheckedItem.includes(HSFilter.OFFER_CONFERENCE)) {
              selectedItemsWithoutCheckedItem = selectedItemsWithoutCheckedItem.filter((hsFilter) => (![HSFilter.FBS, HSFilter.FCS].includes(hsFilter as HSFilter)));
            }

            if (!selectedItemsWithoutCheckedItem.includes(HSFilter.FBS) || !selectedItemsWithoutCheckedItem.includes(HSFilter.OFFER_CONFERENCE)) {
              setOfferFBSConferenceFilters([]);
            }

            if (!selectedItemsWithoutCheckedItem.includes(HSFilter.FCS) || !selectedItemsWithoutCheckedItem.includes(HSFilter.OFFER_CONFERENCE)) {
              setOfferFCSConferenceFilters([]);
            }

            if (!selectedItemsWithoutCheckedItem.includes(HSFilter.HIGHEST_LEVEL_OFFER)) {
              selectedItemsWithoutCheckedItem = selectedItemsWithoutCheckedItem.filter((hsFilter) => (
                ![HSFilter.HIGHEST_LEVEL_OFFER_P5, HSFilter.HIGHEST_LEVEL_OFFER_G5, HSFilter.HIGHEST_LEVEL_OFFER_FCS]
                  .includes(hsFilter as HSFilter)
                ));
            }

            if (!selectedItemsWithoutCheckedItem.includes(HSFilter.COMMITTED_SCHOOL)) {
              setCommittedSchoolName('');
            }

            if (!selectedItemsWithoutCheckedItem.includes(HSFilter.ACT)) {
              setActMinimum('');
            }

            if (!selectedItemsWithoutCheckedItem.includes(HSFilter.SAT)) {
              setSatMinimum('');
            }

            if (!selectedItemsWithoutCheckedItem.includes(HSFilter.GPA)) {
              setGpaMinimum('');
            }

            if (!selectedItemsWithoutCheckedItem.includes(HSFilter.PLAYER_NAMES)) {
              setSearchByNamePlayers([]);
            }

            if (!selectedItemsWithoutCheckedItem.includes(HSFilter.SPRINTS)) {
              selectedItemsWithoutCheckedItem = selectedItemsWithoutCheckedItem.filter((hsFilter) => (
                ![
                  HSFilter.SPRINTS,
                  ...SPRINTS.map((sprint) => sprint.value)
                ].includes(hsFilter as HSFilter)
              ));
            }

            if (!selectedItemsWithoutCheckedItem.includes(HSFilter.HURDLES)) {
              selectedItemsWithoutCheckedItem = selectedItemsWithoutCheckedItem.filter((hsFilter) => (
                ![
                  HSFilter.HURDLES,
                  ...HURDLES.map((hurdleEvent) => hurdleEvent.value)
                ].includes(hsFilter as HSFilter)
              ));
            }

            if (!selectedItemsWithoutCheckedItem.includes(HSFilter.MIDDLE_DISTANCE)) {
              selectedItemsWithoutCheckedItem = selectedItemsWithoutCheckedItem.filter((hsFilter) => (
                ![
                  HSFilter.MIDDLE_DISTANCE,
                  ...MIDDLE_DISTANCE.map((middleDistanceEvent) => middleDistanceEvent.value)
                ].includes(hsFilter as HSFilter)
              ));
            }

            if (!selectedItemsWithoutCheckedItem.includes(HSFilter.RELAYS)) {
              selectedItemsWithoutCheckedItem = selectedItemsWithoutCheckedItem.filter((hsFilter) => (
                ![
                  HSFilter.RELAYS,
                  ...RELAYS.map((relayEvent) => relayEvent.value)
                ].includes(hsFilter as HSFilter)
              ));
            }

            if (!selectedItemsWithoutCheckedItem.includes(HSFilter.THROWING_EVENTS)) {
              selectedItemsWithoutCheckedItem = selectedItemsWithoutCheckedItem.filter((hsFilter) => (
                ![
                  HSFilter.THROWING_EVENTS,
                  ...THROWING_EVENTS.map((throwingEvent) => throwingEvent.value)
                ].includes(hsFilter as HSFilter)
              ));
            }

            if (!selectedItemsWithoutCheckedItem.includes(HSFilter.JUMPING_EVENTS)) {
              selectedItemsWithoutCheckedItem = selectedItemsWithoutCheckedItem.filter((hsFilter) => (
                ![
                  HSFilter.JUMPING_EVENTS,
                  ...JUMPING_EVENTS.map((jumpingEvent) => jumpingEvent.value)
                ].includes(hsFilter as HSFilter)
              ));
            }

            if (!selectedItemsWithoutCheckedItem.includes(HSFilter.HS_CLASS_YEAR)) {
              setHSClassYearRangeFilter([HS_CLASS_YEAR_RANGE.MIN, HS_CLASS_YEAR_RANGE.MAX]);
            }

            if (!selectedItemsWithoutCheckedItem.includes(HSFilter.ARCHIVES)) {
              setHSArchiveFilter([ARCHIVE_YEAR_RANGE.MIN, ARCHIVE_YEAR_RANGE.MAX]);
            }

            if (!selectedItemsWithoutCheckedItem.includes(HSFilter.PAI)) {
              setPaiRangeFilter([0, 5]);
            }
            if (!selectedItemsWithoutCheckedItem.includes(HSFilter.HS_MPH)) {
              setHsMPHRangeFilter([0, 30]);
            }

            if (!selectedItemsWithoutCheckedItem.includes(HSFilter.COMBINE)) {
              setCombineScoreRangeFilter([0, 5]);
            }

            if (!selectedItemsWithoutCheckedItem.includes(HSFilter.RATING_247)) {
              setRating247RangeFilter([0, 5]);
            }

            if (!selectedItemsWithoutCheckedItem.includes(HSFilter.OFFER_COUNT)) {
              setOfferCountRangeFilter([0, 70]);
            }

            if (!selectedItemsWithoutCheckedItem.includes(HSFilter.COMBINE_YEAR)) {
              setCombineYearRangeFilter([COMBINE_YEAR_RANGE.MIN, COMBINE_YEAR_RANGE.MAX]);
            }

            if (!selectedItemsWithoutCheckedItem.includes(HSFilter.HS_ARM_LENGTH)) {
              setHSArmLengthRangeFilter([ARM_LENGTH_RANGE.MIN, ARM_LENGTH_RANGE.MAX]);
            }

            if (!selectedItemsWithoutCheckedItem.includes(HSFilter.HS_HANDSIZE)) {
              setHSHandSizeRangeFilter([HAND_SIZE_RANGE.MIN, HAND_SIZE_RANGE.MAX]);
            }

            if (!selectedItemsWithoutCheckedItem.includes(HSFilter.HS_WINGSPAN)) {
              setHSWingspanRangeFilter([WINGSPAN_RANGE.MIN, WINGSPAN_RANGE.MAX]);
            }

            if (!selectedItemsWithoutCheckedItem.includes(HSFilter.HS_WEIGHT)) {
              setHsWeightRangeFilter([WEIGHT_RANGE.MIN, WEIGHT_RANGE.MAX]);
            }

            if (!selectedItemsWithoutCheckedItem.includes(HSFilter.HS_HEIGHT)) {
              setHsHeightRangeFilter([HEIGHT_RANGE.MIN, HEIGHT_RANGE.MAX]);
            }

            if (!selectedItemsWithoutCheckedItem.includes(HSFilter.TRACK_AND_FIELD_DATA_YEAR)) {
              setHSTrackAndFieldYearRangeFilter([TRACK_AND_FIELD_YEAR_RANGE.MIN, TRACK_AND_FIELD_YEAR_RANGE.MAX]);
            }

            let updatedCombineRangeFilters = { ...combineRangeFilters };

            if (!selectedItemsWithoutCheckedItem.includes(HSFilter.COMBINE_MPH)) {
              updatedCombineRangeFilters = {
                ...updatedCombineRangeFilters,
                [HSFilter.COMBINE_MPH]: undefined,
              };
            }

            if (!selectedItemsWithoutCheckedItem.includes(HSFilter.COMBINE_10_SPLIT)) {
              updatedCombineRangeFilters = {
                ...updatedCombineRangeFilters,
                [HSFilter.COMBINE_10_SPLIT]: undefined,
              };
            }

            if (!selectedItemsWithoutCheckedItem.includes(HSFilter.COMBINE_40_M_DASH)) {
              updatedCombineRangeFilters = {
                ...updatedCombineRangeFilters,
                [HSFilter.COMBINE_40_M_DASH]: undefined,
              };
            }

            if (!selectedItemsWithoutCheckedItem.includes(HSFilter.COMBINE_SHUTTLE)) {
              updatedCombineRangeFilters = {
                ...updatedCombineRangeFilters,
                [HSFilter.COMBINE_SHUTTLE]: undefined,
              };
            }

            if (!selectedItemsWithoutCheckedItem.includes(HSFilter.COMBINE_3_CONE)) {
              updatedCombineRangeFilters = {
                ...updatedCombineRangeFilters,
                [HSFilter.COMBINE_3_CONE]: undefined,
              };
            }

            if (!selectedItemsWithoutCheckedItem.includes(HSFilter.COMBINE_VERTICAL_JUMP)) {
              updatedCombineRangeFilters = {
                ...updatedCombineRangeFilters,
                [HSFilter.COMBINE_VERTICAL_JUMP]: undefined,
              };
            }

            if (!selectedItemsWithoutCheckedItem.includes(HSFilter.COMBINE_BROAD_JUMP)) {
              updatedCombineRangeFilters = {
                ...updatedCombineRangeFilters,
                [HSFilter.COMBINE_BROAD_JUMP]: undefined,
              };
            }

            if (!selectedItemsWithoutCheckedItem.includes(HSFilter.COMBINE_POWERTOSS)) {
              updatedCombineRangeFilters = {
                ...updatedCombineRangeFilters,
                [HSFilter.COMBINE_POWERTOSS]: undefined,
              };
            }

            setCombineRangeFilters(updatedCombineRangeFilters);

            if (!selectedItemsWithoutCheckedItem.includes(HSFilter.COMBINE_EVENT)) {
              setCombineEventFilters([]);
            }

            if (!selectedItemsWithoutCheckedItem.includes(HSFilter.COMBINE_CITY)) {
              setCombineCityName('');
            }

            if (!selectedItemsWithoutCheckedItem.includes(HSFilter.OFFER_SCHOOL)) {
              setOfferSchoolName('');
            }

            setHsFilters(selectedItemsWithoutCheckedItem);
            checkAndResetTrackFieldRanges(selectedItemsWithoutCheckedItem);
          }
          break;
        case FILTER_TYPE.COLLEGE:
          if (value) {
            setCollegeFilters([...collegeFilters, checkedItem]);
          } else {
            const selectedItemsWithoutCheckedItem = [...collegeFilters];
            const removeIndex = collegeFilters.findIndex(item => item === checkedItem);
            if (removeIndex > -1) {
              selectedItemsWithoutCheckedItem.splice(removeIndex, 1);
            }
            let updatedCollegeFilters = [...selectedItemsWithoutCheckedItem];

            if (!selectedItemsWithoutCheckedItem.includes(PlayerPosition.PRIMARY_POSITION) && !selectedItemsWithoutCheckedItem.includes(PlayerPosition.SECONDARY_POSITION)) {
              setPositionFilters({
                ...positionFilters,
                [FILTER_TYPE.COLLEGE]: [],
              })
            }

            if (!selectedItemsWithoutCheckedItem.includes(CollegeFilter.COLLEGE_NAME)) {
              setCollegeName('');
            }

            if (!selectedItemsWithoutCheckedItem.includes(CollegeFilter.COLLEGE_LEVEL)) {
              setCollegeLevelFilters([]);
            }

            if (!selectedItemsWithoutCheckedItem.includes(CollegeFilter.FBS_CONFERENCE)) {
              setFBSConferenceFilters([]);
            }

            if (!selectedItemsWithoutCheckedItem.includes(CollegeFilter.FCS_CONFERENCE)) {
              setFCSConferenceFilters([]);
            }

            if (!selectedItemsWithoutCheckedItem.includes(CollegeFilter.TRANSFER_PORTAL)) {
              setTransferPortalFilters([]);
            }

            if (!selectedItemsWithoutCheckedItem.includes(CollegeFilter.TRANSFER_YEARS)) {
              setTransferYearsFilters([]);
            }

            let updatedCollegeRangeFilters:any = { ...collegeRangeFilters };

            if (!selectedItemsWithoutCheckedItem.includes(CollegeFilter.AWARDS)) {
              updatedCollegeFilters = updatedCollegeFilters.filter(filterName => {
                return ![CollegeFilter.ALL_AMERICAN, CollegeFilter.ALL_CONFERENCE].includes(filterName as CollegeFilter)
              });

              updatedCollegeRangeFilters = {
                ...updatedCollegeRangeFilters,
                [CollegeFilter.ALL_AMERICAN]: undefined,
                [CollegeFilter.ALL_CONFERENCE]: undefined,
              };
            }

            if (!selectedItemsWithoutCheckedItem.includes(CollegeFilter.ALL_AMERICAN)) {
              updatedCollegeRangeFilters = {
                ...updatedCollegeRangeFilters,
                [CollegeFilter.ALL_AMERICAN]: undefined,
              };
            }

            if (!selectedItemsWithoutCheckedItem.includes(CollegeFilter.ALL_CONFERENCE)) {
              updatedCollegeRangeFilters = {
                ...updatedCollegeRangeFilters,
                [CollegeFilter.ALL_CONFERENCE]: undefined,
              };
            }

            if (!selectedItemsWithoutCheckedItem.includes(CollegeFilter.WATCH_LIST)) {
              updatedCollegeFilters = updatedCollegeFilters.filter(filterName => {
                return ![CollegeFilter.WATCH_LIST, CollegeFilter.SENIOR_BOWL, CollegeFilter.CGS_SHOWCASE, CollegeFilter.EAST_WEST_SHRINE].includes(filterName as CollegeFilter)
              });
            }

            if (!selectedItemsWithoutCheckedItem.includes(CollegeFilter.WATCH_LIST)
              || !selectedItemsWithoutCheckedItem.includes(CollegeFilter.SENIOR_BOWL)
            ) {
              updatedCollegeRangeFilters = {
                ...updatedCollegeRangeFilters,
                [CollegeFilter.SENIOR_BOWL]: undefined,
              };
            }

            if (!selectedItemsWithoutCheckedItem.includes(CollegeFilter.WATCH_LIST)
              || !selectedItemsWithoutCheckedItem.includes(CollegeFilter.CGS_SHOWCASE)
            ) {
              updatedCollegeRangeFilters = {
                ...updatedCollegeRangeFilters,
                [CollegeFilter.CGS_SHOWCASE]: undefined,
              };
            }

            if (!selectedItemsWithoutCheckedItem.includes(CollegeFilter.WATCH_LIST)
              || !selectedItemsWithoutCheckedItem.includes(CollegeFilter.EAST_WEST_SHRINE)
            ) {
              updatedCollegeRangeFilters = {
                ...updatedCollegeRangeFilters,
                [CollegeFilter.EAST_WEST_SHRINE]: undefined,
              };
            }

            if (!selectedItemsWithoutCheckedItem.includes(CollegeFilter.RECRUITING_CLASS)) {
              setCollegeClassYearRangeFilter([COLLEGE_YEAR_RANGE.MIN, COLLEGE_YEAR_RANGE.MAX]);
            }

            if (!selectedItemsWithoutCheckedItem.includes(CollegeFilter.WEIGHT)) {
              setCollegeWeightFilter([WEIGHT_RANGE.MIN, WEIGHT_RANGE.MAX]);
            }

            if (!selectedItemsWithoutCheckedItem.includes(CollegeFilter.HEIGHT)) {
              setCollegeHeightFilter([HEIGHT_RANGE.MIN, HEIGHT_RANGE.MAX]);
            }

            setCollegeFilters(updatedCollegeFilters);
            setCollegeRangeFilters(updatedCollegeRangeFilters);
          }
          break;
        case FILTER_TYPE.NFL:
          if (value) {
            setNflFilters([...nflFilters, checkedItem]);
          } else {
            const selectedItemsWithoutCheckedItem = [...nflFilters];
            const removeIndex = nflFilters.findIndex(item => item === checkedItem);
            if (removeIndex > -1) {
              selectedItemsWithoutCheckedItem.splice(removeIndex, 1);
            }

            let updatedNflFilters = [...selectedItemsWithoutCheckedItem];

            if (!selectedItemsWithoutCheckedItem.includes(PlayerPosition.PRIMARY_POSITION) && !selectedItemsWithoutCheckedItem.includes(PlayerPosition.SECONDARY_POSITION)) {
              setPositionFilters({
                ...positionFilters,
                [FILTER_TYPE.NFL]: []
              });
            }

            if (!selectedItemsWithoutCheckedItem.includes(NFLFilter.DRAFT_TEAM)) {
              setNflDraftTeamFilters([]);
            }

            let updatedNflRangeFilters = { ...nflRangeFilters };

            if (!selectedItemsWithoutCheckedItem.includes(NFLFilter.PRO_COMBINE_SCORE)) {
              setProCombineScoreRangeFilter([0, 5]);
            }

            if (!selectedItemsWithoutCheckedItem.includes(NFLFilter.PPI)) {
              setPpiRangeFilter([0, 5]);
            }


            if (!selectedItemsWithoutCheckedItem.includes(NFLFilter.DRAFT_YEAR)) {
              updatedNflRangeFilters = {
                ...updatedNflRangeFilters,
                [NFLFilter.DRAFT_YEAR]: undefined,
              };
            }

            if (!selectedItemsWithoutCheckedItem.includes(NFLFilter.DRAFT_ROUND)) {
              updatedNflRangeFilters = {
                ...updatedNflRangeFilters,
                [NFLFilter.DRAFT_ROUND]: undefined,
              };
            }

            if (!selectedItemsWithoutCheckedItem.includes(NFLFilter.HEIGHT)) {
              updatedNflRangeFilters = {
                ...updatedNflRangeFilters,
                [NFLFilter.HEIGHT]: undefined,
              };
            }

            if (!selectedItemsWithoutCheckedItem.includes(NFLFilter.WEIGHT)) {
              updatedNflRangeFilters = {
                ...updatedNflRangeFilters,
                [NFLFilter.WEIGHT]: undefined,
              };
            }

            if (!selectedItemsWithoutCheckedItem.includes(NFLFilter.AWARDS)) {
              updatedNflFilters = updatedNflFilters.filter(filterName => {
                return ![NFLFilter.ALL_PRO, NFLFilter.PRO_BOWL].includes(filterName as NFLFilter)
              });

              updatedNflRangeFilters = {
                ...updatedNflRangeFilters,
                [NFLFilter.ALL_PRO]: undefined,
                [NFLFilter.PRO_BOWL]: undefined,
              };
            }

            if (!selectedItemsWithoutCheckedItem.includes(NFLFilter.ALL_PRO)) {
              updatedNflRangeFilters = {
                ...updatedNflRangeFilters,
                [NFLFilter.ALL_PRO]: undefined,
              };
            }

            if (!selectedItemsWithoutCheckedItem.includes(NFLFilter.PRO_BOWL)) {
              updatedNflRangeFilters = {
                ...updatedNflRangeFilters,
                [NFLFilter.PRO_BOWL]: undefined,
              };
            }
            setNflFilters(updatedNflFilters);
            setNflRangeFilters(updatedNflRangeFilters);
          }
          break;
        default:
          break;
      }
    };
  }

  function getAllSelectedAndDefaultColumns (resetAllColumns?:boolean):{
    allSelectedColumns:string[];
    defaultColumns:string[];
    allColumns:string[];
    playerTypeBasedColumn:string[];
    orderedColumns:string[];
  } {
    if (playerTypeFilters.length > 1) {
      const blendedModeColumns:string[] = [...BLENDED_PLAYER_DEFAULT_COLUMNS];
      blendedModeColumns.splice(5, 0, ...BLENDED_PLAYER_COLUMNS);

      let allSelectedColumns:string[] = []
      let orderedColumns:string[] = [];

        if (savedColumns?.length) {
          orderedColumns = savedColumns.map((column:Column) => column.name);
          allSelectedColumns = Array.from(new Set([...savedColumns.filter((column:Column) => column.selected).map((column:Column) => column.name)]))
        } else {
          allSelectedColumns = resetAllColumns
            ? blendedModeColumns
            : Array.from(new Set([
              ...columns.filter((column:Column) => column.selected).map((column:Column) => column.name),
              // ...blendedModeColumns,
            ]));
        }

      return {
        allSelectedColumns,
        defaultColumns: [...BLENDED_PLAYER_DEFAULT_COLUMNS],
        allColumns: resetAllColumns
          ? PLAYER_ALL_COLUMNS
          : columns.map((column:Column) => column.name),
        playerTypeBasedColumn: BLENDED_PLAYER_COLUMNS,
        orderedColumns
      };
    } else {
      let playerTypeBasedColumn = HS_PLAYER_COLUMNS;
      let defaultColumns = HS_PLAYER_DEFAULT_COLUMNS;

      if (playerTypeFilters.includes(FILTER_TYPE.COLLEGE)) {
        playerTypeBasedColumn = COLLEGE_PLAYER_COLUMNS;
        defaultColumns = COLLEGE_PLAYER_DEFAULT_COLUMNS;
      } else if (playerTypeFilters.includes(FILTER_TYPE.NFL)) {
        playerTypeBasedColumn = NFL_PLAYER_COLUMNS;
        defaultColumns = NFL_PLAYER_DEFAULT_COLUMNS;
      }
      let allSelectedColumns = [];
      let orderedColumns:string[] = [];
      if (savedColumns?.length) {
        orderedColumns = savedColumns.map((column:Column) => column.name);
        allSelectedColumns = Array.from(new Set([...savedColumns.filter((column:Column) => column.selected).map((column:Column) => column.name)]))
      } else {
        allSelectedColumns = resetAllColumns
          ? playerTypeBasedColumn
          : Array.from(new Set([
            ...columns.filter((column:Column) => column.selected).map((column:Column) => column.name),
            ...playerTypeBasedColumn,
          ]));
      }

      return {
        allSelectedColumns,
        defaultColumns,
        allColumns: resetAllColumns
          ? PLAYER_ALL_COLUMNS
          : columns.map((column:Column) => column.name),
        playerTypeBasedColumn,
        orderedColumns
      };
    }
  }

  function getAllSelectedAndDefaultColumns1 (resetAllColumns?:boolean):{
    allSelectedColumns:string[];
    defaultColumns:string[];
    allColumns:string[];
    playerTypeBasedColumn:string[];
    orderedColumns:string[];
  } {

    let allSelectedColumns:string[] = []
    let orderedColumns:string[] = [];

    if (savedColumns?.length) {
      orderedColumns = savedColumns?.map((column:Column) => column.name);
      allSelectedColumns = Array.from(new Set([...savedColumns?.filter((column:Column) => column.selected).map((column:Column) => column.name)]))
    } else {
      allSelectedColumns = resetAllColumns
        ? columnsToShow
        : Array.from(new Set([
          ...columns.filter((column:Column) => column.selected).map((column:Column) => column.name),
        ]));
    }

  return {
    allSelectedColumns,
    defaultColumns: [...disableColumns],
    allColumns: resetAllColumns
      ? columnsToShow
      : columns.map((column:Column) => column.name),
    playerTypeBasedColumn: columnsToShow,
    orderedColumns
  };
  }

  function reorderArray(originalArray: string[], orderArray: string[]) {
    // Create a map to store the indexes of each element in the orderArray
    const indexMap = new Map();
    orderArray.forEach((item, index) => {
      indexMap.set(item, index);
    });
  
    // Sort the originalArray based on the order specified in the orderArray
    originalArray.sort((a, b) => {
      const indexA = indexMap.get(a);
      const indexB = indexMap.get(b);
      return indexA - indexB;
    });
  
    return originalArray;
  }
  
  function setAndUpdateColumns (resetAllColumns?:boolean, resetFilter?:boolean) {
    const {
      allSelectedColumns,
      defaultColumns,
      playerTypeBasedColumn,
      orderedColumns
    } = getAllSelectedAndDefaultColumns1(resetAllColumns);

    if (resetAllColumns) {
      setColumns(columnsToShow.map((columnName:string) => ({
        name: columnName,
        selected: true,
      } as Column)));
      setDisableColumns(Array.from(new Set(defaultDisableColumns.flat())));
    } else {
      if (savedColumns?.length) {
        const allColPerPlayerType = Array.from(new Set([...defaultColumns, ...playerTypeBasedColumn]))
        const ordereArray = reorderArray(allColPerPlayerType, orderedColumns);
        setColumns([...ordereArray].map((column:string) => ({
          name: column,
          selected: allSelectedColumns.includes(column) || defaultColumns.includes(column),
        } as Column)));
        setAndUpdateColumnsBasedOnFilters([...hsFilters, ...collegeFilters], resetFilter);
      } else {
        setColumns(columns.map((column:Column) => ({
          name: column.name,
          selected: allSelectedColumns.includes(column.name),
        } as Column)));
      }
    }

    setDefaultColumns(defaultColumns);
  }

  function setAndUpdateColumnsBasedOnFilters (filters:string[], resetFilter?:boolean) {
    const {
      allSelectedColumns,
      defaultColumns,
      playerTypeBasedColumn
    } = getAllSelectedAndDefaultColumns1(true);
    const trackFieldColumns = new Set<PlayerColumn>();

    filters.forEach((filter:string) => {
      switch (filter) {
        case HSFilter.S_55_METERS:
          trackFieldColumns.add(PlayerColumn.TRACK_55M);
          break;
        case HSFilter.S_60_METERS:
          trackFieldColumns.add(PlayerColumn.TRACK_60M);
          break;
        case HSFilter.S_100_METERS:
          trackFieldColumns.add(PlayerColumn.TRACK_100M);
          break;
        case HSFilter.S_200_METERS:
          trackFieldColumns.add(PlayerColumn.TRACK_200M);
          break;
        case HSFilter.S_300_METERS:
          trackFieldColumns.add(PlayerColumn.TRACK_300M);
          break;
        case HSFilter.S_400_METERS:
          trackFieldColumns.add(PlayerColumn.TRACK_400M);
          break;
        case HSFilter.MD_800_METERS:
          trackFieldColumns.add(PlayerColumn.TRACK_800M);
          break;
        case HSFilter.HH_55_METERS:
          trackFieldColumns.add(PlayerColumn.TRACK_55HH);
          break;
        case HSFilter.HH_60_METERS:
          trackFieldColumns.add(PlayerColumn.TRACK_60HH);
          break;
        case HSFilter.HH_110_METERS:
          trackFieldColumns.add(PlayerColumn.TRACK_110HH);
          break;
        case HSFilter.H_300_INT:
          trackFieldColumns.add(PlayerColumn.TRACK_300IH);
          break;
        case HSFilter.H_400_METERS:
          trackFieldColumns.add(PlayerColumn.TRACK_400H);
          break;
        case HSFilter.R_400_METERS:
          trackFieldColumns.add(PlayerColumn.TRACK_400R);
          break;
        case HSFilter.R_800_METERS:
          trackFieldColumns.add(PlayerColumn.TRACK_800R);
          break;
        case HSFilter.R_1600_METERS:
          trackFieldColumns.add(PlayerColumn.TRACK_1600R);
          break;
        case HSFilter.R_3200_METERS:
          trackFieldColumns.add(PlayerColumn.TRACK_3200R);
          break;
        case HSFilter.SHOTPUT:
          trackFieldColumns.add(PlayerColumn.TRACK_SHOTPUT);
          break;
        case HSFilter.DISCUS:
          trackFieldColumns.add(PlayerColumn.TRACK_DISCUS);
          break;
        case HSFilter.JAVELIN:
          trackFieldColumns.add(PlayerColumn.TRACK_JAVELIN);
          break;
        case HSFilter.HIGH_JUMP:
          trackFieldColumns.add(PlayerColumn.TRACK_HIGH_JUMP);
          break;
        case HSFilter.LONG_JUMP:
          trackFieldColumns.add(PlayerColumn.TRACK_LONG_JUMP);
          break;
        case HSFilter.TRIPLE_JUMP:
          trackFieldColumns.add(PlayerColumn.TRACK_TRIPLE_JUMP);
          break;
        case HSFilter.POLE_VAULT_JUMP:
          trackFieldColumns.add(PlayerColumn.TRACK_POLE_VAULT);
          break;
        case HSFilter.COMBINE_MPH:
          trackFieldColumns.add(PlayerColumn.COMBINE_MPH);
          break;
        case HSFilter.COMBINE_SHUTTLE:
          trackFieldColumns.add(PlayerColumn.COMBINE_SHUTTLE);
          break;
        case HSFilter.COMBINE_10_SPLIT:
          trackFieldColumns.add(PlayerColumn.COMBINE_10_SPLIT);
          break;
        case HSFilter.COMBINE_3_CONE:
          trackFieldColumns.add(PlayerColumn.COMBINE_3_CONE);
          break;
        case HSFilter.COMBINE_40_M_DASH:
          trackFieldColumns.add(PlayerColumn.COMBINE_40M_DASH);
          break;
        case HSFilter.COMBINE_BROAD_JUMP:
          trackFieldColumns.add(PlayerColumn.COMBINE_BROAD_JUMP);
          break;
        case HSFilter.COMBINE_VERTICAL_JUMP:
          trackFieldColumns.add(PlayerColumn.COMBINE_VERTICAL_JUMP);
          break;
        case HSFilter.COMBINE_WINGSPAN:
          trackFieldColumns.add(PlayerColumn.COMBINE_WINGSPAN);
          break;
        case HSFilter.COMBINE_POWERTOSS:
          trackFieldColumns.add(PlayerColumn.COMBINE_POWER_TOSS);
          break;
        case CollegeFilter.SENIOR_BOWL:
          trackFieldColumns.add(PlayerColumn.WATCH_LIST);
          trackFieldColumns.add(PlayerColumn.WATCH_LIST_YEAR);
          break;
        case CollegeFilter.CGS_SHOWCASE:
          trackFieldColumns.add(PlayerColumn.WATCH_LIST);
          trackFieldColumns.add(PlayerColumn.WATCH_LIST_YEAR);
          break;
        case CollegeFilter.EAST_WEST_SHRINE:
          trackFieldColumns.add(PlayerColumn.WATCH_LIST);
          trackFieldColumns.add(PlayerColumn.WATCH_LIST_YEAR);
          break;
        default:
          break;
      }
    });

    if (showWatchList) {
      trackFieldColumns.add(PlayerColumn.WATCH_LIST);
      trackFieldColumns.add(PlayerColumn.WATCH_LIST_YEAR);
    }

    const scoreColumns:Array<PlayerColumn> = [];

    if (playerTypeFilters.includes(FILTER_TYPE.NFL) || playerTypeFilters.includes(FILTER_TYPE.COLLEGE)) {
      scoreColumns.push(PlayerColumn.PPI_SCORE);
    }

    if (playerTypeFilters.includes(FILTER_TYPE.NFL)) {
      scoreColumns.push(PlayerColumn.PRO_COMBINE_SCORE);
    }

    const allSelectedColumnsCopy = [...allSelectedColumns];
    const allColumnsCopy = [...defaultColumns, ...playerTypeBasedColumn];

    const indexOfFirstSelectableColumn = allColumnsCopy.findIndex((column:string) => !defaultColumns.includes(column));
    allColumnsCopy.splice(indexOfFirstSelectableColumn, 0, ...Array.from(trackFieldColumns));
    allSelectedColumnsCopy.splice(indexOfFirstSelectableColumn, 0, ...Array.from(trackFieldColumns));
    
    const indexOfScoreColumn = allColumnsCopy.findIndex((column:string) => column === PlayerColumn.COMBINE_SCORE);
    allColumnsCopy.splice(indexOfScoreColumn + 1, 0, ...scoreColumns);
    allSelectedColumnsCopy.splice(indexOfScoreColumn + 1, 0, ...scoreColumns);
    let disableTrackCol = [...disableColumns, ...Array.from(trackFieldColumns)]
    if (resetFilter) {
      disableTrackCol = defaultDisableColumns;
    }
    setDisableColumns(Array.from(new Set(disableTrackCol.flat())));
    updateColumnsBasedOnAccessLevel(Array.from(new Set(allSelectedColumnsCopy)), Array.from(new Set(allColumnsCopy)));
  }

  function updateColumnsBasedOnAccessLevel (selectedColumns:string[], allColumns:string[]) {
    const { orderedColumns } = getAllSelectedAndDefaultColumns1();
    allColumns = reorderArray(allColumns, orderedColumns);
    // const filteredSelectedColumns = selectedColumns.filter((column:string) => !(
    //   [PlayerColumn.COLLEGE_POSITION, PlayerColumn.HS_POSITION, PlayerColumn.NFL_POSITION].includes(column as PlayerColumn))
    // );
    // const filteredAllColumns = allColumns.filter((column:string) => !(
    //   [PlayerColumn.COLLEGE_POSITION, PlayerColumn.HS_POSITION, PlayerColumn.NFL_POSITION].includes(column as PlayerColumn))
    // );

    // if (playerTypeFilters.length === 1) {
    //   switch (playerTypeFilters[0]) {
    //     case FILTER_TYPE.COLLEGE:
    //       filteredSelectedColumns.splice(1, 0, PlayerColumn.COLLEGE_POSITION);
    //       filteredAllColumns.splice(1, 0, PlayerColumn.COLLEGE_POSITION);
    //       break;
    //     case FILTER_TYPE.NFL:
    //       filteredSelectedColumns.splice(1, 0, PlayerColumn.NFL_POSITION);
    //       filteredAllColumns.splice(1, 0, PlayerColumn.NFL_POSITION);
    //       break;
    //     case FILTER_TYPE.HIGH_SCHOOL:
    //       filteredSelectedColumns.splice(1, 0, PlayerColumn.HS_POSITION);
    //       filteredAllColumns.splice(1, 0, PlayerColumn.HS_POSITION);
    //       break;
    //     default:
    //       break;
    //   }
    // } else {
    //   const includeHighSchoolPlayers = playerTypeFilters.includes(FILTER_TYPE.HIGH_SCHOOL);
    //   const includeCollegePlayers = playerTypeFilters.includes(FILTER_TYPE.COLLEGE);
    //   const includeNFLPlayers = playerTypeFilters.includes(FILTER_TYPE.NFL);

    //   if (includeHighSchoolPlayers && includeCollegePlayers && !includeNFLPlayers) {
    //     filteredSelectedColumns.splice(1, 0, ...[PlayerColumn.HS_POSITION, PlayerColumn.COLLEGE_POSITION]);
    //     filteredAllColumns.splice(1, 0, ...[PlayerColumn.HS_POSITION, PlayerColumn.COLLEGE_POSITION]);
    //   } else if (includeHighSchoolPlayers && includeCollegePlayers && includeNFLPlayers) {
    //     filteredSelectedColumns.splice(1, 0, ...[PlayerColumn.HS_POSITION, PlayerColumn.COLLEGE_POSITION, PlayerColumn.NFL_POSITION]);
    //     filteredAllColumns.splice(1, 0, ...[PlayerColumn.HS_POSITION, PlayerColumn.COLLEGE_POSITION, PlayerColumn.NFL_POSITION]);
    //   } else if (!includeHighSchoolPlayers && includeCollegePlayers && includeNFLPlayers) {
    //     filteredSelectedColumns.splice(1, 0, ...[PlayerColumn.COLLEGE_POSITION, PlayerColumn.NFL_POSITION]);
    //     filteredAllColumns.splice(1, 0, ...[PlayerColumn.COLLEGE_POSITION, PlayerColumn.NFL_POSITION]);
    //   } else if (includeHighSchoolPlayers && !includeCollegePlayers && includeNFLPlayers) {
    //     filteredSelectedColumns.splice(1, 0, ...[PlayerColumn.HS_POSITION, PlayerColumn.NFL_POSITION]);
    //     filteredAllColumns.splice(1, 0, ...[PlayerColumn.HS_POSITION, PlayerColumn.NFL_POSITION]);
    //   }
    // }
    setColumns(allColumns.map((columnName:string) => ({
      name: columnName,
      selected: selectedColumns.includes(columnName),
    } as Column)));
  }

  function setHSClassYearsAndArchiveFilters () {
    const isSelectedHSPlayerType = playerTypeFilters.includes(FILTER_TYPE.HIGH_SCHOOL);
    const isPreviouslySelectedHSClassYearFilter = oldHSFilters.includes(HSFilter.HS_CLASS_YEAR);
    const isPreviouslySelectedArchiveFilter = oldHSFilters.includes(HSFilter.ARCHIVES);

    setOldHSFilters([...hsFilters]);

    // let newHSClass = [
    //   Math.max(HS_CLASS_YEAR_RANGE.MIN, Array.isArray(hsClassYearRangeFilter) ? hsClassYearRangeFilter[0] : HS_CLASS_YEAR_RANGE.MIN),
    //   Math.min(HS_CLASS_YEAR_RANGE.MAX, Array.isArray(hsClassYearRangeFilter) ? hsClassYearRangeFilter[1] : HS_CLASS_YEAR_RANGE.MAX),
    // ];
    let newHSClass = [
      HS_CLASS_YEAR_RANGE.MIN,
      HS_CLASS_YEAR_RANGE.MAX,
    ];

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const isHSSelected = playerTypeFilters.includes(FILTER_TYPE.HIGH_SCHOOL)
    if (playerTypeFilters.includes(FILTER_TYPE.NFL)) {
      let maxYear = currentYear;
      if (isHSSelected) {
        maxYear = HS_CLASS_YEAR_RANGE.MAX;
      }
      const minYear = currentYear - 25;
      // newHSClass = [
      //   Math.max(HS_CLASS_YEAR_RANGE.MIN, minYear),
      //   Math.min(HS_CLASS_YEAR_RANGE.MAX, maxYear),
      // ];
      newHSClass = [ minYear, maxYear ];
      setHSClassYearRangeFilter(newHSClass);
    } else if (playerTypeFilters.includes(FILTER_TYPE.COLLEGE) && !playerTypeFilters.includes(FILTER_TYPE.NFL) ) {
      const minYear = currentYear - 15;
      let maxYear = currentYear;
      if (isHSSelected) {
        maxYear = HS_CLASS_YEAR_RANGE.MAX;
      }
      newHSClass = [ minYear, maxYear ];
      setHSClassYearRangeFilter(newHSClass);
    }
    // setHSClassYearRangeFilter(newHSClass);

    if (isSelectedHSPlayerType) {
      if (!isSelectedHSClassYearFilter && !isSelectedArchiveFilter) {
        // both HS Class and Archive are NOT selected
        setHsFilters([...hsFilters, HSFilter.HS_CLASS_YEAR]);
      } else if (!isAdmin && isSelectedArchiveFilter && !isPreviouslySelectedArchiveFilter && isSelectedHSClassYearFilter) {
        // Archive is selected - toggle off HS Class Year (only for non-admin accounts)
        setHsFilters(hsFilters.filter(filter => filter !== HSFilter.HS_CLASS_YEAR));
      } else if (!isAdmin && isSelectedHSClassYearFilter && !isPreviouslySelectedHSClassYearFilter && isSelectedArchiveFilter) {
        // HS Class Year is selected - toggle off Archive (only for non-admin accounts)
        if (isSelectedPrepFilter) {
          setHsFilters(hsFilters.filter(filter => (filter !== HSFilter.ARCHIVES && filter !== HSFilter.PREP)));
        } else {
          setHsFilters(hsFilters.filter(filter => filter !== HSFilter.ARCHIVES));
        }
      }
    } else if ((isSelectedHSClassYearFilter || isSelectedArchiveFilter) && !isAdmin) {
      // Toggle off both Archive and HS Class year
      setHsFilters(hsFilters.filter(filter => {
        return filter !== HSFilter.ARCHIVES;
      }));
    }
  }

  function setHSClassYearsAndPrepFilters () {
    const isSelectedHSPlayerType = playerTypeFilters.includes(FILTER_TYPE.HIGH_SCHOOL);
    const isPreviouslySelectedHSClassYearFilter = oldHSFilters.includes(HSFilter.HS_CLASS_YEAR);
    const isPreviouslySelectedPrepFilter = oldHSFilters.includes(HSFilter.PREP);

    setOldHSFilters([...hsFilters]);

    // let newHSClass = [
    //   Math.max(HS_CLASS_YEAR_RANGE.MIN, Array.isArray(hsClassYearRangeFilter) ? hsClassYearRangeFilter[0] : HS_CLASS_YEAR_RANGE.MIN),
    //   Math.min(HS_CLASS_YEAR_RANGE.MAX, Array.isArray(hsClassYearRangeFilter) ? hsClassYearRangeFilter[1] : HS_CLASS_YEAR_RANGE.MAX),
    // ];
    let newHSClass = [
      PREP_YEAR_RANGE.MIN,
      PREP_YEAR_RANGE.MAX,
    ];

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const isHSSelected = playerTypeFilters.includes(FILTER_TYPE.HIGH_SCHOOL)
    if (playerTypeFilters.includes(FILTER_TYPE.NFL)) {
      let maxYear = currentYear;
      if (isHSSelected) {
        maxYear = PREP_YEAR_RANGE.MAX;
      }
      const minYear = currentYear - 25;
      // newHSClass = [
      //   Math.max(PREP_YEAR_RANGE.MIN, minYear),
      //   Math.min(PREP_YEAR_RANGE.MAX, maxYear),
      // ];
      newHSClass = [ minYear, maxYear ];
    } else if (playerTypeFilters.includes(FILTER_TYPE.COLLEGE) && !playerTypeFilters.includes(FILTER_TYPE.NFL) ) {
      const minYear = currentYear - 15;
      let maxYear = currentYear;
      if (isHSSelected) {
        maxYear = PREP_YEAR_RANGE.MAX;
      }
      newHSClass = [ minYear, maxYear ];
    }
    setHSClassYearRangeFilter(newHSClass);

    if (isSelectedHSPlayerType) {
      if (!isSelectedHSClassYearFilter && !isSelectedPrepFilter) {
        // both HS Class and Archive are NOT selected
        setHsFilters([...hsFilters, HSFilter.HS_CLASS_YEAR]);
      } else if (!isAdmin && isSelectedPrepFilter && !isPreviouslySelectedPrepFilter && isSelectedHSClassYearFilter) {
        // Archive is selected - toggle off HS Class Year (only for non-admin accounts)
        setHsFilters(hsFilters.filter(filter => filter !== HSFilter.HS_CLASS_YEAR));
      } else if (!isAdmin && isSelectedHSClassYearFilter && !isPreviouslySelectedHSClassYearFilter && isSelectedPrepFilter) {
        // HS Class Year is selected - toggle off Archive (only for non-admin accounts)
        if (isSelectedArchiveFilter) {
          setHsFilters(hsFilters.filter(filter => (filter !== HSFilter.ARCHIVES && filter !== HSFilter.PREP)));
        } else {
          setHsFilters(hsFilters.filter(filter => filter !== HSFilter.PREP));
        }
      }
    } else if ((isSelectedHSClassYearFilter || isSelectedPrepFilter) && !isAdmin) {
      // Toggle off both Archive and HS Class year
      setHsFilters(hsFilters.filter(filter => {
        return filter !== HSFilter.PREP;
      }));
    }
  }
  

  function onCombineEventFilterCheckBoxChange (combineEvent:string) {
    return (value:boolean) => {
      if (value) {
        setCombineEventFilters([...combineEventFilters, combineEvent]);
      } else {
        const selectedFiltersWithoutCheckedItem = [...combineEventFilters];
        const removeIndex = combineEventFilters.findIndex(item => item === combineEvent);
        if (removeIndex > -1) {
          selectedFiltersWithoutCheckedItem.splice(removeIndex, 1);
        }
        setCombineEventFilters(selectedFiltersWithoutCheckedItem);
      }
    };
  }

  function onLevelFilterCheckBoxChange(level:string) {
    return (value:boolean) => {
      if (value) {
        setCollegeLevelFilters([...collegeLevelFilters, level]);
      } else {
        const selectedFiltersWithoutCheckedItem = [...collegeLevelFilters];
        const removeIndex = collegeLevelFilters.findIndex(item => item === level);
        if (removeIndex > -1) {
          selectedFiltersWithoutCheckedItem.splice(removeIndex, 1);
        }
        setCollegeLevelFilters(selectedFiltersWithoutCheckedItem);
      }
    };
  }

  function onFCSConferenceFilterCheckBoxChange(conference:number) {
    return (value:boolean) => {
      if (value) {
        setFCSConferenceFilters([...fcsConferenceFilters, conference]);
      } else {
        const selectedFiltersWithoutCheckedItem = [...fcsConferenceFilters];
        const removeIndex = fcsConferenceFilters.findIndex(item => item === conference);
        if (removeIndex > -1) {
          selectedFiltersWithoutCheckedItem.splice(removeIndex, 1);
        }
        setFCSConferenceFilters(selectedFiltersWithoutCheckedItem);
      }
    };
  }

  function onFBSConferenceFilterCheckBoxChange(conference:number) {
    return (value:boolean) => {
      if (value) {
        setFBSConferenceFilters([...fbsConferenceFilters, conference]);
      } else {
        const selectedFiltersWithoutCheckedItem = [...fbsConferenceFilters];
        const removeIndex = fbsConferenceFilters.findIndex(item => item === conference);
        if (removeIndex > -1) {
          selectedFiltersWithoutCheckedItem.splice(removeIndex, 1);
        }
        setFBSConferenceFilters(selectedFiltersWithoutCheckedItem);
      }
    };
  }

  function onTransferPortalFilterCheckBoxChange(portal:string) {
    return (value:boolean) => {
      if (value) {
        setTransferPortalFilters([...transferPortalFilters, portal]);
      } else {
        const selectedFiltersWithoutCheckedItem = [...transferPortalFilters];
        const removeIndex = transferPortalFilters.findIndex(item => item === portal);
        if (removeIndex > -1) {
          selectedFiltersWithoutCheckedItem.splice(removeIndex, 1);
        }
        setTransferPortalFilters(selectedFiltersWithoutCheckedItem);
      }
    };
  }

  function onTransferYearsFilterCheckBoxChange(transferYear:string) {
    return (value:boolean) => {
      if (value) {
        setTransferYearsFilters([...transferYearsFilters, transferYear]);
      } else {
        const selectedFiltersWithoutCheckedItem = [...transferYearsFilters];
        const removeIndex = transferYearsFilters.findIndex(item => item === transferYear);
        if (removeIndex > -1) {
          selectedFiltersWithoutCheckedItem.splice(removeIndex, 1);
        }
        setTransferYearsFilters(selectedFiltersWithoutCheckedItem);
      }
    };
  }

  function onNFLDraftTeamCheckBoxChange(draftTeamId:number) {
    return (value:boolean) => {
      if (value) {
        setNflDraftTeamFilters([...nflDraftTeamFilters, draftTeamId]);
      } else {
        const selectedFiltersWithoutCheckedItem = [...nflDraftTeamFilters];
        const removeIndex = nflDraftTeamFilters.findIndex(item => item === draftTeamId);
        if (removeIndex > -1) {
          selectedFiltersWithoutCheckedItem.splice(removeIndex, 1);
        }
        setNflDraftTeamFilters(selectedFiltersWithoutCheckedItem);
      }
    };
  }

  function onOtherSportCheckBoxChange(sport:string) {
    return (value:boolean) => {
      if (value) {
        setOtherSportFilters([...otherSportFilters, sport]);
      } else {
        const selectedFiltersWithoutCheckedItem = [...otherSportFilters];
        const removeIndex = otherSportFilters.findIndex(item => item === sport);
        if (removeIndex > -1) {
          selectedFiltersWithoutCheckedItem.splice(removeIndex, 1);
        }
        setOtherSportFilters(selectedFiltersWithoutCheckedItem);
      }
    };
  }

  function onOfferFBSConferenceCheckBoxChange(conference:number) {
    return (value:boolean) => {
      if (value) {
        setOfferFBSConferenceFilters([...offerFBSConferenceFilters, conference]);
      } else {
        const selectedFiltersWithoutCheckedItem = [...offerFBSConferenceFilters];
        const removeIndex = offerFBSConferenceFilters.findIndex(item => item === conference);
        if (removeIndex > -1) {
          selectedFiltersWithoutCheckedItem.splice(removeIndex, 1);
        }
        setOfferFBSConferenceFilters(selectedFiltersWithoutCheckedItem);
      }
    };
  }

  function onOfferFCSConferenceCheckBoxChange(conference:number) {
    return (value:boolean) => {
      if (value) {
        setOfferFCSConferenceFilters([...offerFCSConferenceFilters, conference]);
      } else {
        const selectedFiltersWithoutCheckedItem = [...offerFCSConferenceFilters];
        const removeIndex = offerFCSConferenceFilters.findIndex(item => item === conference);
        if (removeIndex > -1) {
          selectedFiltersWithoutCheckedItem.splice(removeIndex, 1);
        }
        setOfferFCSConferenceFilters(selectedFiltersWithoutCheckedItem);
      }
    };
  }

  function onSort (columnName:PlayerColumn, explicitOrder?:Order) {
    let newOrder = Order.desc;
    if (sortedByColumn === columnName) {
      newOrder = order === Order.asc ? Order.desc : Order.asc;
    }
    newOrder = explicitOrder || newOrder;
    setOrder(newOrder);
    setSortedByColumn(columnName);
    setNeedToRefresh(true);
  }

  const activePlayersEnabled = playerTypeFilters.length === 1 && playerTypeFilters.includes(FILTER_TYPE.COLLEGE);
  const isOnlyHighSchoolSelected = playerTypeFilters.length === 1 && playerTypeFilters.includes(FILTER_TYPE.HIGH_SCHOOL);

  function prepareFilters (filters?:any) {
    const hsPositionCodes = filters?.hsPositionCodes?.length
      ? filters.hsPositionCodes.map((positionCode:string) => `"${positionCode}"`)
      : (positionFilters[FILTER_TYPE.HIGH_SCHOOL] || []).map(positionCode => `"${positionCode}"`);
    const hsPrimaryPositionEnabled = filters
      ? filters.hsPrimaryPositionEnabled
      : (hsFilters || []).includes(PlayerPosition.PRIMARY_POSITION);
    const hsSecondaryPositionEnabled = filters
      ? filters.hsSecondaryPositionEnabled
      : (hsFilters || []).includes(PlayerPosition.SECONDARY_POSITION);

    const collegePositionCodes = filters?.collegePositionCodes?.length
      ? filters.collegePositionCodes.map((positionCode:string) => `"${positionCode}"`)
      : (positionFilters[FILTER_TYPE.COLLEGE] || []).map(positionCode => `"${positionCode}"`);
    const collegePrimaryPositionEnabled = filters
      ? filters.collegePrimaryPositionEnabled
      : (collegeFilters || []).includes(PlayerPosition.PRIMARY_POSITION);
    const collegeSecondaryPositionEnabled = filters
      ? filters.collegeSecondaryPositionEnabled
      : (collegeFilters || []).includes(PlayerPosition.SECONDARY_POSITION);

    const nflPositionCodes = filters?.nflPositionCodes?.length
      ? filters.nflPositionCodes.map((positionCode:string) => `"${positionCode}"`)
      : (positionFilters[FILTER_TYPE.NFL] || []).map(positionCode => `"${positionCode}"`);
    const nflPrimaryPositionEnabled = filters
      ? filters.nflPrimaryPositionEnabled
      : (nflFilters || []).includes(PlayerPosition.PRIMARY_POSITION);
    const nflSecondaryPositionEnabled = filters
      ? filters.nflSecondaryPositionEnabled
      : (nflFilters || []).includes(PlayerPosition.SECONDARY_POSITION);

    return `{
      playerType: [${filters ? filters.playerType.map((playerType:string) => `"${playerType}"`) : playerTypeFilters.map(playerType => `"${playerType}"`)}],
      hsGraduationYearFilter: {
        filterEnabled: ${filters ? filters.hsGraduationYearFilter.filterEnabled : (hsFilters || []).includes(HSFilter.HS_CLASS_YEAR)},
        filterStart: ${(filters && filters.hsGraduationYearFilter.filterEnabled) ? filters.hsGraduationYearFilter.filterStart : (isArray(hsClassYearRangeFilter) ? hsClassYearRangeFilter[0] : HS_CLASS_YEAR_RANGE.MIN)},
        filterEnd: ${(filters && filters.hsGraduationYearFilter.filterEnabled) ? filters.hsGraduationYearFilter.filterEnd : (isArray(hsClassYearRangeFilter) ? hsClassYearRangeFilter[1] : HS_CLASS_YEAR_RANGE.MAX)}
      },
      newPlayersEnabled: ${filters ? filters.newPlayersEnabled : (hsFilters || []).includes(HSFilter.NEW_PLAYER)},
      archiveFilter: {
        filterEnabled: ${filters ? filters.archiveFilter.filterEnabled : (hsFilters || []).includes(HSFilter.ARCHIVES)},
        filterStart: ${(filters && filters.archiveFilter.filterEnabled) ? filters.archiveFilter.filterStart : (isArray(hsArchiveFilter) ? hsArchiveFilter[0] : ARCHIVE_YEAR_RANGE.MIN)},
        filterEnd: ${(filters && filters.archiveFilter.filterEnabled) ? filters.archiveFilter.filterEnd : isArray(hsArchiveFilter) ? hsArchiveFilter[1] : ARCHIVE_YEAR_RANGE.MAX}
      },
      prepFilter: {
        filterEnabled: ${filters ? filters.prepFilter.filterEnabled : (hsFilters || []).includes(HSFilter.PREP)},
        filterStart: ${(filters && filters.prepFilter.filterEnabled) ? filters.prepFilter.filterStart : (isArray(prepFilter) ? prepFilter[0] : PREP_YEAR_RANGE.MIN)},
        filterEnd: ${(filters && filters.prepFilter.filterEnabled) ? filters.prepFilter.filterEnd : isArray(prepFilter) ? prepFilter[1] : PREP_YEAR_RANGE.MAX}
      },
      highSchoolEnabled: ${filters ? filters.highSchoolEnabled : (hsFilters || []).includes(HSFilter.HS_STATE)},
      highSchoolName: "${(filters && filters.highSchoolName) ? filters.highSchoolName : hsName}",
      highSchoolCity: "${(filters && filters.highSchoolCity) ? filters.highSchoolCity : hsCity}",
      highSchoolCounty: "${(filters && filters.highSchoolCounty) ? filters.highSchoolCounty : hsCounty}",
      highSchoolState: [${(filters && filters.highSchoolState) ? filters.highSchoolState.map((state:string) => `"${state}"`) : selectedStates.map((state:string) => `"${state}"`)}],
      internationalEnabled: ${filters ? filters.internationalEnabled : (hsFilters || []).includes(HSFilter.INTERNATIONAL)},
      playerNamesEnabled: ${filters?.playerNamesEnabled ? true : (hsFilters || []).includes(HSFilter.PLAYER_NAMES)},
      searchByNamePlayers: [${(filters?.playerNamesEnabled && !!filters?.searchByNamePlayers?.length) ? filters.searchByNamePlayers.map((player:SearchByNamePlayer) => serializeGQLObject(player)) : searchByNamePlayers.map((player:SearchByNamePlayer) => serializeGQLObject(player))}],
      hsPrimaryPositionEnabled: ${hsPrimaryPositionEnabled || (!hsSecondaryPositionEnabled && !!hsPositionCodes?.length)},
      hsSecondaryPositionEnabled: ${hsSecondaryPositionEnabled},
      hsPositionCodes: [${hsPositionCodes}],
      hsHeightFilter: {
        filterEnabled: ${filters ? filters.hsHeightFilter.filterEnabled : (hsFilters || []).includes(HSFilter.HS_HEIGHT)},
        filterStart: ${(filters && filters.hsHeightFilter.filterEnabled) ? filters.hsHeightFilter.filterStart : (isArray(hsHeightRangeFilter) ? hsHeightRangeFilter[0] : HEIGHT_RANGE.MIN)},
        filterEnd: ${(filters && filters.hsHeightFilter.filterEnabled) ? filters.hsHeightFilter.filterEnd : (isArray(hsHeightRangeFilter) ? hsHeightRangeFilter[1] : HEIGHT_RANGE.MAX)}
      },
      hsWeightFilter: {
        filterEnabled: ${filters ? filters.hsWeightFilter.filterEnabled : (hsFilters || []).includes(HSFilter.HS_WEIGHT)},
        filterStart: ${(filters && filters.hsWeightFilter.filterEnabled) ? filters.hsWeightFilter.filterStart : (isArray(hsWeightRangeFilter) ? hsWeightRangeFilter[0] : WEIGHT_RANGE.MIN)},
        filterEnd: ${(filters && filters.hsWeightFilter.filterEnabled) ? filters.hsWeightFilter.filterEnd : (isArray(hsWeightRangeFilter) ? hsWeightRangeFilter[1] : WEIGHT_RANGE.MAX)}
      },
      hsWingspanFilter: {
        filterEnabled: ${filters ? filters.hsWingspanFilter.filterEnabled : (hsFilters || []).includes(HSFilter.HS_WINGSPAN)},
        filterStart: ${(filters && filters.hsWingspanFilter.filterEnabled) ? filters.hsWingspanFilter.filterStart : (isArray(hsWingspanRangeFilter) ? hsWingspanRangeFilter[0] : WINGSPAN_RANGE.MIN)},
        filterEnd: ${(filters && filters.hsWingspanFilter.filterEnabled) ? filters.hsWingspanFilter.filterEnd : (isArray(hsWingspanRangeFilter) ? hsWingspanRangeFilter[1] : WINGSPAN_RANGE.MAX)}
      },
      hsArmFilter: {
        filterEnabled: ${filters ? filters.hsArmFilter.filterEnabled : (hsFilters || []).includes(HSFilter.HS_ARM_LENGTH)},
        filterStart: ${(filters && filters.hsArmFilter.filterEnabled) ? filters.hsArmFilter.filterStart : (isArray(hsArmLengthRangeFilter) ? hsArmLengthRangeFilter[0] : ARM_LENGTH_RANGE.MIN)},
        filterEnd: ${(filters && filters.hsArmFilter.filterEnabled) ? filters.hsArmFilter.filterEnd : (isArray(hsArmLengthRangeFilter) ? hsArmLengthRangeFilter[1] : ARM_LENGTH_RANGE.MAX)}
      },
      hsHandFilter: {
        filterEnabled: ${filters ? filters.hsHandFilter.filterEnabled : (hsFilters || []).includes(HSFilter.HS_HANDSIZE)},
        filterStart: ${(filters && filters.hsHandFilter.filterEnabled) ? filters.hsHandFilter.filterStart : (isArray(hsHandSizeRangeFilter) ? hsHandSizeRangeFilter[0] : HAND_SIZE_RANGE.MIN)},
        filterEnd: ${(filters && filters.hsHandFilter.filterEnabled) ? filters.hsHandFilter.filterEnd : (isArray(hsHandSizeRangeFilter) ? hsHandSizeRangeFilter[1] : HAND_SIZE_RANGE.MAX)}
      },
      multiSportsEnabled: ${filters ? filters.multiSportsEnabled : (hsFilters || []).includes(HSFilter.OTHER_SPORTS)},
      multiSports: [${(filters && filters.multiSportsEnabled) ? filters.multiSports.map((sport:string) => `"${sport}"`) : otherSportFilters.map(sport => `"${sport}"`)}],
      speedEnabled: ${filters ? filters.speedEnabled : (hsFilters || []).includes(HSFilter.SPEED)},
      powerEnabled: ${filters ? filters.powerEnabled : (hsFilters || []).includes(HSFilter.POWER)},
      paiFilter: {
        filterEnabled: ${filters ? filters.paiFilter.filterEnabled : (hsFilters || []).includes(HSFilter.PAI)},
        filterStart: ${(filters && filters.paiFilter.filterEnabled) ? filters.paiFilter.filterStart : (isArray(paiRangeFilter) ? paiRangeFilter[0] : 0)},
        filterEnd: ${(filters && filters.paiFilter.filterEnabled) ? filters.paiFilter.filterEnd : (isArray(paiRangeFilter) ? paiRangeFilter[1] : 5)}
      },
      combineFilter: {
        filterEnabled: ${filters ? filters.combineFilter.filterEnabled : (hsFilters || []).includes(HSFilter.COMBINE)},
        filterStart: ${(filters && filters.combineFilter.filterEnabled ) ? filters.combineFilter.filterStart : (isArray(combineScoreRangeFilter) ? combineScoreRangeFilter[0] : 0)},
        filterEnd: ${(filters && filters.combineFilter.filterEnabled ) ? filters.combineFilter.filterEnd : (isArray(combineScoreRangeFilter) ? combineScoreRangeFilter[1] : 5)}
      },
      proCombineFilter: {
        filterEnabled: ${filters ? filters.proCombineFilter.filterEnabled : (nflFilters || []).includes(NFLFilter.PRO_COMBINE_SCORE)},
        filterStart: ${(filters && filters.proCombineFilter.filterEnabled ) ? filters.proCombineFilter.filterStart : (isArray(proCombineScoreRangeFilter) ? proCombineScoreRangeFilter[0] : 0)},
        filterEnd: ${(filters && filters.proCombineFilter.filterEnabled ) ? filters.proCombineFilter.filterEnd : (isArray(proCombineScoreRangeFilter) ? proCombineScoreRangeFilter[1] : 5)}
      },
      ppiFilter: {
        filterEnabled: ${filters ? filters.ppiFilter.filterEnabled : (nflFilters || []).includes(NFLFilter.PPI)},
        filterStart: ${(filters && filters.ppiFilter.filterEnabled ) ? filters.ppiFilter.filterStart : (isArray(ppiRangeFilter) ? ppiRangeFilter[0] : 0)},
        filterEnd: ${(filters && filters.ppiFilter.filterEnabled ) ? filters.ppiFilter.filterEnd : (isArray(ppiRangeFilter) ? ppiRangeFilter[1] : 5)}
      },
      rating247Filter: {
        filterEnabled: ${filters ? filters.rating247Filter.filterEnabled : (hsFilters || []).includes(HSFilter.RATING_247)},
        filterStart: ${(filters && filters.rating247Filter.filterEnabled ) ? filters.rating247Filter.filterStart : (isArray(rating247RangeFilter) ? rating247RangeFilter[0] : 0)},
        filterEnd: ${(filters && filters.rating247Filter.filterEnabled ) ? filters.rating247Filter.filterEnd : (isArray(rating247RangeFilter) ? rating247RangeFilter[1] : 5)}
      },
      gpaEnabled: ${filters ? filters.gpaEnabled : (hsFilters || []).includes(HSFilter.GPA)},
      gpaMinimum: ${(filters && filters.gpaEnabled) ? filters.gpaMinimum : (!!gpaMinimum ? Number(gpaMinimum) : 0)},
      satEnabled: ${filters ? filters.satEnabled : (hsFilters || []).includes(HSFilter.SAT)},
      satMinimum: ${(filters && filters.gpaEnabled) ? filters.satMinimum : (!!satMinimum ? Number(satMinimum) : 0)},
      actEnabled: ${filters ? filters.actEnabled : (hsFilters || []).includes(HSFilter.ACT)},
      actMinimum: ${(filters && filters.gpaEnabled) ? filters.actMinimum : (!!actMinimum ? Number(actMinimum) : 0)},
      offerCountFilter: {
        filterEnabled: ${filters ? filters.offerCountFilter.filterEnabled : (hsFilters || []).includes(HSFilter.OFFER_COUNT)},
        filterStart: ${(filters && filters.offerCountFilter.filterEnabled ) ? filters.offerCountFilter.filterStart : (isArray(offerCountRangeFilter) ? offerCountRangeFilter[0] : 0)},
        filterEnd: ${(filters && filters.offerCountFilter.filterEnabled ) ? filters.offerCountFilter.filterEnd : (isArray(offerCountRangeFilter) ? offerCountRangeFilter[1] : 70)}
      },
      offerSchoolEnabled: ${filters ? filters.offerSchoolEnabled : (hsFilters || []).includes(HSFilter.OFFER_SCHOOL)},
      offerSchoolName: "${(filters && filters.offerSchoolEnabled) ? filters.offerSchoolName : offerSchoolName}",
      offerFBSEnabled: ${filters ? filters.offerFBSEnabled : (hsFilters || []).includes(HSFilter.FBS)},
      offerFCSEnabled: ${filters ? filters.offerFCSEnabled : (hsFilters || []).includes(HSFilter.FCS)},
      offerFBSConferenceIds: ${!!filters?.offerFBSConferenceIds?.length ? filters.offerFBSConferenceIds : `[${offerFBSConferenceFilters.toString()}]`},
      offerFCSConferenceIds: ${!!filters?.offerFCSConferenceIds?.length ? filters.offerFCSConferenceIds : `[${offerFCSConferenceFilters.toString()}]`},
      highestLevelOfferP5Enabled: ${filters ? filters.highestLevelOfferP5Enabled : (hsFilters || []).includes(HSFilter.HIGHEST_LEVEL_OFFER_P5)},
      highestLevelOfferG5Enabled: ${filters ? filters.highestLevelOfferG5Enabled : (hsFilters || []).includes(HSFilter.HIGHEST_LEVEL_OFFER_G5)},
      uncommittedEnabled: ${filters ? filters.uncommittedEnabled : uncommittedEnabled },
      committedSchoolEnabled: ${filters ? filters.committedSchoolEnabled : (hsFilters || []).includes(HSFilter.COMMITTED_SCHOOL)},
      committedSchoolName: "${(filters && filters.committedSchoolEnabled) ? filters.committedSchoolName : committedSchoolName}",
      trackFieldYearFilter: {
        filterEnabled: ${filters ? filters.trackFieldYearFilter.filterEnabled : (hsFilters || []).includes(HSFilter.TRACK_AND_FIELD_DATA_YEAR)},
        filterStart: ${(filters && filters.trackFieldYearFilter.filterEnabled) ? filters.trackFieldYearFilter.filterStart : (isArray(hsTrackAndFieldYearRangeFilter) ? hsTrackAndFieldYearRangeFilter[0] : TRACK_AND_FIELD_YEAR_RANGE.MIN)},
        filterEnd: ${(filters && filters.trackFieldYearFilter.filterEnabled) ? filters.trackFieldYearFilter.filterEnd : (isArray(hsTrackAndFieldYearRangeFilter) ? hsTrackAndFieldYearRangeFilter[1] : TRACK_AND_FIELD_YEAR_RANGE.MAX)}
      },
      trackField55mFilter: {
        filterEnabled: ${filters ? filters.trackField55mFilter.filterEnabled : (hsFilters || []).includes(HSFilter.S_55_METERS)},
        filterStart: ${(filters && filters.trackField55mFilter.filterEnabled) ? filters.trackField55mFilter.filterStart : (isArray(trackAndFieldEventFilters[HSFilter.S_55_METERS]) ? trackAndFieldEventFilters[HSFilter.S_55_METERS][0] : 6)},
        filterEnd: ${(filters && filters.trackField55mFilter.filterEnabled) ? filters.trackField55mFilter.filterEnd : (isArray(trackAndFieldEventFilters[HSFilter.S_55_METERS]) ? trackAndFieldEventFilters[HSFilter.S_55_METERS][1] : 10)}
      },
      trackField60mFilter: {
        filterEnabled: ${filters ? filters.trackField60mFilter.filterEnabled : (hsFilters || []).includes(HSFilter.S_60_METERS)},
        filterStart: ${(filters && filters.trackField60mFilter.filterEnabled) ? filters.trackField60mFilter.filterStart : (isArray(trackAndFieldEventFilters[HSFilter.S_60_METERS]) ? trackAndFieldEventFilters[HSFilter.S_60_METERS][0] : 6)},
        filterEnd: ${(filters && filters.trackField60mFilter.filterEnabled) ? filters.trackField60mFilter.filterEnd : (isArray(trackAndFieldEventFilters[HSFilter.S_60_METERS]) ? trackAndFieldEventFilters[HSFilter.S_60_METERS][1] : 10)}
      },
      trackField100mFilter: {
        filterEnabled: ${filters ? filters.trackField100mFilter.filterEnabled : (hsFilters || []).includes(HSFilter.S_100_METERS)},
        filterStart: ${(filters && filters.trackField100mFilter.filterEnabled) ? filters.trackField100mFilter.filterStart : (isArray(trackAndFieldEventFilters[HSFilter.S_100_METERS]) ? trackAndFieldEventFilters[HSFilter.S_100_METERS][0] : 9)},
        filterEnd: ${(filters && filters.trackField100mFilter.filterEnabled) ? filters.trackField100mFilter.filterEnd : (isArray(trackAndFieldEventFilters[HSFilter.S_100_METERS]) ? trackAndFieldEventFilters[HSFilter.S_100_METERS][1] : 18)}
      },
      trackField200mFilter: {
        filterEnabled: ${filters ? filters.trackField200mFilter.filterEnabled : (hsFilters || []).includes(HSFilter.S_200_METERS)},
        filterStart: ${(filters && filters.trackField200mFilter.filterEnabled) ? filters.trackField200mFilter.filterStart : (isArray(trackAndFieldEventFilters[HSFilter.S_200_METERS]) ? trackAndFieldEventFilters[HSFilter.S_200_METERS][0] : 18)},
        filterEnd: ${(filters && filters.trackField200mFilter.filterEnabled) ? filters.trackField200mFilter.filterEnd : (isArray(trackAndFieldEventFilters[HSFilter.S_200_METERS]) ? trackAndFieldEventFilters[HSFilter.S_200_METERS][1] : 32)}
      },
      trackField300mFilter: {
        filterEnabled: ${filters ? filters.trackField300mFilter.filterEnabled : (hsFilters || []).includes(HSFilter.S_300_METERS)},
        filterStart: ${(filters && filters.trackField300mFilter.filterEnabled) ? filters.trackField300mFilter.filterStart : (isArray(trackAndFieldEventFilters[HSFilter.S_300_METERS]) ? trackAndFieldEventFilters[HSFilter.S_300_METERS][0] : 33)},
        filterEnd: ${(filters && filters.trackField300mFilter.filterEnabled) ? filters.trackField300mFilter.filterEnd : (isArray(trackAndFieldEventFilters[HSFilter.S_300_METERS]) ? trackAndFieldEventFilters[HSFilter.S_300_METERS][1] : 50)}
      },
      trackField400mFilter: {
        filterEnabled: ${filters ? filters.trackField400mFilter.filterEnabled : (hsFilters || []).includes(HSFilter.S_400_METERS)},
        filterStart: ${(filters && filters.trackField400mFilter.filterEnabled) ? filters.trackField400mFilter.filterStart : (isArray(trackAndFieldEventFilters[HSFilter.S_400_METERS]) ? trackAndFieldEventFilters[HSFilter.S_400_METERS][0] : 40)},
        filterEnd: ${(filters && filters.trackField400mFilter.filterEnabled) ? filters.trackField400mFilter.filterEnd : (isArray(trackAndFieldEventFilters[HSFilter.S_400_METERS]) ? trackAndFieldEventFilters[HSFilter.S_400_METERS][1] : 75)}
      },
      trackField800mFilter: {
        filterEnabled: ${filters ? filters.trackField800mFilter.filterEnabled : (hsFilters || []).includes(HSFilter.MD_800_METERS)},
        filterStart: ${(filters && filters.trackField800mFilter.filterEnabled) ? filters.trackField800mFilter.filterStart : (isArray(trackAndFieldEventFilters[HSFilter.MD_800_METERS]) ? trackAndFieldEventFilters[HSFilter.MD_800_METERS][0] : 90)},
        filterEnd: ${(filters && filters.trackField800mFilter.filterEnabled) ? filters.trackField800mFilter.filterEnd : (isArray(trackAndFieldEventFilters[HSFilter.MD_800_METERS]) ? trackAndFieldEventFilters[HSFilter.MD_800_METERS][1] : 180)}
      },
      trackField55hhFilter: {
        filterEnabled: ${filters ? filters.trackField55hhFilter.filterEnabled : (hsFilters || []).includes(HSFilter.HH_55_METERS)},
        filterStart: ${(filters && filters.trackField55hhFilter.filterEnabled) ? filters.trackField55hhFilter.filterStart : (isArray(trackAndFieldEventFilters[HSFilter.HH_55_METERS]) ? trackAndFieldEventFilters[HSFilter.HH_55_METERS][0] : 7)},
        filterEnd: ${(filters && filters.trackField55hhFilter.filterEnabled) ? filters.trackField55hhFilter.filterEnd : (isArray(trackAndFieldEventFilters[HSFilter.HH_55_METERS]) ? trackAndFieldEventFilters[HSFilter.HH_55_METERS][1] : 12)}
      },
      trackField60hhFilter: {
        filterEnabled: ${filters ? filters.trackField60hhFilter.filterEnabled : (hsFilters || []).includes(HSFilter.HH_60_METERS)},
        filterStart: ${(filters && filters.trackField60hhFilter.filterEnabled) ? filters.trackField60hhFilter.filterStart : (isArray(trackAndFieldEventFilters[HSFilter.HH_60_METERS]) ? trackAndFieldEventFilters[HSFilter.HH_60_METERS][0] : 7)},
        filterEnd: ${(filters && filters.trackField60hhFilter.filterEnabled) ? filters.trackField60hhFilter.filterEnd : (isArray(trackAndFieldEventFilters[HSFilter.HH_60_METERS]) ? trackAndFieldEventFilters[HSFilter.HH_60_METERS][1] : 12)}
      },
      trackField110hhFilter: {
        filterEnabled: ${filters ? filters.trackField110hhFilter.filterEnabled : (hsFilters || []).includes(HSFilter.HH_110_METERS)},
        filterStart: ${(filters && filters.trackField110hhFilter.filterEnabled) ? filters.trackField110hhFilter.filterStart : (isArray(trackAndFieldEventFilters[HSFilter.HH_110_METERS]) ? trackAndFieldEventFilters[HSFilter.HH_110_METERS][0] : 12)},
        filterEnd: ${(filters && filters.trackField110hhFilter.filterEnabled) ? filters.trackField110hhFilter.filterEnd : (isArray(trackAndFieldEventFilters[HSFilter.HH_110_METERS]) ? trackAndFieldEventFilters[HSFilter.HH_110_METERS][1] : 25)}
      },
      trackField300ihFilter: {
        filterEnabled: ${filters ? filters.trackField300ihFilter.filterEnabled : (hsFilters || []).includes(HSFilter.H_300_INT)},
        filterStart: ${(filters && filters.trackField300ihFilter.filterEnabled) ? filters.trackField300ihFilter.filterStart : (isArray(trackAndFieldEventFilters[HSFilter.H_300_INT]) ? trackAndFieldEventFilters[HSFilter.H_300_INT][0] : 34)},
        filterEnd: ${(filters && filters.trackField300ihFilter.filterEnabled) ? filters.trackField300ihFilter.filterEnd : (isArray(trackAndFieldEventFilters[HSFilter.H_300_INT]) ? trackAndFieldEventFilters[HSFilter.H_300_INT][1] : 58)}
      },
      trackField400hFilter: {
        filterEnabled: ${filters ? filters.trackField400hFilter.filterEnabled : (hsFilters || []).includes(HSFilter.H_400_METERS)},
        filterStart: ${(filters && filters.trackField400hFilter.filterEnabled) ? filters.trackField400hFilter.filterStart : (isArray(trackAndFieldEventFilters[HSFilter.H_400_METERS]) ? trackAndFieldEventFilters[HSFilter.H_400_METERS][0] : 45)},
        filterEnd: ${(filters && filters.trackField400hFilter.filterEnabled) ? filters.trackField400hFilter.filterEnd : (isArray(trackAndFieldEventFilters[HSFilter.H_400_METERS]) ? trackAndFieldEventFilters[HSFilter.H_400_METERS][1] : 80)}
      },
      trackField400rFilter: {
        filterEnabled: ${filters ? filters.trackField400rFilter.filterEnabled : (hsFilters || []).includes(HSFilter.R_400_METERS)},
        filterStart: ${(filters && filters.trackField400rFilter.filterEnabled) ? filters.trackField400rFilter.filterStart : (isArray(trackAndFieldEventFilters[HSFilter.R_400_METERS]) ? trackAndFieldEventFilters[HSFilter.R_400_METERS][0] : 38)},
        filterEnd: ${(filters && filters.trackField400rFilter.filterEnabled) ? filters.trackField400rFilter.filterEnd : (isArray(trackAndFieldEventFilters[HSFilter.R_400_METERS]) ? trackAndFieldEventFilters[HSFilter.R_400_METERS][1] : 55)}
      },
      trackField800rFilter: {
        filterEnabled: ${filters ? filters.trackField800rFilter.filterEnabled : (hsFilters || []).includes(HSFilter.R_800_METERS)},
        filterStart: ${(filters && filters.trackField800rFilter.filterEnabled) ? filters.trackField800rFilter.filterStart : (isArray(trackAndFieldEventFilters[HSFilter.R_800_METERS]) ? trackAndFieldEventFilters[HSFilter.R_800_METERS][0] : 80)},
        filterEnd: ${(filters && filters.trackField800rFilter.filterEnabled) ? filters.trackField800rFilter.filterEnd : (isArray(trackAndFieldEventFilters[HSFilter.R_800_METERS]) ? trackAndFieldEventFilters[HSFilter.R_800_METERS][1] : 115)}
      },
      trackField1600rFilter: {
        filterEnabled: ${filters ? filters.trackField1600rFilter.filterEnabled : (hsFilters || []).includes(HSFilter.R_1600_METERS)},
        filterStart: ${(filters && filters.trackField1600rFilter.filterEnabled) ? filters.trackField1600rFilter.filterStart : (isArray(trackAndFieldEventFilters[HSFilter.R_1600_METERS]) ? trackAndFieldEventFilters[HSFilter.R_1600_METERS][0] : 180)},
        filterEnd: ${(filters && filters.trackField1600rFilter.filterEnabled) ? filters.trackField1600rFilter.filterEnd : (isArray(trackAndFieldEventFilters[HSFilter.R_1600_METERS]) ? trackAndFieldEventFilters[HSFilter.R_1600_METERS][1] : 270)}
      },
      trackField3200rFilter: {
        filterEnabled: ${filters ? filters.trackField3200rFilter.filterEnabled : (hsFilters || []).includes(HSFilter.R_3200_METERS)},
        filterStart: ${(filters && filters.trackField3200rFilter.filterEnabled) ? filters.trackField3200rFilter.filterStart : (isArray(trackAndFieldEventFilters[HSFilter.R_3200_METERS]) ? trackAndFieldEventFilters[HSFilter.R_3200_METERS][0] : 450)},
        filterEnd: ${(filters && filters.trackField3200rFilter.filterEnabled) ? filters.trackField3200rFilter.filterEnd : (isArray(trackAndFieldEventFilters[HSFilter.R_3200_METERS]) ? trackAndFieldEventFilters[HSFilter.R_3200_METERS][1] : 750)}
      },
      trackFieldShotputFilter: {
        filterEnabled: ${filters ? filters.trackFieldShotputFilter.filterEnabled : (hsFilters || []).includes(HSFilter.SHOTPUT)},
        filterStart: ${(filters && filters.trackFieldShotputFilter.filterEnabled) ? filters.trackFieldShotputFilter.filterStart : (isArray(trackAndFieldEventFilters[HSFilter.SHOTPUT]) ? trackAndFieldEventFilters[HSFilter.SHOTPUT][0] : 300)},
        filterEnd: ${(filters && filters.trackFieldShotputFilter.filterEnabled) ? filters.trackFieldShotputFilter.filterEnd : (isArray(trackAndFieldEventFilters[HSFilter.SHOTPUT]) ? trackAndFieldEventFilters[HSFilter.SHOTPUT][1] : 1020)}
      },
      trackFieldJavelinFilter: {
        filterEnabled: ${filters ? filters.trackFieldJavelinFilter.filterEnabled : (hsFilters || []).includes(HSFilter.JAVELIN)},
        filterStart: ${(filters && filters.trackFieldJavelinFilter.filterEnabled) ? filters.trackFieldJavelinFilter.filterStart : (isArray(trackAndFieldEventFilters[HSFilter.JAVELIN]) ? trackAndFieldEventFilters[HSFilter.JAVELIN][0] : 420)},
        filterEnd: ${(filters && filters.trackFieldJavelinFilter.filterEnabled) ? filters.trackFieldJavelinFilter.filterEnd : (isArray(trackAndFieldEventFilters[HSFilter.JAVELIN]) ? trackAndFieldEventFilters[HSFilter.JAVELIN][1] : 3120)}
      },
      trackFieldDiscusFilter: {
        filterEnabled: ${filters ? filters.trackFieldDiscusFilter.filterEnabled : (hsFilters || []).includes(HSFilter.DISCUS)},
        filterStart: ${(filters && filters.trackFieldDiscusFilter.filterEnabled) ? filters.trackFieldDiscusFilter.filterStart : (isArray(trackAndFieldEventFilters[HSFilter.DISCUS]) ? trackAndFieldEventFilters[HSFilter.DISCUS][0] : 720)},
        filterEnd: ${(filters && filters.trackFieldDiscusFilter.filterEnabled) ? filters.trackFieldDiscusFilter.filterEnd : (isArray(trackAndFieldEventFilters[HSFilter.DISCUS]) ? trackAndFieldEventFilters[HSFilter.DISCUS][1] : 3000)}
      },
      trackFieldHighJumpFilter: {
        filterEnabled: ${filters ? filters.trackFieldHighJumpFilter.filterEnabled : (hsFilters || []).includes(HSFilter.HIGH_JUMP)},
        filterStart: ${(filters && filters.trackFieldHighJumpFilter.filterEnabled) ? filters.trackFieldHighJumpFilter.filterStart : (isArray(trackAndFieldEventFilters[HSFilter.HIGH_JUMP]) ? trackAndFieldEventFilters[HSFilter.HIGH_JUMP][0] : 54)},
        filterEnd: ${(filters && filters.trackFieldHighJumpFilter.filterEnabled) ? filters.trackFieldHighJumpFilter.filterEnd : (isArray(trackAndFieldEventFilters[HSFilter.HIGH_JUMP]) ? trackAndFieldEventFilters[HSFilter.HIGH_JUMP][1] : 96)}
      },
      trackFieldLongJumpFilter: {
        filterEnabled: ${filters ? filters.trackFieldLongJumpFilter.filterEnabled : (hsFilters || []).includes(HSFilter.LONG_JUMP)},
        filterStart: ${(filters && filters.trackFieldLongJumpFilter.filterEnabled) ? filters.trackFieldLongJumpFilter.filterStart : (isArray(trackAndFieldEventFilters[HSFilter.LONG_JUMP]) ? trackAndFieldEventFilters[HSFilter.LONG_JUMP][0] : 168)},
        filterEnd: ${(filters && filters.trackFieldLongJumpFilter.filterEnabled) ? filters.trackFieldLongJumpFilter.filterEnd : (isArray(trackAndFieldEventFilters[HSFilter.LONG_JUMP]) ? trackAndFieldEventFilters[HSFilter.LONG_JUMP][1] : 360)}
      },
      trackFieldTripleJumpFilter: {
        filterEnabled: ${filters ? filters.trackFieldTripleJumpFilter.filterEnabled : (hsFilters || []).includes(HSFilter.TRIPLE_JUMP)},
        filterStart: ${(filters && filters.trackFieldTripleJumpFilter.filterEnabled) ? filters.trackFieldTripleJumpFilter.filterStart : (isArray(trackAndFieldEventFilters[HSFilter.TRIPLE_JUMP]) ? trackAndFieldEventFilters[HSFilter.TRIPLE_JUMP][0] : 300)},
        filterEnd: ${(filters && filters.trackFieldTripleJumpFilter.filterEnabled) ? filters.trackFieldTripleJumpFilter.filterEnd : (isArray(trackAndFieldEventFilters[HSFilter.TRIPLE_JUMP]) ? trackAndFieldEventFilters[HSFilter.TRIPLE_JUMP][1] : 720)}
      },
      trackFieldPoleVaultFilter: {
        filterEnabled: ${filters ? filters.trackFieldPoleVaultFilter.filterEnabled : (hsFilters || []).includes(HSFilter.POLE_VAULT_JUMP)},
        filterStart: ${(filters && filters.trackFieldPoleVaultFilter.filterEnabled) ? filters.trackFieldPoleVaultFilter.filterStart : (isArray(trackAndFieldEventFilters[HSFilter.POLE_VAULT_JUMP]) ? trackAndFieldEventFilters[HSFilter.POLE_VAULT_JUMP][0] : 96)},
        filterEnd: ${(filters && filters.trackFieldPoleVaultFilter.filterEnabled) ? filters.trackFieldPoleVaultFilter.filterEnd : (isArray(trackAndFieldEventFilters[HSFilter.POLE_VAULT_JUMP]) ? trackAndFieldEventFilters[HSFilter.POLE_VAULT_JUMP][1] : 216)}
      },
      combineYearFilter: {
        filterEnabled: ${filters ? filters.combineYearFilter.filterEnabled : (hsFilters || []).includes(HSFilter.COMBINE_YEAR)},
        filterStart: ${(filters && filters.combineYearFilter.filterEnabled) ? filters.combineYearFilter.filterStart : (isArray(combineYearRangeFilter) ? combineYearRangeFilter[0] : COMBINE_YEAR_RANGE.MIN)},
        filterEnd: ${(filters && filters.combineYearFilter.filterEnabled) ? filters.combineYearFilter.filterEnd : (isArray(combineYearRangeFilter) ? combineYearRangeFilter[1] : COMBINE_YEAR_RANGE.MAX)}
      },
      combineEventEnabled: ${filters ? filters.combineEventEnabled : (hsFilters || []).includes(HSFilter.COMBINE_EVENT)},
      combineEvents: [${(filters && filters.combineEventEnabled) ? filters.combineEvents.map((combineEvent:string) => `"${combineEvent}"`) : combineEventFilters.map(combineEvent => `"${combineEvent}"`)}],
      combineCityEnabled: ${filters ? filters.combineCityEnabled : (hsFilters || []).includes(HSFilter.COMBINE_CITY)},
      combineCity: "${(filters && filters.combineCityEnabled) ? filters.combineCity : combineCityName}",
      combine40mDashFilter: {
        filterEnabled: ${filters ? filters.combine40mDashFilter.filterEnabled : (hsFilters || []).includes(HSFilter.COMBINE_40_M_DASH)},
        filterStart: ${(filters && filters.combine40mDashFilter.filterEnabled) ? filters.combine40mDashFilter.filterStart : (isArray(combineRangeFilters[HSFilter.COMBINE_40_M_DASH]) ? combineRangeFilters[HSFilter.COMBINE_40_M_DASH][0] : 4)},
        filterEnd: ${(filters && filters.combine40mDashFilter.filterEnabled) ? filters.combine40mDashFilter.filterEnd : (isArray(combineRangeFilters[HSFilter.COMBINE_40_M_DASH]) ? combineRangeFilters[HSFilter.COMBINE_40_M_DASH][1] : 7)}
      },
      combine10SplitFilter: {
        filterEnabled: ${filters ? filters.combine10SplitFilter.filterEnabled : (hsFilters || []).includes(HSFilter.COMBINE_10_SPLIT)},
        filterStart: ${(filters && filters.combine10SplitFilter.filterEnabled) ? filters.combine10SplitFilter.filterStart : (isArray(combineRangeFilters[HSFilter.COMBINE_10_SPLIT]) ? combineRangeFilters[HSFilter.COMBINE_10_SPLIT][0] : 1.00)},
        filterEnd: ${(filters && filters.combine10SplitFilter.filterEnabled) ? filters.combine10SplitFilter.filterEnd : (isArray(combineRangeFilters[HSFilter.COMBINE_10_SPLIT]) ? combineRangeFilters[HSFilter.COMBINE_10_SPLIT][1] : 3.00)}
      },
      combineMPHFilter: {
        filterEnabled: ${filters ? filters.combineMPHFilter.filterEnabled : (hsFilters || []).includes(HSFilter.COMBINE_MPH)},
        filterStart: ${(filters && filters.combineMPHFilter.filterEnabled) ? filters.combineMPHFilter.filterStart : (isArray(combineRangeFilters[HSFilter.COMBINE_MPH]) ? combineRangeFilters[HSFilter.COMBINE_MPH][0] : 1)},
        filterEnd: ${(filters && filters.combineMPHFilter.filterEnabled) ? filters.combineMPHFilter.filterEnd : (isArray(combineRangeFilters[HSFilter.COMBINE_MPH]) ? combineRangeFilters[HSFilter.COMBINE_MPH][1] : 30)}
      },
      combineShuttleFilter: {
        filterEnabled: ${filters ? filters.combineShuttleFilter.filterEnabled : (hsFilters || []).includes(HSFilter.COMBINE_SHUTTLE)},
        filterStart: ${(filters && filters.combineShuttleFilter.filterEnabled) ? filters.combineShuttleFilter.filterStart : (isArray(combineRangeFilters[HSFilter.COMBINE_SHUTTLE]) ? combineRangeFilters[HSFilter.COMBINE_SHUTTLE][0] : 3)},
        filterEnd: ${(filters && filters.combineShuttleFilter.filterEnabled) ? filters.combineShuttleFilter.filterEnd : (isArray(combineRangeFilters[HSFilter.COMBINE_SHUTTLE]) ? combineRangeFilters[HSFilter.COMBINE_SHUTTLE][1] : 9)}
      },
      combine3ConeFilter: {
        filterEnabled: ${filters ? filters.combine3ConeFilter.filterEnabled : (hsFilters || []).includes(HSFilter.COMBINE_3_CONE)},
        filterStart: ${(filters && filters.combine3ConeFilter.filterEnabled) ? filters.combine3ConeFilter.filterStart : (isArray(combineRangeFilters[HSFilter.COMBINE_3_CONE]) ? combineRangeFilters[HSFilter.COMBINE_3_CONE][0] : 3)},
        filterEnd: ${(filters && filters.combine3ConeFilter.filterEnabled) ? filters.combine3ConeFilter.filterEnd : (isArray(combineRangeFilters[HSFilter.COMBINE_3_CONE]) ? combineRangeFilters[HSFilter.COMBINE_3_CONE][1] : 9)}
      },
      combineVerticalJumpFilter: {
        filterEnabled: ${filters ? filters.combineVerticalJumpFilter.filterEnabled : (hsFilters || []).includes(HSFilter.COMBINE_VERTICAL_JUMP)},
        filterStart: ${(filters && filters.combineVerticalJumpFilter.filterEnabled) ? filters.combineVerticalJumpFilter.filterStart : (isArray(combineRangeFilters[HSFilter.COMBINE_VERTICAL_JUMP]) ? combineRangeFilters[HSFilter.COMBINE_VERTICAL_JUMP][0] : 15)},
        filterEnd: ${(filters && filters.combineVerticalJumpFilter.filterEnabled) ? filters.combineVerticalJumpFilter.filterEnd : (isArray(combineRangeFilters[HSFilter.COMBINE_VERTICAL_JUMP]) ? combineRangeFilters[HSFilter.COMBINE_VERTICAL_JUMP][1] : 50)}
      },
      combineBroadJumpFilter: {
        filterEnabled: ${filters ? filters.combineBroadJumpFilter.filterEnabled : (hsFilters || []).includes(HSFilter.COMBINE_BROAD_JUMP)},
        filterStart: ${(filters && filters.combineBroadJumpFilter.filterEnabled) ? filters.combineBroadJumpFilter.filterStart : (isArray(combineRangeFilters[HSFilter.COMBINE_BROAD_JUMP]) ? combineRangeFilters[HSFilter.COMBINE_BROAD_JUMP][0] : 15)},
        filterEnd: ${(filters && filters.combineBroadJumpFilter.filterEnabled) ? filters.combineBroadJumpFilter.filterEnd : (isArray(combineRangeFilters[HSFilter.COMBINE_BROAD_JUMP]) ? combineRangeFilters[HSFilter.COMBINE_BROAD_JUMP][1] : 50)}
      },
      combinePowerTossFilter: {
        filterEnabled: ${filters ? filters.combinePowerTossFilter.filterEnabled : (hsFilters || []).includes(HSFilter.COMBINE_POWERTOSS)},
        filterStart: ${(filters && filters.combinePowerTossFilter.filterEnabled) ? filters.combinePowerTossFilter.filterStart : (isArray(combineRangeFilters[HSFilter.COMBINE_POWERTOSS]) ? combineRangeFilters[HSFilter.COMBINE_POWERTOSS][0] : 15)},
        filterEnd: ${(filters && filters.combinePowerTossFilter.filterEnabled) ? filters.combinePowerTossFilter.filterEnd : (isArray(combineRangeFilters[HSFilter.COMBINE_POWERTOSS]) ? combineRangeFilters[HSFilter.COMBINE_POWERTOSS][1] : 65)}
      },
      combineRatingFilter: {
        filterEnabled: ${filters ? filters.combineRatingFilter.filterEnabled : (hsFilters || []).includes(HSFilter.SPARQ)},
        filterStart: ${(filters && filters.combineRatingFilter.filterEnabled) ? filters.combineRatingFilter.filterStart : (isArray(combineRangeFilters[HSFilter.SPARQ]) ? combineRangeFilters[HSFilter.SPARQ][0] : 0)},
        filterEnd: ${(filters && filters.combineRatingFilter.filterEnabled) ? filters.combineRatingFilter.filterEnd : (isArray(combineRangeFilters[HSFilter.SPARQ]) ? combineRangeFilters[HSFilter.SPARQ][1] : 200)}
      },
      collegePrimaryPositionEnabled: ${collegePrimaryPositionEnabled || (!collegeSecondaryPositionEnabled && !!collegePositionCodes?.length)},
      collegeSecondaryPositionEnabled: ${collegeSecondaryPositionEnabled},
      collegePositionCodes: [${collegePositionCodes}],
      collegeHeightFilter: {
        filterEnabled: ${filters ? filters.collegeHeightFilter.filterEnabled : (collegeFilters || []).includes(CollegeFilter.HEIGHT)},
        filterStart: ${(filters && filters.collegeHeightFilter.filterEnabled) ? filters.collegeHeightFilter.filterStart : (isArray(collegeHeightFilter) ? collegeHeightFilter[0] : HEIGHT_RANGE.MIN)},
        filterEnd: ${(filters && filters.collegeHeightFilter.filterEnabled) ? filters.collegeHeightFilter.filterEnd : (isArray(collegeHeightFilter) ? collegeHeightFilter[1] : HEIGHT_RANGE.MAX)}
      },
      collegeWeightFilter: {
        filterEnabled: ${filters ? filters.collegeWeightFilter.filterEnabled : (collegeFilters || []).includes(CollegeFilter.WEIGHT)},
        filterStart: ${(filters && filters.collegeWeightFilter.filterEnabled) ? filters.collegeWeightFilter.filterStart : (isArray(collegeWeightFilter) ? collegeWeightFilter[0] : WEIGHT_RANGE.MIN)},
        filterEnd: ${(filters && filters.collegeWeightFilter.filterEnabled) ? filters.collegeWeightFilter.filterEnd : (isArray(collegeWeightFilter) ? collegeWeightFilter[1] : WEIGHT_RANGE.MAX)}
      },
      collegeRecruitingClassFilter: {
        filterEnabled: ${filters ? filters.collegeRecruitingClassFilter.filterEnabled : (collegeFilters || []).includes(CollegeFilter.RECRUITING_CLASS)},
        filterStart: ${(filters && filters.collegeRecruitingClassFilter.filterEnabled) ? filters.collegeRecruitingClassFilter.filterStart : (isArray(collegeClassYearRangeFilter) ? collegeClassYearRangeFilter[0] : COLLEGE_YEAR_RANGE.MIN)},
        filterEnd: ${(filters && filters.collegeRecruitingClassFilter.filterEnabled) ? filters.collegeRecruitingClassFilter.filterEnd : (isArray(collegeClassYearRangeFilter) ? collegeClassYearRangeFilter[1] : COLLEGE_YEAR_RANGE.MAX)}
      },
      collegeNameEnabled: ${filters?.collegeNameEnabled ? true : (collegeFilters || []).includes(CollegeFilter.COLLEGE_NAME)},
      collegeName: "${filters?.collegeNameEnabled ? filters.collegeName : collegeName}",
      levelEnabled: ${filters?.levelEnabled ? true : (collegeFilters || []).includes(CollegeFilter.COLLEGE_LEVEL)},
      levels: [${(filters?.levelEnabled && filters?.levels) ? filters.levels.map((collegeLevel:string) => `"${collegeLevel}"`) : collegeLevelFilters.map(collegeLevel => `"${collegeLevel}"`)}],
      fbsConferenceEnabled: ${filters?.fbsConferenceEnabled ? true : (collegeFilters || []).includes(CollegeFilter.FBS_CONFERENCE)},
      fbsConferenceIds: [${(filters?.fbsConferenceEnabled && filters?.fbsConferenceIds) ? filters.fbsConferenceIds : fbsConferenceFilters.map(fbsConference => fbsConference)}],
      fcsConferenceEnabled: ${filters?.fcsConferenceEnabled ? true : (collegeFilters || []).includes(CollegeFilter.FCS_CONFERENCE)},
      fcsConferenceIds: [${(filters?.fcsConferenceEnabled && filters?.fcsConferenceIds) ? filters.fcsConferenceIds : fcsConferenceFilters.map(fcsConference => fcsConference)}],
      transferStatusEnabled: ${filters?.transferStatusEnabled ? true : (collegeFilters || []).includes(CollegeFilter.TRANSFER_PORTAL)},
      transferStatus: [${(filters?.transferStatusEnabled && filters.transferStatus) ? filters.transferStatus : transferPortalFilters.map(transferPortal => `"${transferPortal}"`)}],
      transferYearsEnabled: ${filters?.transferYearsEnabled ? true : (collegeFilters || []).includes(CollegeFilter.TRANSFER_YEARS)},
      transferYears: [${filters?.transferYearsEnabled ? filters.transferYears : transferYearsFilters?.map(transferYear => `"${transferYear}"`) || ''}],
      allAmericanFilter: {
        filterEnabled: ${filters?.allAmericanFilter?.filterEnabled || (collegeFilters || []).includes(CollegeFilter.ALL_AMERICAN)},
        filterStart: ${filters?.allAmericanFilter?.filterEnabled ? filters.allAmericanFilter.filterStart : (isArray(collegeRangeFilters[CollegeFilter.ALL_AMERICAN]) ? collegeRangeFilters[CollegeFilter.ALL_AMERICAN][0] : ALL_AMERICAN_YEAR_RANGE.MIN)},
        filterEnd: ${filters?.allAmericanFilter?.filterEnabled ? filters.allAmericanFilter.filterEnd : (isArray(collegeRangeFilters[CollegeFilter.ALL_AMERICAN]) ? collegeRangeFilters[CollegeFilter.ALL_AMERICAN][1] : ALL_AMERICAN_YEAR_RANGE.MAX)}
      },
      allConferenceFilter: {
        filterEnabled: ${filters?.allConferenceFilter?.filterEnabled || (collegeFilters || []).includes(CollegeFilter.ALL_CONFERENCE)},
        filterStart: ${filters?.allConferenceFilter?.filterEnabled ? filters.allConferenceFilter.filterStart : (isArray(collegeRangeFilters[CollegeFilter.ALL_CONFERENCE]) ? collegeRangeFilters[CollegeFilter.ALL_CONFERENCE][0] : ALL_CONFERENCE_YEAR_RANGE.MIN)},
        filterEnd: ${filters?.allConferenceFilter?.filterEnabled ? filters.allConferenceFilter.filterEnd : (isArray(collegeRangeFilters[CollegeFilter.ALL_CONFERENCE]) ? collegeRangeFilters[CollegeFilter.ALL_CONFERENCE][1] : ALL_CONFERENCE_YEAR_RANGE.MAX)}
      },
      seniorBowlStart: ${filters?.seniorBowlStart ? filters.seniorBowlStart : ((collegeFilters || []).includes(CollegeFilter.SENIOR_BOWL) ? (isArray(collegeRangeFilters[CollegeFilter.SENIOR_BOWL]) ? collegeRangeFilters[CollegeFilter.SENIOR_BOWL][0] : WATCH_LIST_YEAR_RANGE.MIN) : 0)},
      seniorBowlEnd: ${filters?.seniorBowlEnd ? filters.seniorBowlEnd : ((collegeFilters || []).includes(CollegeFilter.SENIOR_BOWL) ? (isArray(collegeRangeFilters[CollegeFilter.SENIOR_BOWL]) ? collegeRangeFilters[CollegeFilter.SENIOR_BOWL][1] : WATCH_LIST_YEAR_RANGE.MAX) : 0)},
      cgsShowcaseStart: ${filters?.cgsShowcaseStart ? filters.cgsShowcaseStart : ((collegeFilters || []).includes(CollegeFilter.CGS_SHOWCASE) ? (isArray(collegeRangeFilters[CollegeFilter.CGS_SHOWCASE]) ? collegeRangeFilters[CollegeFilter.CGS_SHOWCASE][0] : WATCH_LIST_YEAR_RANGE.MIN): 0)},
      cgsShowcaseEnd: ${filters?.cgsShowcaseEnd ? filters.cgsShowcaseEnd : ((collegeFilters || []).includes(CollegeFilter.CGS_SHOWCASE) ? (isArray(collegeRangeFilters[CollegeFilter.CGS_SHOWCASE]) ? collegeRangeFilters[CollegeFilter.CGS_SHOWCASE][1] : WATCH_LIST_YEAR_RANGE.MAX) : 0)},
      eastWestShrineStart: ${filters?.eastWestShrineStart ? filters.eastWestShrineStart : ((collegeFilters || []).includes(CollegeFilter.EAST_WEST_SHRINE) ? (isArray(collegeRangeFilters[CollegeFilter.EAST_WEST_SHRINE]) ? collegeRangeFilters[CollegeFilter.EAST_WEST_SHRINE][0] : WATCH_LIST_YEAR_RANGE.MIN): 0)},
      eastWestShrineEnd: ${filters?.eastWestShrineEnd ? filters.eastWestShrineEnd : ((collegeFilters || []).includes(CollegeFilter.EAST_WEST_SHRINE) ? (isArray(collegeRangeFilters[CollegeFilter.EAST_WEST_SHRINE]) ? collegeRangeFilters[CollegeFilter.EAST_WEST_SHRINE][1] : WATCH_LIST_YEAR_RANGE.MAX) : 0)},
      collegeWatchListEnabled: ${filters ? filters.collegeWatchListEnabled : (collegeFilters || []).includes(CollegeFilter.WATCH_LIST)
        && ((collegeFilters || []).includes(CollegeFilter.CGS_SHOWCASE) || (collegeFilters || []).includes(CollegeFilter.SENIOR_BOWL) || (collegeFilters || []).includes(CollegeFilter.EAST_WEST_SHRINE))},
      nflPrimaryPositionEnabled: ${nflPrimaryPositionEnabled || (!nflSecondaryPositionEnabled && !!nflPositionCodes?.length)},
      nflSecondaryPositionEnabled: ${nflSecondaryPositionEnabled},
      nflPositionCodes: [${nflPositionCodes}],
      nflHeightFilter: {
        filterEnabled: ${filters ? filters.nflHeightFilter.filterEnabled : (nflFilters || []).includes(NFLFilter.HEIGHT)},
        filterStart: ${(filters && filters.nflHeightFilter.filterEnabled) ? filters.nflHeightFilter.filterStart : isArray(nflRangeFilters[NFLFilter.HEIGHT]) ? nflRangeFilters[NFLFilter.HEIGHT][0] : HEIGHT_RANGE.MIN},
        filterEnd: ${(filters && filters.nflHeightFilter.filterEnabled) ? filters.nflHeightFilter.filterEnd : (isArray(nflRangeFilters[NFLFilter.HEIGHT]) ? nflRangeFilters[NFLFilter.HEIGHT][1] : HEIGHT_RANGE.MAX)}
      },
      nflWeightFilter: {
        filterEnabled: ${filters ? filters.nflWeightFilter.filterEnabled : (nflFilters || []).includes(NFLFilter.WEIGHT)},
        filterStart: ${(filters && filters.nflWeightFilter.filterEnabled) ? filters.nflWeightFilter.filterStart : (isArray(nflRangeFilters[NFLFilter.WEIGHT]) ? nflRangeFilters[NFLFilter.WEIGHT][0] : WEIGHT_RANGE.MIN)},
        filterEnd: ${(filters && filters.nflWeightFilter.filterEnabled) ? filters.nflWeightFilter.filterEnd : (isArray(nflRangeFilters[NFLFilter.WEIGHT]) ? nflRangeFilters[NFLFilter.WEIGHT][1] : WEIGHT_RANGE.MAX)}
      },
      nflDraftTeamEnabled: ${filters ? filters.nflDraftTeamEnabled : (nflFilters || []).includes(NFLFilter.DRAFT_TEAM)},
      nflDraftTeamIds: [${(filters && filters.nflDraftTeamEnabled) ? filters.nflDraftTeamIds : nflDraftTeamFilters.map(draftTeamId => draftTeamId)}],
      nflDraftYearFilter: {
        filterEnabled: ${filters ? filters.nflDraftYearFilter.filterEnabled : (nflFilters || []).includes(NFLFilter.DRAFT_YEAR)},
        filterStart: ${(filters && filters.nflDraftYearFilter.filterEnabled) ? filters.nflDraftYearFilter.filterStart : (isArray(nflRangeFilters[NFLFilter.DRAFT_YEAR]) ? nflRangeFilters[NFLFilter.DRAFT_YEAR][0] : NFL_DRAFT_YEAR_RANGE.MIN)},
        filterEnd: ${(filters && filters.nflDraftYearFilter.filterEnabled) ? filters.nflDraftYearFilter.filterEnd : (isArray(nflRangeFilters[NFLFilter.DRAFT_YEAR]) ? nflRangeFilters[NFLFilter.DRAFT_YEAR][1] : NFL_DRAFT_YEAR_RANGE.MAX)}
      },
      nflDraftRoundFilter: {
        filterEnabled: ${filters ? filters.nflDraftRoundFilter.filterEnabled : ((nflFilters || []).includes(NFLFilter.DRAFT_ROUND))},
        filterStart: ${(filters && filters.nflDraftRoundFilter.filterEnabled) ? filters.nflDraftRoundFilter.filterStart : (isArray(nflRangeFilters[NFLFilter.DRAFT_ROUND]) ? nflRangeFilters[NFLFilter.DRAFT_ROUND][0] : 1)},
        filterEnd: ${(filters && filters.nflDraftRoundFilter.filterEnabled) ? filters.nflDraftRoundFilter.filterEnd : (
          isArray(nflRangeFilters[NFLFilter.DRAFT_ROUND]) ?
            nflRangeFilters[NFLFilter.DRAFT_ROUND][1] :
            ((nflFilters || []).includes(NFLFilter.DRAFT_ROUND) ? 12 : 0)
        )}
      },
      nflUDFAEnabled: ${(nflFilters || []).includes(NFLFilter.UDFA)},
      allProFilter: {
        filterEnabled: ${filters?.allProFilter?.filterEnabled || (nflFilters || []).includes(NFLFilter.ALL_PRO)},
        filterStart: ${filters?.allProFilter?.filterEnabled ? filters.allProFilter.filterStart : (isArray(nflRangeFilters[NFLFilter.ALL_PRO]) ? nflRangeFilters[NFLFilter.ALL_PRO][0] : ALL_PRO_YEAR_RANGE.MIN)},
        filterEnd: ${filters?.allProFilter?.filterEnabled ? filters.allProFilter.filterEnd : (isArray(nflRangeFilters[NFLFilter.ALL_PRO]) ? nflRangeFilters[NFLFilter.ALL_PRO][1] : ALL_PRO_YEAR_RANGE.MAX)}
      },
      proBowlFilter: {
        filterEnabled: ${filters?.proBowlFilter?.filterEnabled || (nflFilters || []).includes(NFLFilter.PRO_BOWL)},
        filterStart: ${filters?.proBowlFilter?.filterEnabled ? filters.proBowlFilter.filterStart : (isArray(nflRangeFilters[NFLFilter.PRO_BOWL]) ? nflRangeFilters[NFLFilter.PRO_BOWL][0] : PRO_BOWL_YEAR_RANGE.MIN)},
        filterEnd: ${filters?.proBowlFilter?.filterEnabled ? filters.proBowlFilter.filterEnd : (isArray(nflRangeFilters[NFLFilter.PRO_BOWL]) ? nflRangeFilters[NFLFilter.PRO_BOWL][1] : PRO_BOWL_YEAR_RANGE.MAX)}
      },
      collegeActiveOnlyEnabled: ${activePlayersEnabled ? filters && filters?.collegeActiveOnlyEnabled ? filters.collegeActiveOnlyEnabled: activePlayersOnly : false},
      hsMPHFilter: {
        filterEnabled: ${filters?.hsMPHRangeFilter?.filterEnabled || (hsFilters || []).includes(HSFilter.HS_MPH)},
        filterStart: ${filters?.hsMPHRangeFilter?.filterEnabled ? filters.hsMPHRangeFilter.filterStart : (isArray(hsMPHRangeFilter) ? hsMPHRangeFilter[0] : 0)},
        filterEnd: ${filters?.hsMPHRangeFilter?.filterEnabled ? filters.hsMPHRangeFilter.filterEnd : (isArray(hsMPHRangeFilter) ? hsMPHRangeFilter[1] :30)}
      },
    }`;
  }

  function getSearchFiltersToStore ():any {
    return {
      playerTypeFilters,
      hsFilters,
      hsClassYearRangeFilter,
      hsArchiveFilter,
      hsName,
      hsCity,
      hsCounty,
      searchByNamePlayers,
      positionFilters,
      hsHeightRangeFilter,
      hsWeightRangeFilter,
      hsWingspanRangeFilter,
      hsArmLengthRangeFilter,
      hsHandSizeRangeFilter,
      otherSportFilters,
      paiRangeFilter,
      combineScoreRangeFilter,
      proCombineScoreRangeFilter,
      rating247RangeFilter,
      gpaMinimum,
      satMinimum,
      actMinimum,
      offerCountRangeFilter,
      offerSchoolName,
      committedSchoolName,
      hsTrackAndFieldYearRangeFilter,
      trackAndFieldEventFilters,
      combineYearRangeFilter,
      combineEventFilters,
      combineCityName,
      combineRangeFilters,
      collegeFilters,
      collegeHeightFilter,
      collegeWeightFilter,
      collegeClassYearRangeFilter,
      collegeName,
      collegeLevelFilters,
      fbsConferenceFilters,
      fcsConferenceFilters,
      collegeRangeFilters,
      offerFBSConferenceFilters,
      offerFCSConferenceFilters,
      transferPortalFilters,
      transferYearsFilters,
      nflFilters,
      nflRangeFilters,
      nflDraftTeamFilters,
      hsMPHRangeFilter
    };
  }

  function applyFilters () {
    setIsDrawerOpen(false);

    const searchFilters = getSearchFiltersToStore();
    clientStorage.save(StorageKey.SEARCH_FILTERS, searchFilters);

    setFilters(searchFilters);
    applyDefaultSortingAndOrdering(searchFilters);
    
    setNeedToRefresh(true);
  }

  function setFilters (searchFilters:any) {
    if (searchFilters) {
      setPlayerTypeFilters(searchFilters.playerTypeFilters);
      setHSClassYearRangeFilter(searchFilters.hsClassYearRangeFilter);
      setHSArchiveFilter(searchFilters.hsArchiveFilter);
      setPrepFilter(searchFilters.prepFilter);
      setHsHeightRangeFilter(searchFilters.hsHeightRangeFilter);
      setHsWeightRangeFilter(searchFilters.hsWeightRangeFilter);
      setHSWingspanRangeFilter(searchFilters.hsWingspanRangeFilter);
      setHSArmLengthRangeFilter(searchFilters.hsArmLengthRangeFilter);
      setHSHandSizeRangeFilter(searchFilters.hsHandSizeRangeFilter);
      setPaiRangeFilter(searchFilters.paiRangeFilter);
      setHsMPHRangeFilter(searchFilters.hsMPHRangeFilter);
      setCombineScoreRangeFilter(searchFilters.combineScoreRangeFilter);
      setProCombineScoreRangeFilter(searchFilters.proCombineScoreRangeFilter);
      setCombineRangeFilters(searchFilters.combineRangeFilters || {});
      setRating247RangeFilter(searchFilters.rating247RangeFilter);
      setOfferCountRangeFilter(searchFilters.offerCountRangeFilter);
      setHSTrackAndFieldYearRangeFilter(searchFilters.hsTrackAndFieldYearRangeFilter);
      setTrackAndFieldEventFilters(searchFilters.trackAndFieldEventFilters || {});
      setHSName(searchFilters.hsName);
      setHSCity(searchFilters.hsCity);
      setHSCounty(searchFilters.hsCounty);
      setGpaMinimum(searchFilters.gpaMinimum);
      setSatMinimum(searchFilters.satMinimum);
      setActMinimum(searchFilters.actMinimum);
      setOfferSchoolName(searchFilters.offerSchoolName);
      setCommittedSchoolName(searchFilters.committedSchoolName);
      setCombineYearRangeFilter(searchFilters.combineYearRangeFilter);
      setCombineCityName(searchFilters.combineCityName);
      setCombineEventFilters(searchFilters.combineEventFilters);
      setOtherSportFilters(searchFilters.otherSportFilters);
      setSearchByNamePlayers(searchFilters.searchByNamePlayers || []);

      setCollegeRangeFilters(searchFilters.collegeRangeFilters);
      setCollegeClassYearRangeFilter(searchFilters.collegeClassYearRangeFilter);
      setCollegeHeightFilter(searchFilters.collegeHeightFilter);
      setCollegeWeightFilter(searchFilters.collegeWeightFilter);
      setCollegeName(searchFilters.collegeName);
      setCollegeLevelFilters(searchFilters.collegeLevelFilters);
      setFCSConferenceFilters(searchFilters.fcsConferenceFilters);
      setFBSConferenceFilters(searchFilters.fbsConferenceFilters);
      setOfferFBSConferenceFilters((searchFilters.offerFBSConferenceFilters) || []);
      setOfferFCSConferenceFilters((searchFilters.offerFCSConferenceFilters) || []);
      setTransferPortalFilters(searchFilters.transferPortalFilters);
      setTransferYearsFilters(searchFilters.transferYearsFilters);

      setNflRangeFilters(searchFilters.nflRangeFilters);
      setNflDraftTeamFilters(searchFilters.nflDraftTeamFilters);

      setPositionFilters(searchFilters.positionFilters);

      setHsFilters(searchFilters.hsFilters);
      setCollegeFilters(searchFilters.collegeFilters);
      setNflFilters(searchFilters.nflFilters);
      setHsMPHRangeFilter(searchFilters.hsMPHRangeFilter);
    }
  }

  function saveSearch (selectedFolder:SearchesFolder | undefined, searchName:string) {
    setPageLoading(true);

    let saveSearchParams = `
      name: "${searchName}",
      filters: ${prepareFilters()},
      folderId: ${selectedFolder ? selectedFolder.id : null}
    `;
    if (savedSearchId) {
      saveSearchParams = `
        ${saveSearchParams}
        id: ${savedSearchId}
      `;
    }

    gql(`
      mutation {
        saveSearch (
          ${saveSearchParams}
        )
      }
    `)
      .then((data:any) => data.saveSearch as number)
      .then((saveSearchId:number) => {
        if (!!saveSearchId) {
          setSavedSearchId(saveSearchId);
          showToast('Saved', ToastType.SUCCESS);
        } else {
          showToast('Oops, something is wrong. Try again or contact our support team.', ToastType.ERROR);
        }
      })
      .catch((error) => {
        console.error(error);
        showToast('Oops, something is wrong. Try again or contact our support team.', ToastType.ERROR);
      })
      .finally(() => {
        setDialogOpen(false);
        setPageLoading(false);
      });
  }

  function prepareDrawerContent (filters:any) {
    const hsFilter:any = [];
    const positionFilters:any = {};
    const trackFieldFilters:any = {};
    const combineFilters:any = {};

    let collegeFilter:any = [];
    let nflFilter:any = [];

    let collegeRangeFilter:any = {
      [CollegeFilter.ALL_AMERICAN]: [ALL_AMERICAN_YEAR_RANGE.MIN, ALL_AMERICAN_YEAR_RANGE.MAX],
      [CollegeFilter.ALL_CONFERENCE]: [ALL_CONFERENCE_YEAR_RANGE.MIN, ALL_CONFERENCE_YEAR_RANGE.MAX],
      [CollegeFilter.SENIOR_BOWL]: [WATCH_LIST_YEAR_RANGE.MIN, WATCH_LIST_YEAR_RANGE.MAX],
      [CollegeFilter.CGS_SHOWCASE]: [WATCH_LIST_YEAR_RANGE.MIN, WATCH_LIST_YEAR_RANGE.MAX],
      [CollegeFilter.EAST_WEST_SHRINE]: [WATCH_LIST_YEAR_RANGE.MIN, WATCH_LIST_YEAR_RANGE.MAX],
    };
    let nflRangeFilter:any = {
      [NFLFilter.ALL_PRO]: [ALL_PRO_YEAR_RANGE.MIN, ALL_PRO_YEAR_RANGE.MAX],
      [NFLFilter.PRO_BOWL]: [PRO_BOWL_YEAR_RANGE.MIN, PRO_BOWL_YEAR_RANGE.MAX],
    };
    const trackFields = new Set();
    const savedSearchFilters:any = {};

    Object.keys(filters).map((key:string) => {
      switch (key) {
        case 'playerType':
          setPlayerTypeFilters(filters.playerType);
          savedSearchFilters.playerTypeFilters = filters.playerType;
          break;
        case 'hsGraduationYearFilter':
          if (filters.hsGraduationYearFilter.filterEnabled) {
            hsFilter.push(HSFilter.HS_CLASS_YEAR);
            setHSClassYearRangeFilter([filters.hsGraduationYearFilter.filterStart, filters.hsGraduationYearFilter.filterEnd]);
            savedSearchFilters.hsClassYearRangeFilter = [filters.hsGraduationYearFilter.filterStart, filters.hsGraduationYearFilter.filterEnd];
            savedSearchFilters.hsFilters = !(savedSearchFilters.hsFilters || []).includes(HSFilter.HS_CLASS_YEAR) && [...(savedSearchFilters.hsFilters || []), HSFilter.HS_CLASS_YEAR];
          } else {
            setHSClassYearRangeFilter([HS_CLASS_YEAR_RANGE.MIN, HS_CLASS_YEAR_RANGE.MAX]);
          }
          break;
        case 'newPlayersEnabled':
          if (filters.newPlayersEnabled) {
            hsFilter.push(HSFilter.NEW_PLAYER);
            savedSearchFilters.hsFilters = !(savedSearchFilters.hsFilters || []).includes(HSFilter.NEW_PLAYER) && [...(savedSearchFilters.hsFilters || []), HSFilter.NEW_PLAYER];
          }
          break;
        case 'archiveFilter':
          if (filters.archiveFilter.filterEnabled) {
            hsFilter.push(HSFilter.ARCHIVES);
            setHSArchiveFilter([filters.archiveFilter.filterStart, filters.archiveFilter.filterEnd]);
            savedSearchFilters.hsArchiveFilter = [filters.archiveFilter.filterStart, filters.archiveFilter.filterEnd];
            savedSearchFilters.hsFilters = !(savedSearchFilters.hsFilters || []).includes(HSFilter.ARCHIVES) && [...(savedSearchFilters.hsFilters || []), HSFilter.ARCHIVES];
          } else {
            setHSArchiveFilter([ARCHIVE_YEAR_RANGE.MIN, ARCHIVE_YEAR_RANGE.MAX]);
          }
          break;
        case 'prepFilter':
          if (filters.prepFilter.filterEnabled) {
            hsFilter.push(HSFilter.ARCHIVES);
            setPrepFilter([filters.prepFilter.filterStart, filters.prepFilter.filterEnd]);
            savedSearchFilters.prepFilter = [filters.prepFilter.filterStart, filters.prepFilter.filterEnd];
            savedSearchFilters.hsFilters = !(savedSearchFilters.hsFilters || []).includes(HSFilter.PREP) && [...(savedSearchFilters.hsFilters || []), HSFilter.PREP];
          } else {
            setPrepFilter([PREP_YEAR_RANGE.MIN, PREP_YEAR_RANGE.MAX]);
          }
          break;
        case 'highSchoolEnabled':
          if (filters.highSchoolEnabled) {
            hsFilter.push(HSFilter.HS_STATE);
            savedSearchFilters.hsFilters = !(savedSearchFilters.hsFilters || []).includes(HSFilter.HS_STATE) && [...(savedSearchFilters.hsFilters || []), HSFilter.HS_STATE];
          }
          break;
        case 'highSchoolName':
          setHSName(filters.highSchoolName);
          savedSearchFilters.hsName = filters.highSchoolName;
          break;
        case 'highSchoolCity':
          setHSCity(filters.highSchoolCity);
          savedSearchFilters.hsCity = filters.highSchoolCity;
          break;
        case 'highSchoolCounty':
          setHSCounty(filters.highSchoolCounty);
          savedSearchFilters.hsCounty = filters.highSchoolCounty;
          break;
        case 'highSchoolState':
          setSelectedStates(filters.highSchoolState);
          savedSearchFilters.highSchoolState = filters.highSchoolState;
          break;
        case 'internationalEnabled':
          if (filters.internationalEnabled) {
            hsFilter.push(HSFilter.INTERNATIONAL);

            savedSearchFilters.hsFilters = !(savedSearchFilters.hsFilters || []).includes(HSFilter.INTERNATIONAL) && [...(savedSearchFilters.hsFilters || []), HSFilter.INTERNATIONAL];
          }
          break;
        case 'playerNamesEnabled':
          if (filters.playerNamesEnabled) {
            hsFilter.push(HSFilter.PLAYER_NAMES);

            savedSearchFilters.hsFilters = !(savedSearchFilters.hsFilters || []).includes(HSFilter.PLAYER_NAMES) && [...(savedSearchFilters.hsFilters || []), HSFilter.PLAYER_NAMES];
          }
          break;
        case 'searchByNamePlayers':
          if (filters.playerNamesEnabled && filters.searchByNamePlayers.length) {
            setSearchByNamePlayers(filters.searchByNamePlayers);

            savedSearchFilters.searchByNamePlayers = filters.searchByNamePlayers || [];
          }
          break;
        case 'hsPrimaryPositionEnabled':
          if (filters.hsPrimaryPositionEnabled) {
            hsFilter.push(PlayerPosition.PRIMARY_POSITION);
            savedSearchFilters.hsFilters = !(savedSearchFilters.hsFilters || []).includes(PlayerPosition.PRIMARY_POSITION) && [...(savedSearchFilters.hsFilters || []), PlayerPosition.PRIMARY_POSITION];
          }
          break;
        case 'hsSecondaryPositionEnabled':
          if (filters.hsSecondaryPositionEnabled) {
            hsFilter.push(PlayerPosition.SECONDARY_POSITION);

            savedSearchFilters.hsFilters = !(savedSearchFilters.hsFilters || []).includes(PlayerPosition.SECONDARY_POSITION) && [...(savedSearchFilters.hsFilters || []), PlayerPosition.SECONDARY_POSITION];
          }
          break;
        case 'hsPositionCodes':
          if (filters.hsPositionCodes.length) {
            positionFilters[FILTER_TYPE.HIGH_SCHOOL] = filters.hsPositionCodes.map((position:string) => `${position}`);

            if (!savedSearchFilters.positionFilters) {
              savedSearchFilters['positionFilters'] = {
                [FILTER_TYPE.HIGH_SCHOOL]: filters.hsPositionCodes
              };
            } else {
              savedSearchFilters.positionFilters = {
                ...savedSearchFilters.positionFilters,
                [FILTER_TYPE.HIGH_SCHOOL]: filters.hsPositionCodes
              };
            }
          }
          break;
        case 'hsHeightFilter':
          if (filters.hsHeightFilter.filterEnabled) {
            hsFilter.push(HSFilter.HS_HEIGHT);
            setHsHeightRangeFilter([filters.hsHeightFilter.filterStart, filters.hsHeightFilter.filterEnd]);

            savedSearchFilters.hsHeightRangeFilter = [filters.hsHeightFilter.filterStart, filters.hsHeightFilter.filterEnd];
            savedSearchFilters.hsFilters = !(savedSearchFilters.hsFilters || []).includes(HSFilter.HS_HEIGHT) && [...(savedSearchFilters.hsFilters || []), HSFilter.HS_HEIGHT];
          } else {
            setHsHeightRangeFilter([HEIGHT_RANGE.MIN, HEIGHT_RANGE.MAX]);
          }
          break;
        case 'hsWeightFilter':
          if (filters.hsWeightFilter.filterEnabled) {
            hsFilter.push(HSFilter.HS_WEIGHT);
            setHsWeightRangeFilter([filters.hsWeightFilter.filterStart, filters.hsWeightFilter.filterEnd]);

            savedSearchFilters.hsWeightRangeFilter = [filters.hsWeightFilter.filterStart, filters.hsWeightFilter.filterEnd];
            savedSearchFilters.hsFilters = !(savedSearchFilters.hsFilters || []).includes(HSFilter.HS_WEIGHT) && [...(savedSearchFilters.hsFilters || []), HSFilter.HS_WEIGHT];
          } else {
            setHsWeightRangeFilter([WEIGHT_RANGE.MIN, WEIGHT_RANGE.MAX]);
          }
          break;
        case 'hsWingspanFilter':
          if (filters.hsWingspanFilter.filterEnabled) {
            hsFilter.push(HSFilter.HS_WINGSPAN);
            setHSWingspanRangeFilter([filters.hsWingspanFilter.filterStart, filters.hsWingspanFilter.filterEnd]);

            savedSearchFilters.hsWingspanRangeFilter = [filters.hsWingspanFilter.filterStart, filters.hsWingspanFilter.filterEnd];
            savedSearchFilters.hsFilters = !(savedSearchFilters.hsFilters || []).includes(HSFilter.HS_WINGSPAN) && [...(savedSearchFilters.hsFilters || []), HSFilter.HS_WINGSPAN];
          } else {
            setHSWingspanRangeFilter([WINGSPAN_RANGE.MIN, WINGSPAN_RANGE.MAX]);
          }
          break;
        case 'hsArmFilter':
          if (filters.hsArmFilter.filterEnabled) {
            hsFilter.push(HSFilter.HS_ARM_LENGTH);
            setHSArmLengthRangeFilter([filters.hsArmFilter.filterStart, filters.hsArmFilter.filterEnd]);

            savedSearchFilters.hsArmLengthRangeFilter = [filters.hsArmFilter.filterStart, filters.hsArmFilter.filterEnd];
            savedSearchFilters.hsFilters = !(savedSearchFilters.hsFilters || []).includes(HSFilter.HS_ARM_LENGTH) && [...(savedSearchFilters.hsFilters || []), HSFilter.HS_ARM_LENGTH];
          } else {
            setHSArmLengthRangeFilter([ARM_LENGTH_RANGE.MIN, ARM_LENGTH_RANGE.MAX]);
          }
          break;
        case 'hsHandFilter':
          if (filters.hsHandFilter.filterEnabled) {
            hsFilter.push(HSFilter.HS_HANDSIZE);
            setHSHandSizeRangeFilter([filters.hsHandFilter.filterStart, filters.hsHandFilter.filterEnd]);

            savedSearchFilters.hsHandSizeRangeFilter = [filters.hsHandFilter.filterStart, filters.hsHandFilter.filterEnd];
            savedSearchFilters.hsFilters = !(savedSearchFilters.hsFilters || []).includes(HSFilter.HS_HANDSIZE) && [...(savedSearchFilters.hsFilters || []), HSFilter.HS_HANDSIZE];
          } else {
            setHSHandSizeRangeFilter([HAND_SIZE_RANGE.MIN, HAND_SIZE_RANGE.MAX]);
          }
          break;
        case 'multiSportsEnabled':
          if (filters.multiSportsEnabled) {
            hsFilter.push(HSFilter.OTHER_SPORTS);

            savedSearchFilters.hsFilters = !(savedSearchFilters.hsFilters || []).includes(HSFilter.OTHER_SPORTS) && [...(savedSearchFilters.hsFilters || []), HSFilter.OTHER_SPORTS];
          }
          break;
        case 'multiSports':
          setOtherSportFilters(filters.multiSports)
          savedSearchFilters.otherSportFilters = filters.multiSports;
          break;
        case 'speedEnabled':
          if (filters.speedEnabled) {
            hsFilter.push(HSFilter.SPEED);

            savedSearchFilters.hsFilters = !(savedSearchFilters.hsFilters || []).includes(HSFilter.SPEED) && [...(savedSearchFilters.hsFilters || []), HSFilter.SPEED];
          }
          break;
        case 'powerEnabled':
          if (filters.powerEnabled) {
            hsFilter.push(HSFilter.POWER);

            savedSearchFilters.hsFilters = !(savedSearchFilters.hsFilters || []).includes(HSFilter.POWER) && [...(savedSearchFilters.hsFilters || []), HSFilter.POWER];
          }
          break;
        case 'paiFilter':
          if (filters.paiFilter.filterEnabled) {
            hsFilter.push(HSFilter.PAI);
            setPaiRangeFilter([filters.paiFilter.filterStart, filters.paiFilter.filterEnd]);

            savedSearchFilters.paiRangeFilters = [filters.paiFilter.filterStart, filters.paiFilter.filterEnd];
            savedSearchFilters.hsFilters = !(savedSearchFilters.hsFilters || []).includes(HSFilter.PAI) && [...(savedSearchFilters.hsFilters || []), HSFilter.PAI];
          } else {
            setPaiRangeFilter([0, 5]);
          }
          break;
        case 'ppiFilter':
          if (filters.ppiFilter.filterEnabled) {
            nflFilter.push(NFLFilter.PPI);
            setPpiRangeFilter([filters.ppiFilter.filterStart, filters.ppiFilter.filterEnd]);

            savedSearchFilters.ppiRangeFilters = [filters.ppiFilter.filterStart, filters.ppiFilter.filterEnd];
            savedSearchFilters.nflFilters = !(savedSearchFilters.nflFilters || []).includes(NFLFilter.PPI) && [...(savedSearchFilters.nflFilters || []), NFLFilter.PPI];
          } else {
            setPpiRangeFilter([0, 5]);
          }
          break;
        case 'combineFilter':
          if (filters.combineFilter.filterEnabled) {
            hsFilter.push(HSFilter.COMBINE);
            setCombineScoreRangeFilter([filters.combineFilter.filterStart, filters.combineFilter.filterEnd]);

            savedSearchFilters.combineScoreRangeFilter = [filters.combineFilter.filterStart, filters.combineFilter.filterEnd];
            savedSearchFilters.hsFilters = !(savedSearchFilters.hsFilters || []).includes(HSFilter.COMBINE) && [...(savedSearchFilters.hsFilters || []), HSFilter.COMBINE];
          } else {
            setCombineScoreRangeFilter([0, 5]);
          }
          break;
        case 'proCombineFilter':
          if (filters.proCombineFilter.filterEnabled) {
            nflFilter.push(NFLFilter.PRO_COMBINE_SCORE);
            setProCombineScoreRangeFilter([filters.proCombineFilter.filterStart, filters.proCombineFilter.filterEnd]);

            savedSearchFilters.proCombineScoreRangeFilter = [filters.proCombineFilter.filterStart, filters.proCombineFilter.filterEnd];
            savedSearchFilters.nflFilter = !(savedSearchFilters.nflFilter || []).includes(NFLFilter.PRO_COMBINE_SCORE) && [...(savedSearchFilters.nflFilter || []), NFLFilter.PRO_COMBINE_SCORE];
          } else {
            setProCombineScoreRangeFilter([0, 5]);
          }
          break;
        case 'rating247Filter':
          if (filters.rating247Filter.filterEnabled) {
            hsFilter.push(HSFilter.RATING_247);
            setRating247RangeFilter([filters.rating247Filter.filterStart, filters.rating247Filter.filterEnd]);

            savedSearchFilters.rating247RangeFilter = [filters.rating247Filter.filterStart, filters.rating247Filter.filterEnd];
            savedSearchFilters.hsFilters = !(savedSearchFilters.hsFilters || []).includes(HSFilter.RATING_247) && [...(savedSearchFilters.hsFilters || []), HSFilter.RATING_247];
          } else {
            setRating247RangeFilter([0, 5]);
          }
          break;
        case 'gpaEnabled':
          if (filters.gpaEnabled) {
            hsFilter.push(HSFilter.GPA);

            savedSearchFilters.hsFilters = !(savedSearchFilters.hsFilters || []).includes(HSFilter.GPA) && [...(savedSearchFilters.hsFilters || []), HSFilter.GPA];
          }
          break;
        case 'gpaMinimum':
          setGpaMinimum(filters.gpaMinimum);

          savedSearchFilters.gpaMinimum = filters.gpaMinimum;
          break;
        case 'satEnabled':
          if (filters.satEnabled) {
            hsFilter.push(HSFilter.SAT);

            savedSearchFilters.hsFilters = !(savedSearchFilters.hsFilters || []).includes(HSFilter.SAT) && [...(savedSearchFilters.hsFilters || []), HSFilter.SAT];
          }
          break;
        case 'satMinimum':
          setSatMinimum(filters.satMinimum);

          savedSearchFilters.satMinimum = filters.satMinimum;
          break;
        case 'actEnabled':
          if (filters.actEnabled) {
            hsFilter.push(HSFilter.ACT);

            savedSearchFilters.hsFilters = !(savedSearchFilters.hsFilters || []).includes(HSFilter.ACT) && [...(savedSearchFilters.hsFilters || []), HSFilter.ACT];
          }
          break;
        case 'hsMPHRangeFilter':
          if (filters.hsMPHRangeFilter.filterEnabled) {
            hsFilter.push(HSFilter.HS_MPH);
            setHsMPHRangeFilter([filters.hsMPHRangeFilter.filterStart, filters.hsMPHRangeFilter.filterEnd]);

            savedSearchFilters.hsMPHRangeFilter = [filters.hsMPHRangeFilter.filterStart, filters.hsMPHRangeFilter.filterEnd];
            savedSearchFilters.hsFilters = !(savedSearchFilters.hsFilters || []).includes(HSFilter.HS_MPH) && [...(savedSearchFilters.hsFilters || []), HSFilter.HS_MPH];
          } else {
            setHsMPHRangeFilter([0, 5]);
          }
          break;
        case 'actMinimum':
          setActMinimum(actMinimum);

          savedSearchFilters.actMinimum = filters.actMinimum;
          break;
        case 'offerCountFilter':
          if (filters.offerCountFilter.filterEnabled) {
            hsFilter.push(HSFilter.OFFER_COUNT);
            setOfferCountRangeFilter([filters.offerCountFilter.filterStart, filters.offerCountFilter.filterEnd]);

            savedSearchFilters.offerCountRangeFilter = [filters.offerCountFilter.filterStart, filters.offerCountFilter.filterEnd];
            savedSearchFilters.hsFilters = !(savedSearchFilters.hsFilters || []).includes(HSFilter.OFFER_COUNT) && [...(savedSearchFilters.hsFilters || []), HSFilter.OFFER_COUNT];
          } else {
            setOfferCountRangeFilter([0, 70]);
          }
          break;
        case 'offerSchoolEnabled':
          if (filters.offerSchoolEnabled) {
            hsFilter.push(HSFilter.OFFER_SCHOOL);

            savedSearchFilters.hsFilters = !(savedSearchFilters.hsFilters || []).includes(HSFilter.OFFER_SCHOOL) && [...(savedSearchFilters.hsFilters || []), HSFilter.OFFER_SCHOOL];
          }
          break;
        case 'offerSchoolName':
          setHSName(offerSchoolName);

          savedSearchFilters.offerSchoolName = filters.offerSchoolName;
          break;
        case 'offerFBSEnabled':
          if (filters.offerFBSEnabled) {
            hsFilter.push(HSFilter.FBS);

            savedSearchFilters.hsFilters = !(savedSearchFilters.hsFilters || []).includes(HSFilter.FBS) && [...(savedSearchFilters.hsFilters || []), HSFilter.FBS];
          }
          break;
        case 'offerFCSEnabled':
          if (filters.offerFCSEnabled) {
            hsFilter.push(HSFilter.FCS);

            savedSearchFilters.hsFilters = !(savedSearchFilters.hsFilters || []).includes(HSFilter.FCS) && [...(savedSearchFilters.hsFilters || []), HSFilter.FCS];
          }
          break;
        case 'highestLevelOfferP5Enabled':
          if (filters.highestLevelOfferP5Enabled) {
            hsFilter.push(HSFilter.HIGHEST_LEVEL_OFFER_P5);

            savedSearchFilters.hsFilters = !(savedSearchFilters.hsFilters || []).includes(HSFilter.HIGHEST_LEVEL_OFFER_P5) && [...(savedSearchFilters.hsFilters || []), HSFilter.HIGHEST_LEVEL_OFFER_P5];
          }
          break;
        case 'highestLevelOfferG5Enabled':
          if (filters.highestLevelOfferG5Enabled) {
            hsFilter.push(HSFilter.HIGHEST_LEVEL_OFFER_G5);

            savedSearchFilters.hsFilters = !(savedSearchFilters.hsFilters || []).includes(HSFilter.HIGHEST_LEVEL_OFFER_G5) && [...(savedSearchFilters.hsFilters || []), HSFilter.HIGHEST_LEVEL_OFFER_G5];
          }
          break;
        case 'uncommittedEnabled':
          if (filters.uncommittedEnabled) {
            setUncommittedEnabled(filters.uncommittedEnabled);
          }
          break;
        case 'committedSchoolEnabled':
          if (filters.committedSchoolEnabled) {
            hsFilter.push(HSFilter.COMMITTED_SCHOOL);

            savedSearchFilters.hsFilters = !(savedSearchFilters.hsFilters || []).includes(HSFilter.COMMITTED_SCHOOL) && [...(savedSearchFilters.hsFilters || []), HSFilter.COMMITTED_SCHOOL];
          }
          break;
        case 'committedSchoolName':
          setCollegeName(filters.committedSchoolName);

          savedSearchFilters.committedSchoolName = filters.committedSchoolName;
          break;
        case 'trackFieldYearFilter':
          if (filters.trackFieldYearFilter.filterEnabled) {
            hsFilter.push(HSFilter.TRACK_AND_FIELD_DATA_YEAR);
            setHSTrackAndFieldYearRangeFilter([filters.trackFieldYearFilter.filterStart, filters.trackFieldYearFilter.filterEnd]);

            savedSearchFilters.hsTrackAndFieldYearRangeFilter = [filters.trackFieldYearFilter.filterStart, filters.trackFieldYearFilter.filterEnd];
            savedSearchFilters.hsFilters = !(savedSearchFilters.hsFilters || []).includes(HSFilter.TRACK_AND_FIELD_DATA_YEAR) && [...(savedSearchFilters.hsFilters || []), HSFilter.TRACK_AND_FIELD_DATA_YEAR];
          } else {
            setHSTrackAndFieldYearRangeFilter([TRACK_AND_FIELD_YEAR_RANGE.MIN, TRACK_AND_FIELD_YEAR_RANGE.MAX]);
          }
          break;
        case 'trackField55mFilter':
          if (filters.trackField55mFilter.filterEnabled) {
            hsFilter.push(HSFilter.S_55_METERS);
            trackFields.add(HSFilter.SPRINTS);
            trackFieldFilters[HSFilter.S_55_METERS] = [filters.trackField55mFilter.filterStart, filters.trackField55mFilter.filterEnd];

            savedSearchFilters.trackAndFieldEventFilters = {...trackAndFieldEventFilters, [HSFilter.S_55_METERS]: [filters.trackField55mFilter.filterStart, filters.trackField55mFilter.filterEnd]};
            savedSearchFilters.hsFilters = !(savedSearchFilters.hsFilters || []).includes(HSFilter.S_55_METERS) && [...(savedSearchFilters.hsFilters || []), HSFilter.S_55_METERS];
          } else {
            trackFieldFilters[HSFilter.S_55_METERS] = [6, 10];
          }
          break;
        case 'trackField60mFilter':
          if (filters.trackField60mFilter.filterEnabled) {
            hsFilter.push(HSFilter.S_60_METERS);
            trackFields.add(HSFilter.SPRINTS);
            trackFieldFilters[HSFilter.S_60_METERS] = [filters.trackField60mFilter.filterStart, filters.trackField60mFilter.filterEnd];

            savedSearchFilters.trackAndFieldEventFilters = {...trackAndFieldEventFilters, [HSFilter.S_60_METERS]: [filters.trackField60mFilter.filterStart, filters.trackField60mFilter.filterEnd]};
            savedSearchFilters.hsFilters = !(savedSearchFilters.hsFilters || []).includes(HSFilter.S_60_METERS) && [...(savedSearchFilters.hsFilters || []), HSFilter.S_60_METERS];
          } else {
            trackFieldFilters[HSFilter.S_60_METERS] = [6, 10];
          }
          break;
        case 'trackField100mFilter':
          if (filters.trackField100mFilter.filterEnabled) {
            hsFilter.push(HSFilter.S_100_METERS);
            trackFields.add(HSFilter.SPRINTS);
            trackFieldFilters[HSFilter.S_100_METERS] = [filters.trackField100mFilter.filterStart, filters.trackField100mFilter.filterEnd];
            savedSearchFilters.trackAndFieldEventFilters = {...trackAndFieldEventFilters, [HSFilter.S_100_METERS]: [filters.trackField100mFilter.filterStart, filters.trackField100mFilter.filterEnd]};
            savedSearchFilters.hsFilters = !(savedSearchFilters.hsFilters || []).includes(HSFilter.S_100_METERS) && [...(savedSearchFilters.hsFilters || []), HSFilter.S_100_METERS];
          } else {
            trackFieldFilters[HSFilter.S_100_METERS] = [9, 18];
          }
          break;
        case 'trackField200mFilter':
          if (filters.trackField200mFilter.filterEnabled) {
            hsFilter.push(HSFilter.S_200_METERS);
            trackFields.add(HSFilter.SPRINTS);
            trackFieldFilters[HSFilter.S_200_METERS] = [filters.trackField200mFilter.filterStart, filters.trackField200mFilter.filterEnd];

            savedSearchFilters.trackAndFieldEventFilters = {...trackAndFieldEventFilters, [HSFilter.S_200_METERS]: [filters.trackField200mFilter.filterStart, filters.trackField200mFilter.filterEnd]};
            savedSearchFilters.hsFilters = !(savedSearchFilters.hsFilters || []).includes(HSFilter.S_200_METERS) && [...(savedSearchFilters.hsFilters || []), HSFilter.S_200_METERS];
          } else {
            trackFieldFilters[HSFilter.S_200_METERS] = [18, 32];
          }
          break;
        case 'trackField300mFilter':
          if (filters.trackField300mFilter.filterEnabled) {
            hsFilter.push(HSFilter.S_300_METERS);
            trackFields.add(HSFilter.SPRINTS);
            trackFieldFilters[HSFilter.S_300_METERS] = [filters.trackField300mFilter.filterStart, filters.trackField300mFilter.filterEnd];

            savedSearchFilters.trackAndFieldEventFilters = {...trackAndFieldEventFilters, [HSFilter.S_300_METERS]: [filters.trackField300mFilter.filterStart, filters.trackField300mFilter.filterEnd]};
            savedSearchFilters.hsFilters = !(savedSearchFilters.hsFilters || []).includes(HSFilter.S_300_METERS) && [...(savedSearchFilters.hsFilters || []), HSFilter.S_300_METERS];
          } else {
            trackFieldFilters[HSFilter.S_300_METERS] = [33, 50];
          }
          break;
        case 'trackField400mFilter':
          if (filters.trackField400mFilter.filterEnabled) {
            hsFilter.push(HSFilter.S_400_METERS);
            trackFields.add(HSFilter.SPRINTS);
            trackFieldFilters[HSFilter.S_400_METERS] = [filters.trackField400mFilter.filterStart, filters.trackField400mFilter.filterEnd];

            savedSearchFilters.trackAndFieldEventFilters = {...trackAndFieldEventFilters, [HSFilter.S_400_METERS]: [filters.trackField400mFilter.filterStart, filters.trackField400mFilter.filterEnd]};
            savedSearchFilters.hsFilters = !(savedSearchFilters.hsFilters || []).includes(HSFilter.S_400_METERS) && [...(savedSearchFilters.hsFilters || []), HSFilter.S_400_METERS];
          } else {
            trackFieldFilters[HSFilter.S_400_METERS] = [40, 75];
          }
          break;
        case 'trackField800mFilter':
          if (filters.trackField800mFilter.filterEnabled) {
            hsFilter.push(HSFilter.MD_800_METERS);
            trackFields.add(HSFilter.MIDDLE_DISTANCE);
            trackFieldFilters[HSFilter.MD_800_METERS] = [filters.trackField800mFilter.filterStart, filters.trackField800mFilter.filterEnd];

            savedSearchFilters.trackAndFieldEventFilters = {...trackAndFieldEventFilters, [HSFilter.MD_800_METERS]: [filters.trackField800mFilter.filterStart, filters.trackField800mFilter.filterEnd]};
            savedSearchFilters.hsFilters = !(savedSearchFilters.hsFilters || []).includes(HSFilter.MD_800_METERS) && [...(savedSearchFilters.hsFilters || []), HSFilter.MD_800_METERS];
          } else {
            trackFieldFilters[HSFilter.MD_800_METERS] = [90, 180];
          }
          break;
        case 'trackField55hhFilter':
          if (filters.trackField55hhFilter.filterEnabled) {
            hsFilter.push(HSFilter.HH_55_METERS);
            trackFields.add(HSFilter.HURDLES);
            trackFieldFilters[HSFilter.HH_55_METERS] = [filters.trackField55hhFilter.filterStart, filters.trackField55hhFilter.filterEnd];

            savedSearchFilters.trackAndFieldEventFilters = {...trackAndFieldEventFilters, [HSFilter.HH_55_METERS]: [filters.trackField55hhFilter.filterStart, filters.trackField55hhFilter.filterEnd]};
            savedSearchFilters.hsFilters = !(savedSearchFilters.hsFilters || []).includes(HSFilter.HH_55_METERS) && [...(savedSearchFilters.hsFilters || []), HSFilter.HH_55_METERS];
          } else {
            trackFieldFilters[HSFilter.HH_55_METERS] = [7, 12];
          }
          break;
        case 'trackField60hhFilter':
          if (filters.trackField60hhFilter.filterEnabled) {
            hsFilter.push(HSFilter.HH_60_METERS);
            trackFields.add(HSFilter.HURDLES);
            trackFieldFilters[HSFilter.HH_60_METERS] = [filters.trackField60hhFilter.filterStart, filters.trackField60hhFilter.filterEnd];

            savedSearchFilters.trackAndFieldEventFilters = {...trackAndFieldEventFilters, [HSFilter.HH_60_METERS]: [filters.trackField60hhFilter.filterStart, filters.trackField60hhFilter.filterEnd]};
            savedSearchFilters.hsFilters = !(savedSearchFilters.hsFilters || []).includes(HSFilter.HH_60_METERS) && [...(savedSearchFilters.hsFilters || []), HSFilter.HH_60_METERS];
          } else {
            trackFieldFilters[HSFilter.HH_60_METERS] = [7, 12];
          }
          break;
        case 'trackField110hhFilter':
          if (filters.trackField110hhFilter.filterEnabled) {
            hsFilter.push(HSFilter.HH_110_METERS);
            trackFields.add(HSFilter.HURDLES);
            trackFieldFilters[HSFilter.HH_110_METERS] = [filters.trackField110hhFilter.filterStart, filters.trackField110hhFilter.filterEnd];

            savedSearchFilters.trackAndFieldEventFilters = {...trackAndFieldEventFilters, [HSFilter.HH_110_METERS]: [filters.trackField110hhFilter.filterStart, filters.trackField110hhFilter.filterEnd]};
            savedSearchFilters.hsFilters = !(savedSearchFilters.hsFilters || []).includes(HSFilter.HH_110_METERS) && [...(savedSearchFilters.hsFilters || []), HSFilter.HH_110_METERS];
          } else {
            trackFieldFilters[HSFilter.HH_110_METERS] = [12, 25];
          }
          break;
        case 'trackField300ihFilter':
          if (filters.trackField300ihFilter.filterEnabled) {
            hsFilter.push(HSFilter.H_300_INT);
            trackFields.add(HSFilter.HURDLES);
            trackFieldFilters[HSFilter.H_300_INT] = [filters.trackField300ihFilter.filterStart, filters.trackField300ihFilter.filterEnd];

            savedSearchFilters.trackAndFieldEventFilters = {...trackAndFieldEventFilters, [HSFilter.H_300_INT]: [filters.trackField300ihFilter.filterStart, filters.trackField300ihFilter.filterEnd]};
            savedSearchFilters.hsFilters = !(savedSearchFilters.hsFilters || []).includes(HSFilter.H_300_INT) && [...(savedSearchFilters.hsFilters || []), HSFilter.H_300_INT];
          } else {
            trackFieldFilters[HSFilter.H_300_INT] = [34, 58];
          }
          break;
        case 'trackField400hFilter':
          if (filters.trackField400hFilter.filterEnabled) {
            hsFilter.push(HSFilter.H_400_METERS);
            trackFields.add(HSFilter.HURDLES);
            trackFieldFilters[HSFilter.H_400_METERS] = [filters.trackField400hFilter.filterStart, filters.trackField400hFilter.filterEnd];

            savedSearchFilters.trackAndFieldEventFilters = {...trackAndFieldEventFilters, [HSFilter.H_400_METERS]: [filters.trackField400hFilter.filterStart, filters.trackField400hFilter.filterEnd]};
            savedSearchFilters.hsFilters = !(savedSearchFilters.hsFilters || []).includes(HSFilter.H_400_METERS) && [...(savedSearchFilters.hsFilters || []), HSFilter.H_400_METERS];
          } else {
            trackFieldFilters[HSFilter.H_400_METERS] = [45, 80];
          }
          break;
        case 'trackField400rFilter':
          if (filters.trackField400rFilter.filterEnabled) {
            hsFilter.push(HSFilter.R_400_METERS);
            trackFields.add(HSFilter.RELAYS);
            trackFieldFilters[HSFilter.R_400_METERS] = [filters.trackField400rFilter.filterStart, filters.trackField400rFilter.filterEnd];

            savedSearchFilters.trackAndFieldEventFilters = {...trackAndFieldEventFilters, [HSFilter.R_400_METERS]: [filters.trackField400rFilter.filterStart, filters.trackField400rFilter.filterEnd]};
            savedSearchFilters.hsFilters = !(savedSearchFilters.hsFilters || []).includes(HSFilter.R_400_METERS) && [...(savedSearchFilters.hsFilters || []), HSFilter.R_400_METERS];
          } else {
            trackFieldFilters[HSFilter.R_400_METERS] = [38, 55];
          }
          break;
        case 'trackField800rFilter':
          if (filters.trackField800rFilter.filterEnabled) {
            hsFilter.push(HSFilter.R_800_METERS);
            trackFields.add(HSFilter.RELAYS);
            trackFieldFilters[HSFilter.R_800_METERS] = [filters.trackField800rFilter.filterStart, filters.trackField800rFilter.filterEnd];

            savedSearchFilters.trackAndFieldEventFilters = {...trackAndFieldEventFilters, [HSFilter.R_800_METERS]: [filters.trackField800rFilter.filterStart, filters.trackField800rFilter.filterEnd]};
            savedSearchFilters.hsFilters = !(savedSearchFilters.hsFilters || []).includes(HSFilter.R_800_METERS) && [...(savedSearchFilters.hsFilters || []), HSFilter.R_800_METERS];
          } else {
            trackFieldFilters[HSFilter.R_800_METERS] = [80, 115];
          }
          break;
        case 'trackField1600rFilter':
          if (filters.trackField1600rFilter.filterEnabled) {
            hsFilter.push(HSFilter.R_1600_METERS);
            trackFields.add(HSFilter.RELAYS);
            trackFieldFilters[HSFilter.R_1600_METERS] = [filters.trackField1600rFilter.filterStart, filters.trackField1600rFilter.filterEnd];

            savedSearchFilters.trackAndFieldEventFilters = {...trackAndFieldEventFilters, [HSFilter.R_1600_METERS]: [filters.trackField1600rFilter.filterStart, filters.trackField1600rFilter.filterEnd]};
            savedSearchFilters.hsFilters = !(savedSearchFilters.hsFilters || []).includes(HSFilter.R_1600_METERS) && [...(savedSearchFilters.hsFilters || []), HSFilter.R_1600_METERS];
          } else {
            trackFieldFilters[HSFilter.R_1600_METERS] = [180, 270];
          }
          break;
        case 'trackField3200rFilter':
          if (filters.trackField3200rFilter.filterEnabled) {
            hsFilter.push(HSFilter.R_3200_METERS);
            trackFields.add(HSFilter.RELAYS);
            trackFieldFilters[HSFilter.R_3200_METERS] = [filters.trackField3200rFilter.filterStart, filters.trackField3200rFilter.filterEnd];

            savedSearchFilters.trackAndFieldEventFilters = {...trackAndFieldEventFilters, [HSFilter.R_3200_METERS]: [filters.trackField3200rFilter.filterStart, filters.trackField3200rFilter.filterEnd]};
            savedSearchFilters.hsFilters = !(savedSearchFilters.hsFilters || []).includes(HSFilter.R_3200_METERS) && [...(savedSearchFilters.hsFilters || []), HSFilter.R_3200_METERS];
          } else {
            trackFieldFilters[HSFilter.R_3200_METERS] = [450, 750];
          }
          break;
        case 'trackFieldShotputFilter':
          if (filters.trackFieldShotputFilter.filterEnabled) {
            hsFilter.push(HSFilter.SHOTPUT);
            trackFields.add(HSFilter.THROWING_EVENTS);
            trackFieldFilters[HSFilter.SHOTPUT] = [filters.trackFieldShotputFilter.filterStart, filters.trackFieldShotputFilter.filterEnd];

            savedSearchFilters.trackAndFieldEventFilters = {...trackAndFieldEventFilters, [HSFilter.SHOTPUT]: [filters.trackFieldShotputFilter.filterStart, filters.trackFieldShotputFilter.filterEnd]};
            savedSearchFilters.hsFilters = !(savedSearchFilters.hsFilters || []).includes(HSFilter.SHOTPUT) && [...(savedSearchFilters.hsFilters || []), HSFilter.SHOTPUT];
          } else {
            trackFieldFilters[HSFilter.SHOTPUT] = [300, 1020];
          }
          break;
        case 'trackFieldJavelinFilter':
          if (filters.trackFieldJavelinFilter.filterEnabled) {
            hsFilter.push(HSFilter.JAVELIN);
            trackFields.add(HSFilter.THROWING_EVENTS);
            trackFieldFilters[HSFilter.JAVELIN] = [filters.trackFieldJavelinFilter.filterStart, filters.trackFieldJavelinFilter.filterEnd];

            savedSearchFilters.trackAndFieldEventFilters = {...trackAndFieldEventFilters, [HSFilter.JAVELIN]: [filters.trackFieldJavelinFilter.filterStart, filters.trackFieldJavelinFilter.filterEnd]};
            savedSearchFilters.hsFilters = !(savedSearchFilters.hsFilters || []).includes(HSFilter.JAVELIN) && [...(savedSearchFilters.hsFilters || []), HSFilter.JAVELIN];
          } else {
            trackFieldFilters[HSFilter.JAVELIN] = [420, 3120];
          }
          break;
        case 'trackFieldDiscusFilter':
          if (filters.trackFieldDiscusFilter.filterEnabled) {
            hsFilter.push(HSFilter.DISCUS);
            trackFields.add(HSFilter.THROWING_EVENTS);
            trackFieldFilters[HSFilter.DISCUS] = [filters.trackFieldDiscusFilter.filterStart, filters.trackFieldDiscusFilter.filterEnd];

            savedSearchFilters.trackAndFieldEventFilters = {...trackAndFieldEventFilters, [HSFilter.DISCUS]: [filters.trackFieldDiscusFilter.filterStart, filters.trackFieldDiscusFilter.filterEnd]};
            savedSearchFilters.hsFilters = !(savedSearchFilters.hsFilters || []).includes(HSFilter.DISCUS) && [...(savedSearchFilters.hsFilters || []), HSFilter.DISCUS];
          } else {
            trackFieldFilters[HSFilter.DISCUS] = [720, 3000];
          }
          break;
        case 'trackFieldHighJumpFilter':
          if (filters.trackFieldHighJumpFilter.filterEnabled) {
            hsFilter.push(HSFilter.HIGH_JUMP);
            trackFields.add(HSFilter.JUMPING_EVENTS);
            trackFieldFilters[HSFilter.HIGH_JUMP] = [filters.trackFieldHighJumpFilter.filterStart, filters.trackFieldHighJumpFilter.filterEnd];

            savedSearchFilters.trackAndFieldEventFilters = {...trackAndFieldEventFilters, [HSFilter.HIGH_JUMP]: [filters.trackFieldHighJumpFilter.filterStart, filters.trackFieldHighJumpFilter.filterEnd]};
            savedSearchFilters.hsFilters = !(savedSearchFilters.hsFilters || []).includes(HSFilter.HIGH_JUMP) && [...(savedSearchFilters.hsFilters || []), HSFilter.HIGH_JUMP];
          } else {
            trackFieldFilters[HSFilter.HIGH_JUMP] = [54, 96];
          }
          break;
        case 'trackFieldLongJumpFilter':
          if (filters.trackFieldLongJumpFilter.filterEnabled) {
            hsFilter.push(HSFilter.LONG_JUMP);
            trackFields.add(HSFilter.JUMPING_EVENTS);
            trackFieldFilters[HSFilter.LONG_JUMP] = [filters.trackFieldLongJumpFilter.filterStart, filters.trackFieldLongJumpFilter.filterEnd];

            savedSearchFilters.trackAndFieldEventFilters = {...trackAndFieldEventFilters, [HSFilter.LONG_JUMP]: [filters.trackFieldLongJumpFilter.filterStart, filters.trackFieldLongJumpFilter.filterEnd]};
            savedSearchFilters.hsFilters = !(savedSearchFilters.hsFilters || []).includes(HSFilter.LONG_JUMP) && [...(savedSearchFilters.hsFilters || []), HSFilter.LONG_JUMP];
          } else {
            trackFieldFilters[HSFilter.LONG_JUMP] = [168, 360];
          }
          break;
        case 'trackFieldTripleJumpFilter':
          if (filters.trackFieldTripleJumpFilter.filterEnabled) {
            hsFilter.push(HSFilter.TRIPLE_JUMP);
            trackFields.add(HSFilter.JUMPING_EVENTS);
            trackFieldFilters[HSFilter.TRIPLE_JUMP] = [filters.trackFieldTripleJumpFilter.filterStart, filters.trackFieldTripleJumpFilter.filterEnd];

            savedSearchFilters.trackAndFieldEventFilters = {...trackAndFieldEventFilters, [HSFilter.TRIPLE_JUMP]: [filters.trackFieldTripleJumpFilter.filterStart, filters.trackFieldTripleJumpFilter.filterEnd]};
            savedSearchFilters.hsFilters = !(savedSearchFilters.hsFilters || []).includes(HSFilter.TRIPLE_JUMP) && [...(savedSearchFilters.hsFilters || []), HSFilter.TRIPLE_JUMP];
          } else {
            trackFieldFilters[HSFilter.TRIPLE_JUMP] = [300, 720];
          }
          break;
        case 'trackFieldPoleVaultFilter':
          if (filters.trackFieldPoleVaultFilter.filterEnabled) {
            hsFilter.push(HSFilter.POLE_VAULT_JUMP);
            trackFields.add(HSFilter.JUMPING_EVENTS);
            trackFieldFilters[HSFilter.POLE_VAULT_JUMP] = [filters.trackFieldPoleVaultFilter.filterStart, filters.trackFieldPoleVaultFilter.filterEnd];

            savedSearchFilters.trackAndFieldEventFilters = {...trackAndFieldEventFilters, [HSFilter.POLE_VAULT_JUMP]: [filters.trackFieldPoleVaultFilter.filterStart, filters.trackFieldPoleVaultFilter.filterEnd]};
            savedSearchFilters.hsFilters = !(savedSearchFilters.hsFilters || []).includes(HSFilter.POLE_VAULT_JUMP) && [...(savedSearchFilters.hsFilters || []), HSFilter.POLE_VAULT_JUMP];
          } else {
            trackFieldFilters[HSFilter.POLE_VAULT_JUMP] = [96, 216];
          }
          break;
        case 'combineYearFilter':
          if (filters.combineYearFilter.filterEnabled) {
            hsFilter.push(HSFilter.COMBINE_YEAR);
            trackFieldFilters[HSFilter.COMBINE_YEAR] = [filters.combineYearFilter.filterStart, filters.combineYearFilter.filterEnd];

            savedSearchFilters.combineYearRangeFilter = [filters.combineYearFilter.filterStart, filters.combineYearFilter.filterEnd];
            savedSearchFilters.hsFilters = !(savedSearchFilters.hsFilters || []).includes(HSFilter.COMBINE_YEAR) && [...(savedSearchFilters.hsFilters || []), HSFilter.COMBINE_YEAR];
          } else {
            trackFieldFilters[HSFilter.COMBINE_YEAR] = [COMBINE_YEAR_RANGE.MIN, COMBINE_YEAR_RANGE.MAX];
          }
          break;
        case 'combineEventEnabled':
          if (filters.combineEventEnabled) {
            hsFilter.push(HSFilter.COMBINE_EVENT);

            savedSearchFilters.hsFilters = !(savedSearchFilters.hsFilters || []).includes(HSFilter.COMBINE_EVENT) && [...(savedSearchFilters.hsFilters || []), HSFilter.COMBINE_EVENT];
          }
          break;
        case 'combineEvents':
          setCombineEventFilters(filters.combineEvents);

          savedSearchFilters.combineEventFilters = filters.combineEvents;
          break;
        case 'combineCityEnabled':
          if (filters.combineCityEnabled) {
            hsFilter.push(HSFilter.COMBINE_CITY);

            savedSearchFilters.hsFilters = !(savedSearchFilters.hsFilters || []).includes(HSFilter.COMBINE_CITY) && [...(savedSearchFilters.hsFilters || []), HSFilter.COMBINE_CITY];
          }
          break;
        case 'combineCity':
          setCombineCityName(filters.combineCity);
          savedSearchFilters.combineCityName = filters.combineCity;
          break;
        case 'combine40mDashFilter':
          if (filters.combine40mDashFilter.filterEnabled) {
            hsFilter.push(HSFilter.COMBINE_40_M_DASH);
            combineFilters[HSFilter.COMBINE_40_M_DASH] = [filters.combine40mDashFilter.filterStart, filters.combine40mDashFilter.filterEnd];

            savedSearchFilters.combineEventFilters = {...combineEventFilters, [HSFilter.COMBINE_40_M_DASH]: [filters.combine40mDashFilter.filterStart, filters.combine40mDashFilter.filterEnd]};
            savedSearchFilters.hsFilters = !(savedSearchFilters.hsFilters || []).includes(HSFilter.COMBINE_40_M_DASH) && [...(savedSearchFilters.hsFilters || []), HSFilter.COMBINE_40_M_DASH];
          } else {
            combineFilters[HSFilter.COMBINE_40_M_DASH] = [4, 7];
          }
          break;
        case 'combine10SplitFilter':
          if (filters.combine10SplitFilter.filterEnabled) {
            hsFilter.push(HSFilter.COMBINE_10_SPLIT);
            combineFilters[HSFilter.COMBINE_10_SPLIT] = [filters.combine10SplitFilter.filterStart, filters.combine10SplitFilter.filterEnd];

            savedSearchFilters.combineEventFilters = {...combineEventFilters, [HSFilter.COMBINE_10_SPLIT]: [filters.combine10SplitFilter.filterStart, filters.combine10SplitFilter.filterEnd]};
            savedSearchFilters.hsFilters = !(savedSearchFilters.hsFilters || []).includes(HSFilter.COMBINE_10_SPLIT) && [...(savedSearchFilters.hsFilters || []), HSFilter.COMBINE_10_SPLIT];
          } else {
            combineFilters[HSFilter.COMBINE_10_SPLIT] = [4, 7];
          }
          break;
        case 'combineMPHFilter':
          if (filters.combineMPHFilter.filterEnabled) {
            hsFilter.push(HSFilter.COMBINE_MPH);
            combineFilters[HSFilter.COMBINE_MPH] = [filters.combineMPHFilter.filterStart, filters.combineMPHFilter.filterEnd];

            savedSearchFilters.combineEventFilters = {...combineEventFilters, [HSFilter.COMBINE_MPH]: [filters.combineMPHFilter.filterStart, filters.combineMPHFilter.filterEnd]};
            savedSearchFilters.hsFilters = !(savedSearchFilters.hsFilters || []).includes(HSFilter.COMBINE_MPH) && [...(savedSearchFilters.hsFilters || []), HSFilter.COMBINE_MPH];
          } else {
            combineFilters[HSFilter.COMBINE_MPH] = [4, 7];
          }
          break;
        case 'combineShuttleFilter':
          if (filters.combineShuttleFilter.filterEnabled) {
            hsFilter.push(HSFilter.COMBINE_SHUTTLE);
            combineFilters[HSFilter.COMBINE_SHUTTLE] = [filters.combineShuttleFilter.filterStart, filters.combineShuttleFilter.filterEnd];

            savedSearchFilters.combineEventFilters = {...combineEventFilters, [HSFilter.COMBINE_SHUTTLE]: [filters.combineShuttleFilter.filterStart, filters.combineShuttleFilter.filterEnd]};
            savedSearchFilters.hsFilters = !(savedSearchFilters.hsFilters || []).includes(HSFilter.COMBINE_SHUTTLE) && [...(savedSearchFilters.hsFilters || []), HSFilter.COMBINE_SHUTTLE];
          } else {
            combineFilters[HSFilter.COMBINE_SHUTTLE] = [3, 9];
          }
          break;
        case 'combine3ConeFilter':
          if (filters.combine3ConeFilter.filterEnabled) {
            hsFilter.push(HSFilter.COMBINE_3_CONE);
            combineFilters[HSFilter.COMBINE_3_CONE] = [filters.combine3ConeFilter.filterStart, filters.combine3ConeFilter.filterEnd];

            savedSearchFilters.combineEventFilters = {...combineEventFilters, [HSFilter.COMBINE_3_CONE]: [filters.combine3ConeFilter.filterStart, filters.combine3ConeFilter.filterEnd]};
            savedSearchFilters.hsFilters = !(savedSearchFilters.hsFilters || []).includes(HSFilter.COMBINE_3_CONE) && [...(savedSearchFilters.hsFilters || []), HSFilter.COMBINE_3_CONE];
          } else {
            combineFilters[HSFilter.COMBINE_3_CONE] = [3, 9];
          }
          break;
        case 'combineVerticalJumpFilter':
          if (filters.combineVerticalJumpFilter.filterEnabled) {
            hsFilter.push(HSFilter.COMBINE_VERTICAL_JUMP);
            combineFilters[HSFilter.COMBINE_VERTICAL_JUMP] = [filters.combineVerticalJumpFilter.filterStart, filters.combineVerticalJumpFilter.filterEnd];

            savedSearchFilters.combineEventFilters = {...combineEventFilters, [HSFilter.COMBINE_VERTICAL_JUMP]: [filters.combineVerticalJumpFilter.filterStart, filters.combineVerticalJumpFilter.filterEnd]};
            savedSearchFilters.hsFilters = !(savedSearchFilters.hsFilters || []).includes(HSFilter.COMBINE_VERTICAL_JUMP) && [...(savedSearchFilters.hsFilters || []), HSFilter.COMBINE_VERTICAL_JUMP];
          } else {
            combineFilters[HSFilter.COMBINE_VERTICAL_JUMP] = [15, 50];
          }
          break;
        case 'combineBroadJumpFilter':
          if (filters.combineBroadJumpFilter.filterEnabled) {
            hsFilter.push(HSFilter.COMBINE_BROAD_JUMP);
            combineFilters[HSFilter.COMBINE_BROAD_JUMP] = [filters.combineBroadJumpFilter.filterStart, filters.combineBroadJumpFilter.filterEnd];

            savedSearchFilters.combineEventFilters = {...combineEventFilters, [HSFilter.COMBINE_BROAD_JUMP]: [filters.combineBroadJumpFilter.filterStart, filters.combineBroadJumpFilter.filterEnd]};
            savedSearchFilters.hsFilters = !(savedSearchFilters.hsFilters || []).includes(HSFilter.COMBINE_BROAD_JUMP) && [...(savedSearchFilters.hsFilters || []), HSFilter.COMBINE_BROAD_JUMP];
          } else {
            combineFilters[HSFilter.COMBINE_BROAD_JUMP] = [15, 50];
          }
          break;
        case 'combinePowerTossFilter':
          if (filters.combinePowerTossFilter.filterEnabled) {
            hsFilter.push(HSFilter.COMBINE_POWERTOSS);
            combineFilters[HSFilter.COMBINE_POWERTOSS] = [filters.combinePowerTossFilter.filterStart, filters.combinePowerTossFilter.filterEnd];

            savedSearchFilters.combineEventFilters = {...combineEventFilters, [HSFilter.COMBINE_POWERTOSS]: [filters.combinePowerTossFilter.filterStart, filters.combinePowerTossFilter.filterEnd]};
            savedSearchFilters.hsFilters = !(savedSearchFilters.hsFilters || []).includes(HSFilter.COMBINE_POWERTOSS) && [...(savedSearchFilters.hsFilters || []), HSFilter.COMBINE_POWERTOSS];
          } else {
            combineFilters[HSFilter.COMBINE_POWERTOSS] = [15, 65];
          }
          break;
        case 'combineRatingFilter':
          if (filters.combineRatingFilter.filterEnabled) {
            hsFilter.push(HSFilter.SPARQ);
            combineFilters[HSFilter.SPARQ] = [filters.combineRatingFilter.filterStart, filters.combineRatingFilter.filterEnd];

            savedSearchFilters.combineEventFilters = {...combineEventFilters, [HSFilter.SPARQ]: [filters.combineRatingFilter.filterStart, filters.combineRatingFilter.filterEnd]};
            savedSearchFilters.hsFilters = !(savedSearchFilters.hsFilters || []).includes(HSFilter.SPARQ) && [...(savedSearchFilters.hsFilters || []), HSFilter.SPARQ];
          } else {
            combineFilters[HSFilter.SPARQ] = [0, 200];
          }
          break;
        case 'collegePrimaryPositionEnabled':
          if (filters.collegePrimaryPositionEnabled) {
            collegeFilter.push(PlayerPosition.PRIMARY_POSITION);

            savedSearchFilters.collegeFilters = !(savedSearchFilters.collegeFilters || []).includes(PlayerPosition.PRIMARY_POSITION) && [...(savedSearchFilters.collegeFilters || []), PlayerPosition.PRIMARY_POSITION];
          }
          break;
        case 'collegeSecondaryPositionEnabled':
          if (filters.collegeSecondaryPositionEnabled) {
            collegeFilter.push(PlayerPosition.SECONDARY_POSITION);

            savedSearchFilters.collegeFilters = !(savedSearchFilters.collegeFilters || []).includes(PlayerPosition.SECONDARY_POSITION) && [...(savedSearchFilters.collegeFilters || []), PlayerPosition.SECONDARY_POSITION];
          }
          break;
        case 'collegePositionCodes':
          if (filters.collegePositionCodes.length) {
            positionFilters[FILTER_TYPE.COLLEGE] = filters.collegePositionCodes;

            if (!savedSearchFilters.positionFilters) {
              savedSearchFilters['positionFilters'] = {
                [FILTER_TYPE.COLLEGE]: filters.collegePositionCodes
              };
            } else {
              savedSearchFilters['positionFilters'] = {
                ...savedSearchFilters.positionFilters,
                [FILTER_TYPE.COLLEGE] : filters.collegePositionCodes
              };
            }
          }
          break;
        case 'collegeHeightFilter':
          if (filters.collegeHeightFilter.filterEnabled) {
            collegeFilter.push(CollegeFilter.HEIGHT);
            setCollegeHeightFilter([filters.collegeHeightFilter.filterStart, filters.collegeHeightFilter.filterEnd]);

            savedSearchFilters.collegeHeightFilter = [filters.collegeHeightFilter.filterStart, filters.collegeHeightFilter.filterEnd];
            savedSearchFilters.collegeFilters = !(savedSearchFilters.collegeFilters || []).includes(CollegeFilter.HEIGHT) && [...(savedSearchFilters.collegeFilters || []), CollegeFilter.HEIGHT];
          } else {
            setCollegeHeightFilter([HEIGHT_RANGE.MIN, HEIGHT_RANGE.MAX]);
          }
          break;
        case 'collegeWeightFilter':
          if (filters.collegeWeightFilter.filterEnabled) {
            collegeFilter.push(CollegeFilter.WEIGHT);
            setCollegeWeightFilter([filters.collegeWeightFilter.filterStart, filters.collegeWeightFilter.filterEnd]);

            savedSearchFilters.collegeWeightFilter = [filters.collegeWeightFilter.filterStart, filters.collegeWeightFilter.filterEnd];
            savedSearchFilters.collegeFilters = !(savedSearchFilters.collegeFilters || []).includes(CollegeFilter.WEIGHT) && [...(savedSearchFilters.collegeFilters || []), CollegeFilter.WEIGHT];
          } else {
            setCollegeWeightFilter([WEIGHT_RANGE.MIN, WEIGHT_RANGE.MAX]);
          }
          break;
        case 'collegeActiveOnlyEnabled':
          if (filters.collegeActiveOnlyEnabled) {
            setActivePlayersOnly(filters.collegeActiveOnlyEnabled);
          }
          break;
        case 'collegeRecruitingClassFilter':
          if (filters.collegeRecruitingClassFilter.filterEnabled) {
            collegeFilter.push(CollegeFilter.RECRUITING_CLASS);
            setCollegeClassYearRangeFilter([filters.collegeRecruitingClassFilter.filterStart, filters.collegeRecruitingClassFilter.filterEnd]);

            savedSearchFilters.collegeClassYearRangeFilter = [filters.collegeRecruitingClassFilter.filterStart, filters.collegeRecruitingClassFilter.filterEnd];
            savedSearchFilters.collegeFilters = !(savedSearchFilters.collegeFilters || []).includes(CollegeFilter.RECRUITING_CLASS) && [...(savedSearchFilters.collegeFilters || []), CollegeFilter.RECRUITING_CLASS];
          } else {
            setCollegeClassYearRangeFilter([COLLEGE_YEAR_RANGE.MIN, COLLEGE_YEAR_RANGE.MAX]);
          }
          break;
        case 'collegeNameEnabled':
          if (filters.collegeNameEnabled) {
            collegeFilter.push(CollegeFilter.COLLEGE_NAME);
          }

          savedSearchFilters.collegeFilters = !(savedSearchFilters.collegeFilters || []).includes(CollegeFilter.COLLEGE_NAME) && [...(savedSearchFilters.collegeFilters || []), CollegeFilter.COLLEGE_NAME];
          break;
        case 'collegeName':
          setCollegeName(filters.collegeName);

          savedSearchFilters.collegeName = filters.collegeName;
          break;
        case 'levelEnabled':
          if (filters.levelEnabled) {
            collegeFilter.push(CollegeFilter.COLLEGE_LEVEL);
          }

          savedSearchFilters.collegeFilters = !(savedSearchFilters.collegeFilters || []).includes(CollegeFilter.COLLEGE_LEVEL) && [...(savedSearchFilters.collegeFilters || []), CollegeFilter.COLLEGE_LEVEL];
          break;
        case 'levels':
          setCollegeLevelFilters(filters.levels);

          savedSearchFilters.collegeLevelFilters = filters.levels;
          break;
        case 'fbsConferenceEnabled':
          if (filters.fbsConferenceEnabled) {
            collegeFilter.push(CollegeFilter.FBS_CONFERENCE);
          }

          savedSearchFilters.collegeFilters = !(savedSearchFilters.collegeFilters || []).includes(CollegeFilter.FBS_CONFERENCE) && [...(savedSearchFilters.collegeFilters || []), CollegeFilter.FBS_CONFERENCE];
          break;
        case 'fbsConferenceIds':
          setFBSConferenceFilters(filters.fbsConferenceIds);

          savedSearchFilters.fbsConferenceFilters = filters.fbsConferenceIds;
          break;
        case 'fcsConferenceEnabled':
          if (filters.fcsConferenceEnabled) {
            collegeFilter.push(CollegeFilter.FCS_CONFERENCE);
          }
          savedSearchFilters.collegeFilters = !(savedSearchFilters.collegeFilters || []).includes(CollegeFilter.FCS_CONFERENCE) && [...(savedSearchFilters.collegeFilters || []), CollegeFilter.FCS_CONFERENCE];
          break;
        case 'fcsConferenceIds':
          setFCSConferenceFilters(filters.fcsConferenceIds);

          savedSearchFilters.fcsConferenceFilters = filters.fcsConferenceIds;
          break;
        case 'transferStatusEnabled':
          if (filters.transferStatusEnabled) {
            collegeFilter.push(CollegeFilter.TRANSFER_PORTAL);
          }
          savedSearchFilters.collegeFilters = !(savedSearchFilters.collegeFilters || []).includes(CollegeFilter.FCS_CONFERENCE) && [...(savedSearchFilters.collegeFilters || []), CollegeFilter.TRANSFER_PORTAL];
          break;
        case 'transferStatus':
          setTransferPortalFilters(filters.transferStatus);

          savedSearchFilters.transferPortalFilters = filters.transferStatus;
          break;
        case 'transferYearsEnabled':
          if (filters.transferYearsEnabled) {
            collegeFilter.push(CollegeFilter.TRANSFER_YEARS);
          }
          savedSearchFilters.collegeFilters = !(savedSearchFilters.collegeFilters || []).includes(CollegeFilter.TRANSFER_YEARS) && [...(savedSearchFilters.collegeFilters || []), CollegeFilter.TRANSFER_YEARS];
          break;
        case 'transferYears':
          setTransferYearsFilters(filters.transferYears);

          savedSearchFilters.transferYearsFilters = filters.transferStatus;
          break;
        case 'allAmericanFilter':
          if (filters.allAmericanFilter.filterEnabled) {
            collegeFilter = [
              ...collegeFilter.filter((filterName:string) => filterName !== CollegeFilter.AWARDS),
              CollegeFilter.AWARDS,
              CollegeFilter.ALL_AMERICAN,
            ];

            collegeRangeFilter = {
              ...collegeRangeFilter,
              [CollegeFilter.ALL_AMERICAN]: [
                filters.allAmericanFilter.filterStart || ALL_AMERICAN_YEAR_RANGE.MIN,
                filters.allAmericanFilter.filterEnd || ALL_AMERICAN_YEAR_RANGE.MAX,
              ],
            };

            savedSearchFilters.collegeFilters = [...collegeFilter];
            savedSearchFilters.collegeRangeFilters = { ...collegeRangeFilter };
          } else {
            const leaveAwardsOn = collegeFilter.includes(CollegeFilter.ALL_AMERICAN) && collegeFilter.includes(CollegeFilter.ALL_CONFERENCE);

            collegeFilter = [...collegeFilter.filter((filterName:string) => leaveAwardsOn
              ? filterName !== CollegeFilter.ALL_AMERICAN
              : filterName !== CollegeFilter.AWARDS && filterName !== CollegeFilter.ALL_AMERICAN
            )];

            collegeRangeFilter = {
              ...collegeRangeFilter,
              [CollegeFilter.ALL_AMERICAN]: [ALL_AMERICAN_YEAR_RANGE.MIN, ALL_AMERICAN_YEAR_RANGE.MAX],
            };
          }
          break;
        case 'allConferenceFilter':
          if (filters.allConferenceFilter.filterEnabled) {
            collegeFilter = [
              ...collegeFilter.filter((filterName:string) => filterName !== CollegeFilter.AWARDS),
              CollegeFilter.AWARDS,
              CollegeFilter.ALL_CONFERENCE,
            ];

            collegeRangeFilter = {
              ...collegeRangeFilter,
              [CollegeFilter.ALL_CONFERENCE]: [
                filters.allConferenceFilter.filterStart || ALL_CONFERENCE_YEAR_RANGE.MIN,
                filters.allConferenceFilter.filterEnd || ALL_CONFERENCE_YEAR_RANGE.MAX,
              ],
            };

            savedSearchFilters.collegeFilters = [...collegeFilter];
            savedSearchFilters.collegeRangeFilters = { ...collegeRangeFilter };
          } else {
            const leaveAwardsOn = collegeFilter.includes(CollegeFilter.ALL_AMERICAN) && collegeFilter.includes(CollegeFilter.ALL_CONFERENCE);

            collegeFilter = [...collegeFilter.filter((filterName:string) => leaveAwardsOn
              ? filterName !== CollegeFilter.ALL_CONFERENCE
              : filterName !== CollegeFilter.AWARDS && filterName !== CollegeFilter.ALL_CONFERENCE
            )];

            collegeRangeFilter = {
              ...collegeRangeFilter,
              [CollegeFilter.ALL_CONFERENCE]: [ALL_CONFERENCE_YEAR_RANGE.MIN, ALL_CONFERENCE_YEAR_RANGE.MAX],
            };
          }
          break;
        case 'seniorBowlStart':
          collegeRangeFilter[CollegeFilter.SENIOR_BOWL][0] = filters.seniorBowlStart;

          if (filters.collegeWatchListEnabled && filters.seniorBowlStart) {
            collegeFilter = [
              ...collegeFilter.filter((filter:CollegeFilter) => filter !== CollegeFilter.SENIOR_BOWL),
              CollegeFilter.SENIOR_BOWL,
            ];
            savedSearchFilters.collegeFilters = [...collegeFilter];
          }

          savedSearchFilters.collegeRangeFilters = {
            ...(savedSearchFilters.collegeRangeFilters || {}),
            [CollegeFilter.SENIOR_BOWL]: savedSearchFilters.collegeRangeFilters?.[CollegeFilter.SENIOR_BOWL]?.length > 1
              ? [
                  filters.seniorBowlStart,
                  savedSearchFilters.collegeRangeFilters[CollegeFilter.SENIOR_BOWL][1],
                ]
              : [filters.seniorBowlStart]
          };
          break;
        case 'seniorBowlEnd':
          collegeRangeFilter[CollegeFilter.SENIOR_BOWL][1] = filters.seniorBowlEnd;

          if (filters.collegeWatchListEnabled && filters.seniorBowlEnd) {
            collegeFilter = [
              ...collegeFilter.filter((filter:CollegeFilter) => filter !== CollegeFilter.SENIOR_BOWL),
              CollegeFilter.SENIOR_BOWL,
            ];
            savedSearchFilters.collegeFilters = [...collegeFilter];
          }

          savedSearchFilters.collegeRangeFilters = {
            ...(savedSearchFilters.collegeRangeFilters || {}),
            [CollegeFilter.SENIOR_BOWL]: savedSearchFilters.collegeRangeFilters?.[CollegeFilter.SENIOR_BOWL]?.length > 0
              ? [
                  savedSearchFilters.collegeRangeFilters[CollegeFilter.SENIOR_BOWL][0],
                  filters.seniorBowlEnd,
                ]
              : [undefined, filters.seniorBowlEnd]
          };
          break;
        case 'cgsShowcaseStart':
          collegeRangeFilter[CollegeFilter.CGS_SHOWCASE][0] = filters.cgsShowcaseStart;

          if (filters.collegeWatchListEnabled && filters.cgsShowcaseStart) {
            collegeFilter = [
              ...collegeFilter.filter((filter:CollegeFilter) => filter !== CollegeFilter.CGS_SHOWCASE),
              CollegeFilter.CGS_SHOWCASE,
            ];
            savedSearchFilters.collegeFilters = [...collegeFilter];
          }

          savedSearchFilters.collegeRangeFilters = {
            ...(savedSearchFilters.collegeRangeFilters || {}),
            [CollegeFilter.CGS_SHOWCASE]: savedSearchFilters.collegeRangeFilters?.[CollegeFilter.CGS_SHOWCASE]?.length > 1
              ? [
                  filters.cgsShowcaseStart,
                  savedSearchFilters.collegeRangeFilters[CollegeFilter.CGS_SHOWCASE][1],
                ]
              : [filters.cgsShowcaseStart]
          };
          break;
        case 'cgsShowcaseEnd':
          collegeRangeFilter[CollegeFilter.CGS_SHOWCASE][1] = filters.cgsShowcaseEnd;

          if (filters.collegeWatchListEnabled && filters.cgsShowcaseEnd) {
            collegeFilter = [
              ...collegeFilter.filter((filter:CollegeFilter) => filter !== CollegeFilter.CGS_SHOWCASE),
              CollegeFilter.CGS_SHOWCASE,
            ];
            savedSearchFilters.collegeFilters = [...collegeFilter];
          }

          savedSearchFilters.collegeRangeFilters = {
            ...(savedSearchFilters.collegeRangeFilters || {}),
            [CollegeFilter.CGS_SHOWCASE]: savedSearchFilters.collegeRangeFilters?.[CollegeFilter.CGS_SHOWCASE]?.length > 0
              ? [
                  savedSearchFilters.collegeRangeFilters[CollegeFilter.CGS_SHOWCASE][0],
                  filters.cgsShowcaseEnd,
                ]
              : [undefined, filters.cgsShowcaseEnd]
          };
          break;
        case 'eastWestShrineStart':
          collegeRangeFilter[CollegeFilter.EAST_WEST_SHRINE][0] = filters.eastWestShrineStart;

          if (filters.collegeWatchListEnabled && filters.eastWestShrineStart) {
            collegeFilter = [
              ...collegeFilter.filter((filter:CollegeFilter) => filter !== CollegeFilter.EAST_WEST_SHRINE),
              CollegeFilter.EAST_WEST_SHRINE,
            ];
            savedSearchFilters.collegeFilters = [...collegeFilter];
          }

          savedSearchFilters.collegeRangeFilters = {
            ...(savedSearchFilters.collegeRangeFilters || {}),
            [CollegeFilter.EAST_WEST_SHRINE]: savedSearchFilters.collegeRangeFilters?.[CollegeFilter.EAST_WEST_SHRINE]?.length > 1
              ? [
                filters.eastWestShrineStart,
                savedSearchFilters.collegeRangeFilters[CollegeFilter.EAST_WEST_SHRINE][1],
              ]
              : [filters.eastWestShrineStart]
          };
          break;
        case 'eastWestShrineEnd':
          collegeRangeFilter[CollegeFilter.EAST_WEST_SHRINE][1] = filters.eastWestShrineEnd;

          if (filters.collegeWatchListEnabled && filters.eastWestShrineEnd) {
            collegeFilter = [
              ...collegeFilter.filter((filter:CollegeFilter) => filter !== CollegeFilter.EAST_WEST_SHRINE),
              CollegeFilter.EAST_WEST_SHRINE,
            ];
            savedSearchFilters.collegeFilters = [...collegeFilter];
          }

          savedSearchFilters.collegeRangeFilters = {
            ...(savedSearchFilters.collegeRangeFilters || {}),
            [CollegeFilter.EAST_WEST_SHRINE]: savedSearchFilters.collegeRangeFilters?.[CollegeFilter.EAST_WEST_SHRINE]?.length > 0
              ? [
                savedSearchFilters.collegeRangeFilters[CollegeFilter.EAST_WEST_SHRINE][0],
                filters.eastWestShrineEnd,
              ]
              : [undefined, filters.eastWestShrineEnd]
          };
          break;
        case 'collegeWatchListEnabled':
          if (filters.collegeWatchListEnabled) {
            collegeFilter.push(CollegeFilter.WATCH_LIST);

            savedSearchFilters.collegeFilters = !(savedSearchFilters.collegeFilters || []).includes(CollegeFilter.WATCH_LIST) && [...(savedSearchFilters.collegeFilters || []), CollegeFilter.WATCH_LIST];
          }
          break;
        case 'nflPrimaryPositionEnabled':
          if (filters.nflPrimaryPositionEnabled) {
            nflFilter.push(PlayerPosition.PRIMARY_POSITION);
          }

          savedSearchFilters.nflFilters = !(savedSearchFilters.nflFilters || []).includes(PlayerPosition.PRIMARY_POSITION) && [...(savedSearchFilters.nflFilters || []), PlayerPosition.PRIMARY_POSITION];
          break;
        case 'nflSecondaryPositionEnabled':
          if (filters.nflSecondaryPositionEnabled) {
            nflFilter.push(PlayerPosition.SECONDARY_POSITION);
          }

          savedSearchFilters.nflFilters = !(savedSearchFilters.nflFilters || []).includes(PlayerPosition.SECONDARY_POSITION) && [...(savedSearchFilters.nflFilters || []), PlayerPosition.SECONDARY_POSITION];
          break;
        case 'nflPositionCodes':
          if (filters.nflPositionCodes.length) {
            positionFilters[FILTER_TYPE.NFL] = filters.nflPositionCodes;

            if (!savedSearchFilters.positionFilters) {
              savedSearchFilters['positionFilters'] = {
                [FILTER_TYPE.NFL]: filters.nflPositionCodes
              };
            } else {
              savedSearchFilters['positionFilters'] = {
                ...savedSearchFilters.positionFilters,
                [FILTER_TYPE.NFL] : filters.nflPositionCodes
              };
            }
          }
          break;
        case 'nflHeightFilter':
          if (filters.nflHeightFilter.filterEnabled) {
            nflFilter.push(NFLFilter.HEIGHT);
            nflRangeFilter[NFLFilter.HEIGHT] = [filters.nflHeightFilter.filterStart, filters.nflHeightFilter.filterEnd];

            savedSearchFilters.nflRangeFilters[NFLFilter.HEIGHT] = [filters.nflHeightFilter.filterStart, filters.nflHeightFilter.filterEnd];
            savedSearchFilters.nflFilters = !(savedSearchFilters.nflFilters || []).includes(NFLFilter.HEIGHT) && [...(savedSearchFilters.nflFilters || []), NFLFilter.HEIGHT];
          } else {
            nflRangeFilter[NFLFilter.HEIGHT] = [HEIGHT_RANGE.MIN, HEIGHT_RANGE.MAX];
          }
          break;
        case 'nflWeightFilter':
          if (filters.nflWeightFilter.filterEnabled) {
            nflFilter.push(NFLFilter.WEIGHT);
            nflRangeFilter[NFLFilter.WEIGHT] = [filters.nflWeightFilter.filterStart, filters.nflWeightFilter.filterEnd];

            savedSearchFilters.nflRangeFilters[NFLFilter.WEIGHT] = [filters.nflWeightFilter.filterStart, filters.nflWeightFilter.filterEnd];
            savedSearchFilters.nflFilters = !(savedSearchFilters.nflFilters || []).includes(NFLFilter.WEIGHT) && [...(savedSearchFilters.nflFilters || []), NFLFilter.WEIGHT];
          } else {
            nflRangeFilter[NFLFilter.WEIGHT] = [WEIGHT_RANGE.MIN, WEIGHT_RANGE.MAX];
          }
          break;
        case 'nflDraftTeamEnabled':
          if (filters.nflDraftTeamEnabled) {
            nflFilter.push(NFLFilter.DRAFT_TEAM);

            savedSearchFilters.nflFilters = !(savedSearchFilters.nflFilters || []).includes(NFLFilter.DRAFT_TEAM) && [...(savedSearchFilters.nflFilters || []), NFLFilter.DRAFT_TEAM];
          }
          break;
        case 'nflDraftTeamIds':
          setNflDraftTeamFilters(filters.nflDraftTeamIds);

          savedSearchFilters.nflDraftTeamFilters = filters.nflDraftTeamIds;
          break;
        case 'nflDraftYearFilter':
          if (filters.nflDraftYearFilter.filterEnabled) {
            nflFilter.push(NFLFilter.DRAFT_YEAR);
            nflRangeFilter[NFLFilter.DRAFT_YEAR] = [filters.nflDraftYearFilter.filterStart, filters.nflDraftYearFilter.filterEnd];

            savedSearchFilters.nflRangeFilters[NFLFilter.DRAFT_YEAR] = [filters.nflDraftYearFilter.filterStart, filters.nflDraftYearFilter.filterEnd];
            savedSearchFilters.nflFilters = !(savedSearchFilters.nflFilters || []).includes(NFLFilter.DRAFT_YEAR) && [...(savedSearchFilters.nflFilters || []), NFLFilter.DRAFT_YEAR];
          } else {
            nflRangeFilter[NFLFilter.DRAFT_YEAR] = [NFL_DRAFT_YEAR_RANGE.MIN, NFL_DRAFT_YEAR_RANGE.MAX];
          }
          break;
        case 'nflDraftRoundFilter':
          if (filters.nflDraftRoundFilter.filterEnabled) {
            nflFilter.push(NFLFilter.DRAFT_ROUND);
            nflRangeFilter[NFLFilter.DRAFT_ROUND] = [filters.nflDraftRoundFilter.filterStart, filters.nflDraftRoundFilter.filterEnd];

            savedSearchFilters.nflRangeFilters[NFLFilter.DRAFT_ROUND] = [filters.nflDraftRoundFilter.filterStart, filters.nflDraftRoundFilter.filterEnd];
            savedSearchFilters.nflFilters = !(savedSearchFilters.nflFilters || []).includes(NFLFilter.DRAFT_ROUND) && [...(savedSearchFilters.nflFilters || []), NFLFilter.DRAFT_ROUND];
          } else {
            nflRangeFilter[NFLFilter.DRAFT_ROUND] = [1, 12];
          }
          break;
        case 'nflUDFAEnabled':
          if (filters.nflUDFAEnabled) {
            nflFilter.push(NFLFilter.UDFA);
          }
          break;
        case 'allProFilter':
          if (filters.allProFilter.filterEnabled) {
            nflFilter = [
              ...nflFilter.filter((filterName:string) => filterName !== NFLFilter.AWARDS),
              NFLFilter.AWARDS,
              NFLFilter.ALL_PRO,
            ];

            nflRangeFilter = {
              ...nflRangeFilter,
              [NFLFilter.ALL_PRO]: [
                filters.allProFilter.filterStart || ALL_PRO_YEAR_RANGE.MIN,
                filters.allProFilter.filterEnd || ALL_PRO_YEAR_RANGE.MAX,
              ],
            };

            savedSearchFilters.nflFilters = [...nflFilter];
            savedSearchFilters.nflRangeFilters = { ...nflRangeFilter };
          } else {
            nflFilter = [...nflFilter.filter((filterName:string) => filterName !== NFLFilter.ALL_PRO)];

            nflRangeFilter = {
              ...nflRangeFilter,
              [NFLFilter.ALL_PRO]: [ALL_PRO_YEAR_RANGE.MIN, ALL_PRO_YEAR_RANGE.MAX],
            };
          }
          break;
        case 'proBowlFilter':
          if (filters.proBowlFilter.filterEnabled) {
            nflFilter = [
              ...nflFilter.filter((filterName:string) => filterName !== NFLFilter.AWARDS),
              NFLFilter.AWARDS,
              NFLFilter.PRO_BOWL,
            ];

            nflRangeFilter = {
              ...nflRangeFilter,
              [NFLFilter.PRO_BOWL]: [
                filters.proBowlFilter.filterStart || PRO_BOWL_YEAR_RANGE.MIN,
                filters.proBowlFilter.filterEnd || PRO_BOWL_YEAR_RANGE.MAX,
              ],
            };

            savedSearchFilters.nflFilters = [...nflFilter];
            savedSearchFilters.nflRangeFilters = { ...nflRangeFilter };
          } else {
            nflFilter = [...nflFilter.filter((filterName:string) => filterName !== NFLFilter.PRO_BOWL)];

            nflRangeFilter = {
              ...nflRangeFilter,
              [NFLFilter.PRO_BOWL]: [PRO_BOWL_YEAR_RANGE.MIN, PRO_BOWL_YEAR_RANGE.MAX],
            };
          }
          break;
        default:
          break;
      }
    });

    setHsFilters([...hsFilter, ...Array.from(trackFields)]);
    setNflFilters(nflFilter);
    setCollegeFilters(collegeFilter);
    setTrackAndFieldEventFilters(trackFieldFilters);
    setCombineRangeFilters(combineFilters);
    setCollegeRangeFilters(collegeRangeFilter);
    setNflRangeFilters(nflRangeFilter);
    setPositionFilters(positionFilters);

    clientStorage.save(StorageKey.SEARCH_FILTERS, savedSearchFilters);
  }

  function applyDefaultSortingAndOrdering (searchFilters:any) {
    // sprints
    if (searchFilters?.hsFilters?.includes(HSFilter.S_55_METERS)) {
      setSortedByColumn(SEARCH_COLUMNS_SORTING_RULES[HSFilter.S_55_METERS]);
      setOrder(SEARCH_COLUMNS_ORDERING_RULES[HSFilter.S_55_METERS]);
    } else if (searchFilters?.hsFilters?.includes(HSFilter.S_60_METERS)) {
      setSortedByColumn(SEARCH_COLUMNS_SORTING_RULES[HSFilter.S_60_METERS]);
      setOrder(SEARCH_COLUMNS_ORDERING_RULES[HSFilter.S_60_METERS]);
    } else if (searchFilters?.hsFilters?.includes(HSFilter.S_100_METERS)) {
      setSortedByColumn(SEARCH_COLUMNS_SORTING_RULES[HSFilter.S_100_METERS]);
      setOrder(SEARCH_COLUMNS_ORDERING_RULES[HSFilter.S_100_METERS]);
    } else if (searchFilters?.hsFilters?.includes(HSFilter.S_200_METERS)) {
      setSortedByColumn(SEARCH_COLUMNS_SORTING_RULES[HSFilter.S_200_METERS]);
      setOrder(SEARCH_COLUMNS_ORDERING_RULES[HSFilter.S_200_METERS]);
    } else if (searchFilters?.hsFilters?.includes(HSFilter.S_300_METERS)) {
      setSortedByColumn(SEARCH_COLUMNS_SORTING_RULES[HSFilter.S_300_METERS]);
      setOrder(SEARCH_COLUMNS_ORDERING_RULES[HSFilter.S_300_METERS]);
    } else if (searchFilters?.hsFilters?.includes(HSFilter.S_400_METERS)) {
      setSortedByColumn(SEARCH_COLUMNS_SORTING_RULES[HSFilter.S_400_METERS]);
      setOrder(SEARCH_COLUMNS_ORDERING_RULES[HSFilter.S_400_METERS]);

    // hurdles
    } else if (searchFilters?.hsFilters?.includes(HSFilter.HH_55_METERS)) {
      setSortedByColumn(SEARCH_COLUMNS_SORTING_RULES[HSFilter.HH_55_METERS]);
      setOrder(SEARCH_COLUMNS_ORDERING_RULES[HSFilter.HH_55_METERS]);
    } else if (searchFilters?.hsFilters?.includes(HSFilter.HH_60_METERS)) {
      setSortedByColumn(SEARCH_COLUMNS_SORTING_RULES[HSFilter.HH_60_METERS]);
      setOrder(SEARCH_COLUMNS_ORDERING_RULES[HSFilter.HH_60_METERS]);
    } else if (searchFilters?.hsFilters?.includes(HSFilter.HH_110_METERS)) {
      setSortedByColumn(SEARCH_COLUMNS_SORTING_RULES[HSFilter.HH_110_METERS]);
      setOrder(SEARCH_COLUMNS_ORDERING_RULES[HSFilter.HH_110_METERS]);
    } else if (searchFilters?.hsFilters?.includes(HSFilter.H_300_INT)) {
      setSortedByColumn(SEARCH_COLUMNS_SORTING_RULES[HSFilter.H_300_INT]);
      setOrder(SEARCH_COLUMNS_ORDERING_RULES[HSFilter.H_300_INT]);
    } else if (searchFilters?.hsFilters?.includes(HSFilter.H_400_METERS)) {
      setSortedByColumn(SEARCH_COLUMNS_SORTING_RULES[HSFilter.H_400_METERS]);
      setOrder(SEARCH_COLUMNS_ORDERING_RULES[HSFilter.H_400_METERS]);

    // middle distance
    } else if (searchFilters?.hsFilters?.includes(HSFilter.MD_800_METERS)) {
      setSortedByColumn(SEARCH_COLUMNS_SORTING_RULES[HSFilter.MD_800_METERS]);
      setOrder(SEARCH_COLUMNS_ORDERING_RULES[HSFilter.MD_800_METERS]);

    // relays
    } else if (searchFilters?.hsFilters?.includes(HSFilter.R_400_METERS)) {
      setSortedByColumn(SEARCH_COLUMNS_SORTING_RULES[HSFilter.R_400_METERS]);
      setOrder(SEARCH_COLUMNS_ORDERING_RULES[HSFilter.R_400_METERS]);
    } else if (searchFilters?.hsFilters?.includes(HSFilter.R_800_METERS)) {
      setSortedByColumn(SEARCH_COLUMNS_SORTING_RULES[HSFilter.R_800_METERS]);
      setOrder(SEARCH_COLUMNS_ORDERING_RULES[HSFilter.R_800_METERS]);
    } else if (searchFilters?.hsFilters?.includes(HSFilter.R_1600_METERS)) {
      setSortedByColumn(SEARCH_COLUMNS_SORTING_RULES[HSFilter.R_1600_METERS]);
      setOrder(SEARCH_COLUMNS_ORDERING_RULES[HSFilter.R_1600_METERS]);
    } else if (searchFilters?.hsFilters?.includes(HSFilter.R_3200_METERS)) {
      setSortedByColumn(SEARCH_COLUMNS_SORTING_RULES[HSFilter.R_3200_METERS]);
      setOrder(SEARCH_COLUMNS_ORDERING_RULES[HSFilter.R_3200_METERS]);

    // throwing events
    } else if (searchFilters?.hsFilters?.includes(HSFilter.SHOTPUT)) {
      setSortedByColumn(SEARCH_COLUMNS_SORTING_RULES[HSFilter.SHOTPUT]);
      setOrder(SEARCH_COLUMNS_ORDERING_RULES[HSFilter.SHOTPUT]);
    } else if (searchFilters?.hsFilters?.includes(HSFilter.DISCUS)) {
      setSortedByColumn(SEARCH_COLUMNS_SORTING_RULES[HSFilter.DISCUS]);
      setOrder(SEARCH_COLUMNS_ORDERING_RULES[HSFilter.DISCUS]);
    } else if (searchFilters?.hsFilters?.includes(HSFilter.JAVELIN)) {
      setSortedByColumn(SEARCH_COLUMNS_SORTING_RULES[HSFilter.JAVELIN]);
      setOrder(SEARCH_COLUMNS_ORDERING_RULES[HSFilter.JAVELIN]);

    // jumping events
    } else if (searchFilters?.hsFilters?.includes(HSFilter.LONG_JUMP)) {
      setSortedByColumn(SEARCH_COLUMNS_SORTING_RULES[HSFilter.LONG_JUMP]);
      setOrder(SEARCH_COLUMNS_ORDERING_RULES[HSFilter.LONG_JUMP]);
    } else if (searchFilters?.hsFilters?.includes(HSFilter.TRIPLE_JUMP)) {
      setSortedByColumn(SEARCH_COLUMNS_SORTING_RULES[HSFilter.TRIPLE_JUMP]);
      setOrder(SEARCH_COLUMNS_ORDERING_RULES[HSFilter.TRIPLE_JUMP]);
    } else if (searchFilters?.hsFilters?.includes(HSFilter.HIGH_JUMP)) {
      setSortedByColumn(SEARCH_COLUMNS_SORTING_RULES[HSFilter.HIGH_JUMP]);
      setOrder(SEARCH_COLUMNS_ORDERING_RULES[HSFilter.HIGH_JUMP]);
    } else if (searchFilters?.hsFilters?.includes(HSFilter.POLE_VAULT_JUMP)) {
      setSortedByColumn(SEARCH_COLUMNS_SORTING_RULES[HSFilter.POLE_VAULT_JUMP]);
      setOrder(SEARCH_COLUMNS_ORDERING_RULES[HSFilter.POLE_VAULT_JUMP]);

    // combine events
    } else if (searchFilters?.hsFilters?.includes(HSFilter.COMBINE_40_M_DASH)) {
      setSortedByColumn(SEARCH_COLUMNS_SORTING_RULES[HSFilter.COMBINE_40_M_DASH]);
      setOrder(SEARCH_COLUMNS_ORDERING_RULES[HSFilter.COMBINE_40_M_DASH]);
    } else if (searchFilters?.hsFilters?.includes(HSFilter.COMBINE_10_SPLIT)) {
      setSortedByColumn(SEARCH_COLUMNS_SORTING_RULES[HSFilter.COMBINE_10_SPLIT]);
      setOrder(SEARCH_COLUMNS_ORDERING_RULES[HSFilter.COMBINE_10_SPLIT]);
    } else if (searchFilters?.hsFilters?.includes(HSFilter.COMBINE_MPH)) {
      setSortedByColumn(SEARCH_COLUMNS_SORTING_RULES[HSFilter.COMBINE_MPH]);
      setOrder(SEARCH_COLUMNS_ORDERING_RULES[HSFilter.COMBINE_MPH]);
    } else if (searchFilters?.hsFilters?.includes(HSFilter.COMBINE_SHUTTLE)) {
      setSortedByColumn(SEARCH_COLUMNS_SORTING_RULES[HSFilter.COMBINE_SHUTTLE]);
      setOrder(SEARCH_COLUMNS_ORDERING_RULES[HSFilter.COMBINE_SHUTTLE]);
    } else if (searchFilters?.hsFilters?.includes(HSFilter.COMBINE_3_CONE)) {
      setSortedByColumn(SEARCH_COLUMNS_SORTING_RULES[HSFilter.COMBINE_3_CONE]);
      setOrder(SEARCH_COLUMNS_ORDERING_RULES[HSFilter.COMBINE_3_CONE]);
    } else if (searchFilters?.hsFilters?.includes(HSFilter.COMBINE_VERTICAL_JUMP)) {
      setSortedByColumn(SEARCH_COLUMNS_SORTING_RULES[HSFilter.COMBINE_VERTICAL_JUMP]);
      setOrder(SEARCH_COLUMNS_ORDERING_RULES[HSFilter.COMBINE_VERTICAL_JUMP]);
    } else if (searchFilters?.hsFilters?.includes(HSFilter.COMBINE_BROAD_JUMP)) {
      setSortedByColumn(SEARCH_COLUMNS_SORTING_RULES[HSFilter.COMBINE_BROAD_JUMP]);
      setOrder(SEARCH_COLUMNS_ORDERING_RULES[HSFilter.COMBINE_BROAD_JUMP]);
    } else if (searchFilters?.hsFilters?.includes(HSFilter.COMBINE_POWERTOSS)) {
      setSortedByColumn(SEARCH_COLUMNS_SORTING_RULES[HSFilter.COMBINE_POWERTOSS]);
      setOrder(SEARCH_COLUMNS_ORDERING_RULES[HSFilter.COMBINE_POWERTOSS]);
    }
  }

  function onSelectColumns (selectedColumnNames:string[]) {
    const { orderedColumns } = getAllSelectedAndDefaultColumns1();

    const allColPerPlayerType = Array.from(new Set([...columnsToShow]))
    const ordereArray = reorderArray(allColPerPlayerType, orderedColumns);
    saveColumns(ordereArray.map((column:string) => ({
      name: column,
      selected: selectedColumnNames.includes(column),
    } as Column)));
  }

  function onReorderColumns (reorderedColumnNames:string[]) {
    const allColPerPlayerType = Array.from(new Set([...columnsToShow]))
    const ordereArray = reorderArray(allColPerPlayerType, reorderedColumnNames);
    const disableCol = ordereArray.filter((column:string) => disableColumns.includes(column));
    const restCol = ordereArray.filter((column:string) => !disableColumns.includes(column));
    const orderedColumns = [...disableCol, ...restCol];
    saveColumns(orderedColumns
      .map((columnName:string) => columns.find((column:Column) => column.name === columnName))
      .filter(Boolean) as Column[]
    );
  }

  function resetColumns () {
    saveColumns(columnsToShow
      .map((columnName:string) =>  {
        return {name: columnName, selected: true}
      })
      .filter(Boolean) as Column[]
    );
  }

  function allowedToExport () {
    const allowedOnlyHSPlayers = user?.accessLevel?.exportSearchResultsAllowanceType === ExportSearchResultsAllowanceType.LIMITED_HS;
    const allowedAnyPlayers = !allowedOnlyHSPlayers && user?.accessLevel?.exportSearchResultsAllowanceType !== ExportSearchResultsAllowanceType.NONE;

    return allowedAnyPlayers
      || (allowedOnlyHSPlayers
        && !playerTypeFilters.includes(FILTER_TYPE.COLLEGE)
        && !playerTypeFilters.includes(FILTER_TYPE.NFL)
      );
  }

  function showToast (message:any, type:ToastType = ToastType.SUCCESS) {
    setToastMessage(message);
    setToastType(type);
    setToastVisible(true);
  }

  const sideBySideActionDisabled = !selectedPlayers.length;
  const compareActionDisabled = !selectedPlayers.length || selectedPlayers.length > 1;
  const saveActionDisabled = !selectedPlayers.length;
  const alertActionDisabled = !selectedPlayers.length;
  const editActionDisabled = !selectedPlayers.length;
  const hideActionDisabled = !selectedPlayers.length;

  const loading = pageLoading || collegeTeamsLoading || jucoTeamsLoading || nflTeamsLoading
    || statesLoading || fbsConferencesLoading || fcsConferencesLoading;

  return (
    <div className={clsx(classes.searchPlayers, className)}>
      <Loader inProgress={loading} />

      <div className={classes.header}>
        <div className={classes.titleRow}>
          <div className={classes.titleTextWrap}>
            <h2 className={classes.headerTitle}>Results</h2>
          </div>

          <div className={classes.dropdowns}>
            <div className={classes.dropdownWrapper}>
              <div className={classes.surfaceToTopLabel}>
                Surface Players to Top:
              </div>

              <SurfaceToTop
                className={classes.surfaceToTop}
                values={surfaceToTopValues}
                onSelect={(values:string[]) => setSurfaceToTopValues(values)}
              />
            </div>

            <div className={classes.dropdownWrapper}>
              Show
              <PageLimitDropdown
                className={clsx(classes.dropdown, classes.limitDropDown)}
                selectorRootClassName={classes.dropdownSelector}
                value={String(limit)}
                onChange={(limit:string) => setLimit(Number(limit))}
              />
              Players
            </div>
          </div>
        </div>

        <div className={classes.actions}>
          <div className={classes.actionsPrimary}>
            <Action
              className={clsx(classes.action, sideBySideActionDisabled && classes.disabledAction)}
              icon={PinIcon}
              iconClassName={classes.actionIcon}
              disabled={sideBySideActionDisabled}
              onClick={onPin}
            >
              <span className={classes.actionText}>Pin</span>
            </Action>

            {user?.accessLevel?.canComparePlayers && (
              <Action
                className={clsx(classes.action, compareActionDisabled && classes.disabledAction)}
                icon={CompareIcon}
                iconClassName={classes.actionIcon}
                disabled={compareActionDisabled}
                href={!compareActionDisabled ? `/compare/${selectedPlayers[0].slug}` : '/compare'}
              >
                <span className={classes.actionText}>Compare</span>
              </Action>
            )}

            <Action
              className={clsx(classes.action, saveActionDisabled && classes.disabledAction)}
              icon={SaveIcon}
              iconClassName={classes.actionIcon}
              disabled={saveActionDisabled}
              onClick={onSave}
            >
              <span className={classes.actionText}>Save</span>
            </Action>

            {!isNFL && (
              <Action
                className={clsx(classes.action, alertActionDisabled && classes.disabledAction)}
                icon={AlertIcon}
                iconClassName={classes.actionIcon}
                disabled={alertActionDisabled}
                onClick={onAlert}
              >
                <span className={classes.actionText}>Alert</span>
              </Action>
            )}

            {isAdmin && (
              <Action
                className={clsx(classes.action, editActionDisabled && classes.disabledAction)}
                icon={EditIcon}
                iconClassName={classes.actionIcon}
                disabled={editActionDisabled}
                onClick={onEditPlayers}
              >
                <span className={classes.actionText}>Edit</span>
              </Action>
            )}

            <Action
              className={clsx(classes.action, hideActionDisabled && classes.disabledAction)}
              icon={EyeIcon}
              iconClassName={classes.actionIcon}
              disabled={hideActionDisabled}
              onClick={onHidePlayers}
            >
              <span className={classes.actionText}>Hide</span>
            </Action>
          </div>

          <div className={classes.actionsSecondary}>
            <AddRemoveColumns
              className={classes.addRemoveColumns}
              columns={columns.map((column:Column, index:number) => ({
                content: PLAYER_COLUMN_TITLE[column.name as PlayerColumn],
                value: column.name,
                isDefault: disableColumns.includes(column.name),
                isDragDisabled: index < columns.findIndex((column:Column) => !defaultColumns.includes(column.name as PlayerColumn))
              })) as MultiSelectItem[]}
              selectedColumns={columns
                .filter((column:Column) => column.selected)
                .map((column:Column) => column.name)
              }
              onSelect={onSelectColumns}
              onReorder={onReorderColumns}
              onResetColumnsClick={resetColumns}
            />

            {allowedToExport() && (
              <>
                <Divider
                  orientation='vertical'
                  light
                  flexItem
                />

                <SelectXLSExportType
                  className={classes.xlsExport}
                  items={[
                    { content: `${SelectedXLSType.SELECTED} (${selectedPlayers.length})`, value: SelectedXLSType.SELECTED },
                    { content: `${SelectedXLSType.ALL} (${allPlayerIds.length})`, value: SelectedXLSType.ALL },
                  ]}
                  tooltipText={user?.accessLevel?.exportSearchResultsLimit ? `Your monthly limit is ${user.accessLevel.exportSearchResultsLimit}` : ''}
                  disabled={loading}
                  onSelect={onXLSDownload}
                />
              </>
            )}
          </div>
        </div>
      </div>

      <SearchPlayerTable
        items={players}
        limit={limit}
        totalPlayersCount={totalPlayersCount}
        columns={columns
          .filter((column:Column) => column.selected)
          .map((column:Column) => column.name)
        }
        selectedItems={selectedPlayers}
        setSelectedItems={setSelectedPlayers}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        order={order}
        collegeTeams={collegeTeams}
        loading={loading}
        sortedByColumn={sortedByColumn}
        onSort={onSort}
      />

      <Toast
        visible={toastVisible}
        type={toastType}
        onHide={() => setToastVisible(false)}
      >
        {toastMessage}
      </Toast>

      <SearchPlayerFilterDrawer
        loading={loading}
        isDrawerOpen={isDrawerOpen}
        user={user}
        isAdmin={isAdmin}
        isNFL={isNFL}
        currentRecruitingYear={currentRecruitingYear}
        nflDraftYear={nflDraftYear}
        fbsConferences={fbsConferences}
        fcsConferences={fcsConferences}
        nflTeams={nflTeams}
        collegeTeams={collegeTeams}
        jucoTeams={jucoTeams}
        searchCounts={searchCounts}
        searchByNamePlayers={searchByNamePlayers}
        setSearchByNamePlayers={setSearchByNamePlayers}
        hsPlayersForbidden={hsPlayersForbidden}
        collegePlayersForbidden={collegePlayersForbidden}
        nflPlayersForbidden={nflPlayersForbidden}
        archivePlayersForbidden={archivePlayersForbidden}
        states={states}
        selectedStates={selectedStates}
        needMoreFilters={needMoreFilters}
        positionFilters={positionFilters}
        hsFilters={hsFilters}
        nflFilters={nflFilters}
        collegeFilters={collegeFilters}
        playerTypeFilters={playerTypeFilters}
        collegeLevelFilters={collegeLevelFilters}
        fbsConferenceFilters={fbsConferenceFilters}
        fcsConferenceFilters={fcsConferenceFilters}
        nflDraftTeamFilters={nflDraftTeamFilters}
        transferPortalFilters={transferPortalFilters}
        transferYearsFilters={transferYearsFilters}
        otherSportFilters={otherSportFilters}
        combineEventFilters={combineEventFilters}
        collegeRangeFilters={collegeRangeFilters}
        nflRangeFilters={nflRangeFilters}
        trackAndFieldEventFilters={trackAndFieldEventFilters}
        combineRangeFilters={combineRangeFilters}
        offerFBSConferenceFilters={offerFBSConferenceFilters}
        offerFCSConferenceFilters={offerFCSConferenceFilters}
        hsName={hsName}
        hsCity={hsCity}
        hsCounty={hsCounty}
        collegeName={collegeName}
        gpaMinimum={gpaMinimum}
        satMinimum={satMinimum}
        actMinimum={actMinimum}
        offerSchoolName={offerSchoolName}
        committedSchoolName={committedSchoolName}
        combineCityName={combineCityName}
        hsClassYearRangeFilter={hsClassYearRangeFilter}

        hsArchiveFilter={hsArchiveFilter}
        prepFilter={prepFilter}
        hsHeightRangeFilter={hsHeightRangeFilter}
        hsWeightRangeFilter={hsWeightRangeFilter}
        hsWingspanRangeFilter={hsWingspanRangeFilter}
        hsArmLengthRangeFilter={hsArmLengthRangeFilter}
        hsHandSizeRangeFilter={hsHandSizeRangeFilter}
        paiRangeFilter={paiRangeFilter}
        ppiRangeFilter={ppiRangeFilter}
        hsMPHRangeFilter={hsMPHRangeFilter}
        combineScoreRangeFilter={combineScoreRangeFilter}
        proCombineScoreRangeFilter={proCombineScoreRangeFilter}
        rating247RangeFilter={rating247RangeFilter}
        offerCountRangeFilter={offerCountRangeFilter}
        hsTrackAndFieldYearRangeFilter={hsTrackAndFieldYearRangeFilter}
        combineYearRangeFilter={combineYearRangeFilter}
        collegeClassYearRangeFilter={collegeClassYearRangeFilter}
        collegeWeightFilter={collegeWeightFilter}
        collegeHeightFilter={collegeHeightFilter}

        setIsDrawerOpen={setIsDrawerOpen}
        applyFilters={() => {
          setCurrentPage(1);
          setIgnoreNeedToApplyFilters(true);
          applyFilters();
          // clear query parameters to be able to restore filters from localStorage
          // when coming back through browser history (using back button)
          history.replace(location.pathname);
        }}
        setDialogOpen={setDialogOpen}
        handleAllPositionChange={handleAllPositionChange}

        onFilterCheckBoxChange={onFilterCheckBoxChange}
        onLevelFilterCheckBoxChange={onLevelFilterCheckBoxChange}
        onFBSConferenceFilterCheckBoxChange={onFBSConferenceFilterCheckBoxChange}
        onFCSConferenceFilterCheckBoxChange={onFCSConferenceFilterCheckBoxChange}
        onTransferPortalFilterCheckBoxChange={onTransferPortalFilterCheckBoxChange}
        onTransferYearsFilterCheckBoxChange={onTransferYearsFilterCheckBoxChange}
        onNFLDraftTeamCheckBoxChange={onNFLDraftTeamCheckBoxChange}
        onOtherSportCheckBoxChange={onOtherSportCheckBoxChange}
        onCombineEventFilterCheckBoxChange={onCombineEventFilterCheckBoxChange}
        onOfferFBSConferenceCheckBoxChange={onOfferFBSConferenceCheckBoxChange}
        onOfferFCSConferenceCheckBoxChange={onOfferFCSConferenceCheckBoxChange}
        handlePlayerTypeChange={handlePlayerTypeChange}
        setCollegeRangeFilters={setCollegeRangeFilters}
        setNflRangeFilters={setNflRangeFilters}
        setTrackAndFieldEventFilters={setTrackAndFieldEventFilters}
        setCombineRangeFilters={setCombineRangeFilters}
        setHsClassYearRangeFilter={setHSClassYearRangeFilter}
        setCollegeHeightFilter={setCollegeHeightFilter}
        setCollegeWeightFilter={setCollegeWeightFilter}
        setHsHeightRangeFilter={setHsHeightRangeFilter}
        setCollegeClassYearRangeFilter={setCollegeClassYearRangeFilter}
        setHsWeightRangeFilter={setHsWeightRangeFilter}
        setHSWingspanRangeFilter={setHSWingspanRangeFilter}
        setHSHandSizeRangeFilter={setHSHandSizeRangeFilter}
        setHSArmLengthRangeFilter={setHSArmLengthRangeFilter}
        setPaiRangeFilter={setPaiRangeFilter}
        setPpiRangeFilter={setPpiRangeFilter}
        setHsMPHRangeFilter={setHsMPHRangeFilter}
        setCombineScoreRangeFilter={setCombineScoreRangeFilter}
        setProCombineScoreRangeFilter={setProCombineScoreRangeFilter}
        setRating247RangeFilter={setRating247RangeFilter}
        setOfferCountRangeFilter={setOfferCountRangeFilter}
        setHSTrackAndFieldYearRangeFilter={setHSTrackAndFieldYearRangeFilter}
        setCombineYearRangeFilter={setCombineYearRangeFilter}
        setHSArchiveFilter={setHSArchiveFilter}
        setPrepFilter={setPrepFilter}
        setHSName={setHSName}
        setHSCity={setHSCity}
        setHSCounty={setHSCounty}
        setCollegeName={setCollegeName}
        setGpaMinimum={setGpaMinimum}
        setSatMinimum={setSatMinimum}
        setActMinimum={setActMinimum}
        setOfferSchoolName={setOfferSchoolName}
        setCommittedSchoolName={setCommittedSchoolName}
        setCombineCityName={setCombineCityName}
        setSelectedStates={setSelectedStates}
        clearAllFilters={clearAllFilters}
        setActivePlayersOnly={setActivePlayersOnly}
        activePlayersOnly={activePlayersOnly}
        setUncommittedEnabled={setUncommittedEnabled}
        uncommittedEnabled={uncommittedEnabled}
      />

      <SaveSearchModal
        dialogOpen={dialogOpen}
        loading={loading}
        savedSearchName={savedSearchData && savedSearchData.name}
        savedSearchFolder={savedSearchData && savedSearchData.folder}
        setDialogOpen={(isDialogOpen:boolean) => setDialogOpen(isDialogOpen)}
        saveSearch={(selectedFolder:SearchesFolder | undefined, searchName:string) => saveSearch(selectedFolder, searchName)}
        setLoading={setPageLoading}
        showToast={showToast}
      />
    </div>
  );
}

const mapStateToProps = (state:State) => {
  return {
    user: state.user,
    currentRecruitingYear: state.configurations.currentRecruitingYear,
    nflDraftYear: state.configurations.nflDraftYear,
    isNFL: state.ui.nflAccess,
    isAdmin: state.ui.adminAccess,
    collegeTeams: state.teams.collegeTeams,
    collegeTeamsLoading: state.teams.collegeTeamsLoading,
    jucoTeams: state.teams.jucoTeams,
    jucoTeamsLoading: state.teams.jucoTeamsLoading,
    nflTeams: state.teams.nflTeams,
    nflTeamsLoading: state.teams.nflTeamsLoading,
    states: state.states.allStates,
    statesLoading: state.states.statesLoading,
    fbsConferences: state.conferences.fbsConferences,
    fbsConferencesLoading: state.conferences.fbsConferencesLoading,
    fcsConferences: state.conferences.fcsConferences,
    fcsConferencesLoading: state.conferences.fcsConferencesLoading,
    savedColumns: state.userSettings.searchColumns,
    rolloverMonthTransferPortal: state.configurations.rolloverMonthTransferPortal,
    rolloverDateTransferPortal: state.configurations.rolloverDateTransferPortal,
  };
};

const mapDispatchToProps =  (dispatch:Dispatch) => {
  return bindActionCreators(
    {
      fetchCollegeTeams,
      fetchJucoTeams,
      fetchNFLTeams,
      fetchStates,
      fetchFBSConferences,
      fetchFCSConferences,
      fetchUserSettings,
      saveColumns: saveSearchColumns,
    },
    dispatch
  )
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SearchPlayers);
//
